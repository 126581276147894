export default (state, action) => {
    if (typeof state === 'undefined') {
      state = {
        isFetching: true,
        data: {}
      }
    }
  
    switch (action.type) {
      case 'GET_INSTITUTE_COURSE_PENDING':
        return { isFetching: true, data: action.payload }
      case 'GET_INSTITUTE_COURSE_SUCCESS':
        return { isFetching: false, data: action.payload.data }
      case 'GET_INSTITUTE_COURSE_ERROR':
        return { isFetching: false, data: action.payload }
      default:
        return state
    }
  };