import { Interseptor } from './Interseptor'

const axios = require('axios')
export let baseURL = 'https://uat.customerjourney.flyhifinance.com'
//export let baseURL = 'https://customerjourney.flyhifinance.com'
//export let baseURL = 'http://localhost:8080'

const instanceUrl = axios.create({
  baseURL: baseURL,
  transformRequest: [
    function (data, headers) {
      let jwt = localStorage.getItem('jwt')
      if (jwt) {
        headers.Authorization = 'Bearer ' + jwt
      }
      headers['X-Trace-Id'] = getOrCreateTraceId();
      return JSON.stringify(data)
    }
  ],
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache'
  }
})

const instanceUrlWithMultipart = axios.create({
  baseURL: 'http://localhost:8080',
  transformRequest: [
    function (data, headers) {
      let jwt = localStorage.getItem('jwt')
      if (jwt) {
        headers.Authorization = 'Bearer ' + jwt
      }
      return JSON.stringify(data)
    }
  ],
  headers: {
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache'
  }
})

/**
 * httpservice for get and post json formatted data
 * @returns {*}
 */
export const httpservice = () => {
  instanceUrl.interceptors.request.use(request => Interseptor.requestHandler(request))
  instanceUrl.interceptors.response.use(
    response => Interseptor.successHandler(response),
    error => Interseptor.errorHandler(error)
  )
  return instanceUrl
}


/**
 * httpserviceWithMultipart for sending form data
 * @returns {*}
 */
export const httpserviceWithMultipart = () => {
  instanceUrlWithMultipart.interceptors.request.use(request => Interseptor.requestHandler(request))
  instanceUrlWithMultipart.interceptors.response.use(
    response => Interseptor.successHandler(response),
    error => Interseptor.errorHandler(error)
  )
  return instanceUrlWithMultipart
}

function getOrCreateTraceId() {
  let traceId = localStorage.getItem('traceId');
  if (!traceId) {
    traceId = 'trace-' + Math.random().toString(36).substr(2, 9);
    localStorage.setItem('traceId', traceId);  // Store it in local storage
  }
  return traceId;
}
