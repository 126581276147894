import * as React from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const loaderCss = {
    display: 'flex',
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6705882352941176)',
    zIndex: 9999999,
}

export default function CAppLoader() {
    const showLoader = useSelector((state) => state?.setAppLoader);
    return (
        showLoader &&
        <Box sx={loaderCss}>
            <CircularProgress />
        </Box>
    );
}