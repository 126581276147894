import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CButton from '../utils/CButton';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const columns = [
    { id: 'product', label: 'Product', minWidth: 170, align: 'left', },
    { id: 'detailId', label: 'Application Number', minWidth: 100, align: 'left', },
    {
        id: 'loanAmount',
        label: 'Loan Amount',
        minWidth: 100,
        align: 'left',
    },
    {
        id: 'loanTenure',
        label: 'Loan Tenure',
        minWidth: 100,
        align: 'left',
    },
    {
        id: 'emiAmount',
        label: 'EMI Ammount',
        minWidth: 100,
        align: 'left',
    },
    {
        id: 'button',
        label: '',
        minWidth: 50,
        align: 'left',
    },
];


export default function ActiveApplication({ activeApplications }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const userId = userData?.userId;

    function onResumeClick(e, row) {
        e.preventDefault();
        localStorage.setItem("detailId", row.detailId)
        // To DO handle tab and stepper count
        navigate(`${row?.formPath}`, { state: { tabValue: 2, stepperCount: 2, userDetailId: row.detailId } })

    }
    
    return (
        <div>
            {
                matches ?
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                {!activeApplications || !activeApplications?.length && <caption style={{ textAlign: "center" }}> No Active Loans available</caption>}
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {activeApplications && activeApplications
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code} >
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (

                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.id === 'product'
                                                                    ? 'FlyHi Shiksha Loan'
                                                                    : column.id === 'button' ? <CButton handleClick={(e) => onResumeClick(e, row)} name="Resume loan application" minWidth={true} /> : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper> :
                    <Box sx={{ width: '100%', overflow: 'hidden', pt: 4 }}>
                        {!activeApplications || !activeApplications?.length && <Typography style={{ textAlign: "center" }}> No Active Loans available</Typography>}

                        {activeApplications && activeApplications
                            .map((row) => {
                                return (
                                    <>
                                        <Box sx={{
                                            height: "100px",
                                            borderRadius: 4,
                                            bgcolor: '#E5E5E5',
                                            pt: 4,
                                            my: 4
                                        }}>
                                            <Typography variant='body3' sx={{ fontWeight: "Bold", color: "#202020" }}>FlyHi Shiksha</Typography>
                                            <Box sx={{ mt: 1 }}>
                                                <Typography variant='aadharLabel' sx={{ fontWeight: "Bold", color: "#616161" }}>{`INR ${row?.loanAmount} - ${row.loanTenure} months`}</Typography>
                                            </Box>
                                        </Box>
                                        <CButton width={"170px"} isLato={false} fontWeight={"Bold"} fontSize={"16px"} handleClick={(e) => onResumeClick(e, row)} name="Resume" minWidth={true} />
                                    </>
                                )
                            })}


                    </Box>
            }

        </div>
    );
}
