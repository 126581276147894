import axios from 'axios'
import { httpservice, baseURL } from './httpService'


/**
 * Initiate Doc upload
 */

export function uploadEsignDoc(detailId) {
    return httpservice()
        .post(`api/digio/upload?id=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function saveEsignTransactionData(detailId, txnId, message, errorCode) {
    return httpservice()
        .post(`api/digio/esignData?id=${detailId}&txnId=${txnId}&message=${message}&errorCode=${errorCode}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function getEsignData(detailId) {
    return httpservice()
        .get(`api/digio/esignData?id=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function registerUserMandate(detailId) {
    return httpservice()
        .post(`api/digio/mandate?id=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function getMandateData(detailId) {
    return httpservice()
        .get(`api/digio/getMandate?id=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}


export function saveMandateTransactionData(detailId, txnId, message, errorCode) {
    return httpservice()
        .post(`api/digio/saveMandate?id=${detailId}&txnId=${txnId}&message=${message}&errorCode=${errorCode}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function downloadPhysicalNachForm(detailId, mandateId) {
    return httpservice()
        .get(`api/digio/downloadPdf?id=${detailId}&mandateId=${mandateId}`,{
            responseType: 'blob',
            headers: {
                "Content-type": "application/pdf",
                "Accept": "application/pdf"
            },
        })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })



}

export function uploadPhysicalNachForm(formData) {
    // let jwt = localStorage.getItem('jwt')
    // const auth = 'Bearer ' + jwt
    // return httpservice()
    //     .post(`api/digio/uploadPdf`, formData,{
    //         headers: {
    //             "Content-type": "multipart/form-data",
    //             "Authorization": auth,
    //         },
    //     })
    //     .then(function (response) {
    //         return response
    //     })
    //     .catch(function (error) {
    //         return error.data
    //     })

    let jwt = localStorage.getItem('jwt')
    const auth = 'Bearer ' + jwt

    let URL = baseURL + `/api/digio/uploadPdf`
    return new Promise((resolve, reject) => {
        axios.post(
            URL,
            formData,
            {
                headers: {
                    "Authorization": auth,
                    "Content-type": "multipart/form-data",
                },
            }
        ).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            reject(error)
        })
    })



}