import { Avatar, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CButton from "../utils/CButton";
import CAlert from "../utils/CAlert";
import { useDispatch, useSelector } from "react-redux";
import { WebcamCapture } from "../../helper/Webcam";
import { setAppLoader } from "../../actions/setAppLoader";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { uploadFile } from "../../services/studentService";
import { faceVerify, deleteFile, saveFaceData, getFileImage, saveSalesId } from "../../services/faceVerificationService";
import { lead, uploadCb, onboard } from "../../services/lmsService"
import success from "../../assets/images/Vector.svg";
import failure from "../../assets/images/failure.svg";
import { saveFormPath } from "../../services/userService";
import { generatePDF, finalSubmit } from "../../services/pdfService";
import { getUserData } from "../../actions/getUserData";
import CInput from "../utils/CInput";
import { Box } from "@mui/system";
import { generateEquifax } from "../../services/pdfService";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FaceVerification({ setTabValue, setStepperCount, userDetailId }) {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const inputRef = React.useRef(null);
    const [openCamera, setOpenCamera] = React.useState(false)
    const [captureImage, setCaptureImage] = React.useState('')
    const [isVerified, setIsVerified] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('')
    const [uploadFileURL, setUploadFileURL] = React.useState('')
    const [faceMatchScore, setFaceMatchScore] = React.useState('')
    const [displayFile, setDisplayFile] = React.useState('')
    const [isSales, setIsSales] = React.useState('true')
    const [salesId, setSalesId] = React.useState('')
    const [salesIdData, setSalesIdData] = React.useState('')
    const [disableSubmit, setDisableSubmit] = React.useState(true);
    const [showTermsC, setShowTermsC] = React.useState(false);

    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userAadharImage = userDetails?.aadharDetails?.userImageId
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    useEffect(() => {
        dispatch(getUserData(userDetailId))
    }, []);

    React.useEffect(() => {
        dispatch(setAppLoader(false))
    }, [isFetching])

    useEffect(() => {
        (async function () {
            if (userDetails?.faceImage) {
                const splitFile = userDetails?.faceImage?.split("/")
                const obj = {
                    fileName: `${splitFile[3]}/${splitFile[4]}`
                }
                const dFile = await getFileImage(obj);
                setDisplayFile(dFile?.data)
                setIsVerified(true)
                setFaceMatchScore(userDetails?.userFaceMatchScore)
                setUploadFileURL(userDetails?.faceImage)
                setSalesId(userDetails?.salesPersonDetails?.salesId)
                setSalesIdData(userDetails?.salesPersonDetails?.salesPersonId)
            }


        })()
    }, [userDetails])

    function openCameraFunction() {
        setOpenCamera(true)
        setCaptureImage('')
    }
    const onRadioChange = (event) => {
        setIsSales(event.target.value);
    };

    useEffect(() => {
        if(!matches && +faceMatchScore > 75){
            setDisableSubmit(false)
        }else if (isSales === 'false' && +faceMatchScore > 75)
            setDisableSubmit(false)
        else if (isSales === 'true' && salesIdData && salesIdData.trim().length > 0 && + faceMatchScore > 75)
            setDisableSubmit(false)
        else
            setDisableSubmit(true)
    }, [isSales, salesIdData, faceMatchScore])

    function checkDisable(e) {
        if (!uploadFileURL)
            return false;
        if (!+faceMatchScore > 75)
            return false;
        if (isSales === 'true' && !salesIdData)
            return false
        if (isSales === 'true' && !salesIdData.trim().length > 0)
            return false
        return true;
    }

    async function onSubmitFaceVerication(e) {
        if(userDetails?.formPath !== "/faceMatch") {
            navigate(userDetails?.formPath, { state: { tabValue: 10, stepperCount: 6, userDetailId: userDetailId, alreadySubmitted: true } })
            return
        }
        e.preventDefault();
        dispatch(setAppLoader(true))
        const obj = {
            userInstituteDetailId: userDetails?.detailId,
            faceImage: uploadFileURL,
            faceMatchScore: +faceMatchScore
        }
        const saveFace = await saveFaceData(obj)
        const salesObj = {
            salesId: salesId,
            salesPersonId: salesIdData,
            createdBy: userDetails?.user?.userName,
            userInstituteDetailId: userDetails?.detailId
        }
        if (isSales === 'true') {

            await saveSalesId(salesObj)
        }
        //if (saveFace.status === 200) {
        // TO DO: Add spinner here
        await generatePDF(userDetails?.user?.userName, userDetailId)
        await generateEquifax(userDetails?.user?.userName, userDetails?.detailId)
        await lead(userDetails?.detailId)
        await uploadCb(userDetails?.detailId)
        const breResp = await onboard(userDetails?.detailId, null, null)
        //await finalSubmit(userDetails?.detailId, "Submit", "")
        dispatch(setAppLoader(false))
        //}
        setStepperCount(7)
        setTabValue(10)

        if (breResp.status && breResp.status === 200) {
            if (breResp.data.data.status === "Approved") {
                saveFormPath("/loanApproved", userDetails?.detailId)
                navigate("/loanApproved", { state: { tabValue: 10, stepperCount: 6, userDetailId: userDetailId } })
            } else if (breResp.data.data.status === "Rejected") {
                saveFormPath("/loanDeclined", userDetails?.detailId)
                navigate("/loanDeclined", { state: { tabValue: 10, stepperCount: 6, userDetailId: userDetailId } })
            } else {
                saveFormPath("/loanStatus", userDetails?.detailId)
                navigate("/loanStatus", { state: { tabValue: 10, stepperCount: 6, userDetailId: userDetailId } })
            }
        } else {
            saveFormPath("/loanStatus", userDetails?.detailId)
            navigate("/loanStatus", { state: { tabValue: 10, stepperCount: 6, userDetailId: userDetailId } })
        }


    }

    async function urltoFile(url, filename, mimeType) {
        const res = await fetch(url);
        const buf = await res.arrayBuffer();
        return new File([buf], filename, { type: mimeType });
    };

    async function verifyImage(imageToVerify) {
        const formData = new FormData();
        const url = imageToVerify
        const file = await urltoFile(url, `${userDetails?.user?.userName}.jpeg`, "image/jpeg")
        formData.append("file", file);
        formData.append("delimeter", userDetails?.user?.userName)
        formData.append("detailId", +localStorage.getItem('detailId'))
        const fileData = await uploadFile(formData, true)
        if (fileData.status === 200) {
            const verifedImage = await faceVerify(fileData.data, userAadharImage, +localStorage.getItem('detailId'))
            setIsVerified(verifedImage?.data?.result?.verified)
            setFaceMatchScore(+verifedImage?.data?.result?.matchPercentage.split('.')[0])
        } else {
            setError(true)
            setErrorMsg("Not Verified, Try Again")
        }
        await deleteFile(fileData.data)
        const formDataS3 = new FormData();
        formDataS3.append("file", file);
        formDataS3.append("delimeter", userDetails?.user?.userName)
        formDataS3.append("detailId", +localStorage.getItem('detailId'))
        await uploadFile(formDataS3, false).then(async res => {
            if (res?.status === 200) {
                setUploadFileURL(res?.data)
            }
        })
    }

    function handleBack() {
        if (userDetails?.instituteAddress?.instituteRiskCode === 4 || userDetails?.instituteAddress?.instituteRiskCode === 3) {
            userDetails?.aadharDetails?.isCurrentAddress ? navigate("/aadhaarDetails", { state: { tabValue: 6, stepperCount: 5, userDetailId: userDetailId } }) : navigate("/addNewAddress", { state: { tabValue: 7, stepperCount: 5, userDetailId: userDetailId } })
        } else {
            setTabValue(8)
            navigate("/bankDetails", { state: { tabValue: 8, stepperCount: 7, userDetailId: userDetailId } });
        }
    }

    function onCaptureImage(image) {
        setCaptureImage(image)
        verifyImage(image)
    }

    function showTerm() {
        setShowTermsC(!showTermsC)
    }

    return (
        <form onSubmit={(e) => onSubmitFaceVerication(e)} c className='applicationFormContainer' >
            {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
            }
            {
                errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
            }
            {
                !matches &&
                <Box sx={{ display: "flex" }}>
                    <ArrowBackIosIcon onClick={() => handleBack()} />
                </Box>
            }
            <Grid item xs={12} >
                {matches ?
                    <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Face Verifcation and Liveliness Check</Typography>
                    : null
                    // <Typography color='textColor' variant="subtitle3" sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}>Face Match</Typography>
                }
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                {matches ? <Grid sx={{ textAlign: "left" }} item xs={12}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Face Verification</Typography>
                </Grid> :
                    <Grid sx={{ textAlign: "center" }} item xs={12}>
                        <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Click picture</Typography>
                    </Grid>
                }
                {
                    matches ?
                        <Grid sx={{ textAlign: "left" }} item xs={6}>
                            {openCamera && !captureImage && <WebcamCapture setOpenCamera={setOpenCamera} setCaptureImage={(image) => onCaptureImage(image)} />}
                            {/* disabled={isVerified}  */}
                            {!openCamera && <Button onClick={openCameraFunction} color="uploadButton" fullWidth variant="contained" startIcon={<AddAPhotoIcon />}>
                                Open Camera
                            </Button>}
                        </Grid> :
                        <Grid sx={{ textAlign: "center" }} item xs={12}>
                            {openCamera && !captureImage && <WebcamCapture setOpenCamera={setOpenCamera} setCaptureImage={(image) => onCaptureImage(image)} />}
                            {!openCamera &&
                                <CameraAltOutlinedIcon sx={{ fontSize: 60 }} onClick={openCameraFunction} />
                            }
                        </Grid>
                }
                <Grid sx={{ textAlign: "left" }} item md={6}>
                </Grid>
                {captureImage && !isVerified &&
                    <Grid sx={{ textAlign: "left" }} item xs={12}>
                        <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Image</Typography>
                    </Grid>
                }
                <Grid sx={{ textAlign: "left", display: "flex", alignItems: "end", gap: "10px" }} item xs={12}>
                    {captureImage &&
                        <React.Fragment>
                            <img style={{ width: "30%", height: "100%" }} src={captureImage} />
                            {/* <CButton handleClick={() => verifyImage()} name="Verify" /> */}
                        </React.Fragment>
                    }
                </Grid>

                <Grid sx={{ display: "flex", alignItems: "center", gap: "5px" }} item xs={12}>
                    {
                        !captureImage ? null : isVerified ?
                            <React.Fragment>
                                <img src={success} />
                                <Typography color='textColor' variant='body1' sx={{ textAlign: "left" }}>Image Verified</Typography>
                            </React.Fragment> :
                            <React.Fragment>
                                <img src={failure} />
                                <Typography color='textColor' variant='body1' sx={{ textAlign: "left" }}>Image Verification Failed. Please Retry</Typography>
                            </React.Fragment>

                    }
                </Grid>

                {uploadFileURL && isVerified && displayFile &&
                    <Grid sx={{ textAlign: "left" }} item xs={6}>
                        <Avatar sx={{ width: 100, height: 100 }} src={displayFile} variant="square">
                        </Avatar>
                    </Grid>
                }
                {
                    !matches &&
                    <Grid item sx={{ my: 2 }} xs={12}>
                        <Divider style={{ background: '#616161' }} />
                    </Grid>
                }
                {matches &&
                    <Grid sx={{ mb: { xs: 5 }, my: { sm: 5 }, textAlign: "left" }} item xs={12}>
                        <Divider />
                    </Grid>
                }
                <Grid sx={{ pt: 1, textAlign: "left" }} item xs={12}>
                    {
                        matches ? <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Confirm Your Loan</Typography> :
                            <Typography color='textColor' variant='subtitle1' sx={{fontWeight: "bold", textAlign: "center", fontFamily: "Poppins" }}>Loan Summary</Typography>
                    }
                </Grid>
                <Grid sx={{ pt: 1, textAlign: "left" }} item xs={12}>
                    <Typography color='textColor' variant='Label20' sx={{ textAlign: "left" }}>Here is the summary of your selected scheme</Typography>
                </Grid>
                <Grid sx={{ mt: 3, textAlign: "left" }} item xs={12}>
                    <Typography color='textColor' variant='body1' sx={{ textAlign: "left" }}>Flyhi Study Loans- {userDetails?.instituteName?.instituteName} - {userDetails?.loanTenure} month</Typography>
                </Grid>
                {matches &&
                    <Grid sx={{ my: 1, textAlign: "left" }} item xs={12}>
                        <Divider />
                    </Grid>
                }
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Loan Amount</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{userDetails?.loanAmount}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Tenure</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{userDetails?.loanTenure}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>EMI Type</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{`${userDetails?.schemeDetails?.numberOfAdvanceEmis} - Advance`}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>EMI </Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{userDetails?.emiAmount} {userDetails?.instituteAddress?.interestBourneByInstitute ? "(This amount is not inclusive of interest. Interest will be paid by selected institute)" : null}</Typography>
                </Grid>
                {/* <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Interest Cost</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>xxxx</Typography>
                </Grid> */}
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>APR</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{userDetails?.apr}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Processing Fees</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{`${userDetails?.processingFees} (GST Applicable)`}</Typography>
                </Grid>
                {matches &&
                    <Grid sx={{ my: 1, textAlign: "left" }} item xs={12}>
                        <Divider />
                    </Grid>
                }
                {
                    matches ?
                        <>
                            <Grid sx={{ textAlign: "left", mt: 3 }} item xs={12}>
                                <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Did a sales person help you fill this applications?</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={isSales}
                                    name="radio-buttons-group"
                                    onChange={onRadioChange}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label={<Typography color='textColor' variant='aadharLabel' >Yes</Typography>} />
                                    <FormControlLabel value={false} control={<Radio />} label={<Typography color='textColor' variant='aadharLabel' >No</Typography>} />
                                </RadioGroup>
                            </Grid>
                            <Grid sx={{ display: "flex", textAlign: "left" }} item xs={12}>
                                <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Enter Sales Person ID/Name</Typography>
                                <Box sx={{ ml: 3, width: "150px" }}>
                                    <CInput name="salesID" disabled={isSales !== "true"} value={salesIdData} onChange={setSalesIdData} />
                                </Box>
                            </Grid>
                        </> :
                        <>
                            <Grid sx={{ display: "flex", textAlign: "left" }} item xs={12}>
                                <CInput label="Sales person id (if applicable)" name="salesID" value={salesIdData} onChange={setSalesIdData} />
                            </Grid>
                        </>
                }
                {matches ?
                    <Grid sx={{ textAlign: "left", mt: 3 }} item xs={12}>
                        <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Terms and Conditions</Typography>
                    </Grid> :
                    <Grid sx={{ pt: 1, textAlign: "left" }} item xs={12}>
                        {
                            <Grid sx={{ textAlign: "center", mt: 3, display: "flex", justifyContent: "center" }} item xs={12}>
                                <Typography color='textColor' variant='subtitle2' sx={{ fontWeight: "bold", textAlign: "center", fontFamily: "Poppins" }}>Terms and Conditions</Typography>
                                <ExpandMoreIcon sx={{ mt: 1 }} onClick={showTerm} />
                            </Grid>
                        }
                    </Grid>

                }

            </Grid>
            {matches ?
                <Grid sx={{ pt: 2, textAlign: "left", display: "flex", flexDirection: "column" }} item>
                    <Typography color='textColor' variant='aadharLabel' >1. The loan disbursement is subject to successful payment of processing fee and advance EMI if applicable.</Typography>
                    <Typography color='textColor' variant='aadharLabel' >
                        2. In case the Processing Fee and Advance EMI (if applicable) is not paid after signing the loan agreement, the customer hereby authorizes Flyhi Finance to collect using the registered mandate.</Typography>
                    <Typography color='textColor' variant='aadharLabel' >3. Disbursement will be made to Institute's Bank account after mandate registration and verification of documents if any.</Typography>
                    <Typography color='textColor' variant='aadharLabel' >4. Detailed repayment schedule and welcome letter will be sent to your registered email ID.</Typography>
                    <Typography color='textColor' variant='aadharLabel' >5. By signing the loan agreement, we assume you have read the Terms and Conditions.</Typography>
                </Grid> :
                showTermsC &&
                <Grid sx={{ pt: 2, textAlign: "left", display: "flex", flexDirection: "column" }} item>
                    <Typography color='textColor' variant='aadharLabel' >1. The loan disbursement is subject to successful payment of processing fee and advance EMI if applicable.</Typography>
                    <Typography color='textColor' variant='aadharLabel' >
                        2. In case the Processing Fee and Advance EMI (if applicable) is not paid after signing the loan agreement, the customer hereby authorizes Flyhi Finance to collect using the registered mandate.</Typography>
                    <Typography color='textColor' variant='aadharLabel' >3. Disbursement will be made to Institute's Bank account after mandate registration and verification of documents if any.</Typography>
                    <Typography color='textColor' variant='aadharLabel' >4. Detailed repayment schedule and welcome letter will be sent to your registered email ID.</Typography>
                    <Typography color='textColor' variant='aadharLabel' >5. By signing the loan agreement, we assume you have read the Terms and Conditions.</Typography>
                </Grid>
            }
            <div style={{ padding: "20px 0px 0px", display: "flex", flexDirection: "row", justifyContent: `${!matches ? "space-around" : "space-between"}` }}>
                {matches && <CButton handleClick={() => handleBack()} name="Back" />}
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    disabled={disableSubmit}
                    variant="contained">
                    Save & Submit
                </Button>
            </div>
        </form >
    )
}