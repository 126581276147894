import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';


const columns = [
    { id: 'product', label: 'Product', minWidth: 170, align: 'left', key: 1 },
    { id: 'detailId', label: 'Application Number', minWidth: 100, align: 'left', key: 2 },
    {
        id: 'loanAmount',
        label: 'Loan Amount',
        minWidth: 100,
        align: 'left',
        key: 3
    },
    {
        id: 'loanTenure',
        label: 'Loan Tenure',
        minWidth: 100,
        align: 'left',
        key: 4
    },
    {
        id: 'emiAmount',
        label: 'EMI Ammount',
        minWidth: 100,
        align: 'left',
        key: 5
    },
    {
        id: 'status',
        label: 'Status',
        minWidth: 50,
        align: 'left',
        key: 6
    },
];



export default function ActiveLoans({ activeLoans }) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const allUsersData = localStorage.getItem('mobileData') ? JSON.parse(localStorage.getItem('mobileData')) : null

    return (
        <div>
            {
                matches ?
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                {!activeLoans || !activeLoans?.length && <caption style={{ textAlign: "center" }}> No Active Loans available</caption>}
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activeLoans?.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code} >
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (

                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.id === 'product'
                                                                ? 'FlyHi Shiksha Loan'
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    :
                    <Box sx={{ width: '100%', overflow: 'hidden', pt: 4 }}>
                        {!activeLoans || !activeLoans?.length && <Typography style={{ textAlign: "center" }}> No Active Loans available</Typography>}

                        {activeLoans && activeLoans
                            .map((row) => {
                                return (
                                    <Box sx={{
                                        height: "100px",
                                        borderRadius: 4,
                                        bgcolor: '#E5E5E5',
                                        pt: 4
                                    }}>
                                        <Typography variant='body3' sx={{ fontWeight: "Bold", color: "#202020" }}>FlyHi Shiksha</Typography>
                                        <Box sx={{ mt: 1 }}>
                                            <Typography variant='aadharLabel' sx={{ fontWeight: "Bold", color: "#616161" }}>{`INR ${row?.loanAmount} - ${row.loanTenure} months`}</Typography>
                                        </Box>
                                    </Box>
                                )
                            })}
                    </Box>

            }
        </div>
    );
}
