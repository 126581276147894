import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { signUPOTP } from '../../services/authenticationService';
import CInput from '../utils/CInput';
import { setAppLoader } from "../../actions/setAppLoader";
import { useDispatch } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function OTPModal(props) {
    const { mobileNumber, details, setMobileNumberVerified, openOtp, setOpenOtp, setUserId } = props

    const dispatch = useDispatch()
    const [otp, setOtp] = React.useState();
    const [error, setError] = React.useState();
    const handleClose = () => {
        setOpenOtp(false);
    };


    const verifyOTP = () => {
        dispatch(setAppLoader(true))
        const otpData = {
            otp: otp,
            phoneNo: mobileNumber,
            details: details,
            verify: true
        }
        setError(null)
        signUPOTP(otpData).then(data => {
            if (data.status === 200 || data.status === 201) {
                setMobileNumberVerified(true)
                setOpenOtp(false);
                setUserId(data?.data?.userId)
                localStorage.setItem("jwt", data?.data?.jwt)
                localStorage.setItem("userData", JSON.stringify(data?.data))
                console.log(data)
            } else {
                console.log(data.response.data.message)
                setError(data.response.data.message)
            }
        }).catch(error => {
            //setError(true)
            console.log('error', error)
        })
        dispatch(setAppLoader(false))

    };

    function handleChange() {
        setOtp(otp)
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openOtp}

            >
                <DialogContent sx={{ margin: "30px" }}>
                    <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Enter OTP</Typography>
                    <CInput type="number" helperText={error} isError={error ? true : false} placeholder="xxx-xxx" autoFocus={true} value={otp} onChange={setOtp} name="otp" label="OTP" />

                </DialogContent>
                <div style={{ padding: "5px 40px 20px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>                   
                        <Button
                            type="button"
                            sx={{ fontStyle: "Lato" }}
                            color="neutral"
                            variant="contained"
                            onClick={verifyOTP}
                            disabled={!otp}
                        >
                            Verify
                        </Button>
                    
                        <Button
                            type="button"
                            sx={{ fontStyle: "Lato" }}
                            color="neutral"
                            variant="contained"
                            onClick={handleClose}
                        >
                            Close
                        </Button>

                    </div>
            </BootstrapDialog>
        </div>
    );
}
