
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validMobileNumber } from "../../custom-hooks/validRegex";
import { getEmiAndProcessingFee, getLoanTenureService } from "../../services/applicationFormService";
import { equifaxSoftPull, loanEligibilityCheck, userConsent, verifyMobile, verifyPanExtensive, verifyPin } from "../../services/authenticationService";
import ErrorModal from "../common/ErrorModal";
import TermsAndCondition from "../common/TermAndCondition";
import CAlert from "../utils/CAlert";
import CInput from "../utils/CInput";
import CSelectBox from "../utils/CSelectBox";
import AddressModal from "./AddressModal";
import EligibilityModal from "./EligibilityModal";
import OTPModal from "./OTPModal";
import { setAppLoader } from "../../actions/setAppLoader";
import success from "../../assets/images/Vector.svg";
import EvaluationDeclaration from "./EvaluationDeclaration";
import Privacy from "../common/Privacy";

export default function SelfEvaluationVerification(props) {

    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = React.useState(null);
    const [phoneError, setPhoneError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('')
    const [otp, setOtp] = React.useState([]);
    const [mobileNumberVerified, setMobileNumberVerified] = React.useState(false);
    const [details, setDetails] = React.useState();
    const [pinCode, setPinCode] = React.useState();
    const [pinCodeVerified, setpinCodeVerified] = React.useState(false);
    const [panNumber, setPanNumber] = React.useState();
    const [panNumberVerified, setPanNumberVerified] = React.useState(false);
    const [loanTenure, setLoanTenure] = React.useState('');
    const [emi, setEMI] = React.useState(0);
    const [maintainMinimumBal, setMaintainMinimumBalance] = React.useState(0);
    const [openOtp, setOpenOtp] = React.useState(false);
    const [openAddr, setOpenAddr] = React.useState(false);
    const [locationError, setLocationError] = React.useState(false)
    const [isAccepted, setIsAccepted] = React.useState(false)
    const [userId, setUserId] = React.useState();
    const [eligibility, setEligibility] = React.useState();
    const [openEligibility, setOpenEligibility] = React.useState(false);

    const [loanAmount, setLoanAmount] = React.useState('');
    const [loanTenureList, setLoanTenureList] = React.useState([]);
    const [openTC, setOpenTC] = React.useState(false)
    const [openPrivacy, setOpenPrivacy] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [invalidPanCount, setInvalidPanCount] = React.useState(0)
    const [technicalError, setTechnicalError] = React.useState(false);
    // const [openDecl, setOpenDecl] = React.useState(true)

    const dispatch = useDispatch()



    async function onSendOTPClick() {
        dispatch(setAppLoader(true))
        if (!validMobileNumber.test(mobileNumber)) {
            setPhoneError(true)
            setErrorMsg("Please Enter Valid Mobile Number")
            dispatch(setAppLoader(false))
        } else {
            await verifyMobile(mobileNumber).then(data => {
                if (data.status === 200 || data.status === 201) {
                    setDetails(data?.data?.details)
                    setOpenOtp(true);
                    setPhoneError(false)
                    const mobileData = {
                        details: data?.data?.details,
                        mobile: mobileNumber
                    }
                    localStorage.setItem('mobileData', JSON.stringify(mobileData));
                } else {
                    setPhoneError(true)
                    setErrorMsg(data?.response?.data?.message)
                }
                dispatch(setAppLoader(false))
            }).catch(error => {
                console.log('error', error)
                dispatch(setAppLoader(false))
            })
        }
    }

    async function onPinVerifyClick(e) {
        e.preventDefault();
        dispatch(setAppLoader(true))
        verifyPin(pinCode).then(data => {
            if (data.status === 200 || data.status === 201) {
                setpinCodeVerified(true)
            } else if (data.response.status === 400) {
                setpinCodeVerified(false)
            } else if (data.response.status === 451) {
                setpinCodeVerified(false)
                setLocationError(true)
            }

        });

        await getLoanTenureService("").then((res) => {
            const tempLoanData = []
            for (const props in res) {
                tempLoanData.push({
                    name: res[props].schemeTenure,
                    value: res[props].schemeTenure,
                    advEmi: res[props].numberOfAdvanceEmis
                })
            }
            setLoanTenureList(tempLoanData)
        });
        dispatch(setAppLoader(false))
    }


    // call API on change of Loan Amount and tenure 
    function callEmiAndProcessingFee(obj) {
        dispatch(setAppLoader(true))
        getEmiAndProcessingFee(obj).then(data => {
            const response = {
                emi: data?.emi,
                processingFee: data?.processingFee,
                numberOfAdvanceEmi: data?.numberOfAdvanceEmi,
                interestBourneByInstitute: data?.interestBourneByInstitute
            }
            setEMI(response.emi);

        }).catch(error => {
            console.log('error', error)
        })
        dispatch(setAppLoader(false))
    }

    // on Change LoanAmount
    function onLoanAmountChange(value) {
        setLoanAmount(value);
        const obj = {
            tenure: loanTenure,
            advanceEmis: loanTenureList.filter(e => e.value === loanTenure)[0]?.advEmi,
            loanAmount: +value,
            courseId: 0,
            isOtherCourse: true
        }
        if (value && loanTenure)
            callEmiAndProcessingFee(obj)

    }


    function loanTenureHandler(value, advEmi) {
        setLoanTenure(value)
        const obj = {
            tenure: value,
            advanceEmis: loanTenureList.filter(e => e.value === value)[0].advEmi,
            loanAmount: +loanAmount,
            courseId: 0,
            isOtherCourse: true
        }
        console.log(obj)
        callEmiAndProcessingFee(obj)
    }

    const onRadioChange = (event) => {
        setMaintainMinimumBalance(event.target.value);
    };

    async function onPanVerifyClick(e) {
        e.preventDefault();
        dispatch(setAppLoader(true))
        const obj = {
            pan: panNumber,
            userId: userId
        }


        await verifyPanExtensive(obj).then(async data => {
            console.log(data?.response?.status)
            if (data?.data) {
                setError(false)
                console.log(data?.data)
                setInvalidPanCount(0);
                await equifaxSoftPull(data?.data?.panDetailsId).then(resp => {
                    console.log(resp)
                    if (!resp?.status)
                        setTechnicalError(true)
                    else
                        setPanNumberVerified(true)

                })

            }
            else {
                console.log(invalidPanCount)
                setError(true)
                if (invalidPanCount > 1)
                    setOpenEligibility(true)
                setInvalidPanCount(invalidPanCount + 1)
            }
        });
        dispatch(setAppLoader(false))
    }


    function openModel() {
        setOpenTC(true)
    }

    function openPrivacyModel() {
        setOpenPrivacy(true)
    }


    async function checkEligibility(e) {
        e.preventDefault();
        dispatch(setAppLoader(true))

        const consentObj = {
            userId: userId,
            source: "web",
            consent: true
        }
        await userConsent(consentObj).then(data => {
            console.log(data)
        });


        const obj = {
            loanAmount: loanAmount,
            tenure: loanTenure,
            emi: emi,
            maintainBalance: maintainMinimumBal === 'yes' ? true : false,
            pan: panNumber,
            userId: userId,
            selfEvaluation: true,
            detailId: null
        }
        console.log(obj)
        await loanEligibilityCheck(obj).then(data => {
            console.log(data?.data?.Eligibility)
            setEligibility(data?.data?.Eligibility)
            setOpenEligibility(true)
        });
        dispatch(setAppLoader(false))
    }

    return (
        <form onSubmit={(e) => checkEligibility(e)} className='applicationFormContainer' >
            {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
            }
            {
                errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
            }
            <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left", fontWeight: "bold" }}>Welcome to Flyhi Finance!</Typography>

            <Typography color='textColor' variant='body2' sx={{ textAlign: "left" }}>Lets quickly check your loan eligibility.</Typography>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Box className='form' sx={{ mt: 1 }} >
                        <CInput type="number" helperText={errorMsg} isError={phoneError ? true : false} maxLength={10} autoFocus={true} name="phone" label="Mobile Number*" value={mobileNumber} onChange={setMobileNumber} disabled={mobileNumberVerified} />
                    </Box>

                </Grid>
                <Grid item xs={6}>
                    <div style={{ padding: "45px 0px 0px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        {mobileNumberVerified ? <React.Fragment>
                            <img src={success} />
                        </React.Fragment> : <Button
                            type="button"
                            sx={{ fontStyle: "Lato" }}
                            color="neutral"
                            disabled={!mobileNumber || mobileNumber.length != 10 || mobileNumberVerified}
                            variant="contained"
                            onClick={onSendOTPClick}>
                            Validate
                        </Button>}
                    </div>

                </Grid>
                <Grid item xs={6}>
                    <Box className='form' sx={{ mt: 1 }}>
                        <CInput type="number" maxLength={10} name="pin" label="Current Pincode*" value={pinCode} onChange={setPinCode}
                            disabled={!mobileNumberVerified || pinCodeVerified}
                        />
                    </Box>

                </Grid>
                <Grid item xs={6}>
                    <div style={{ padding: "45px 0px 0px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        {pinCodeVerified ? <React.Fragment>
                            <img src={success} />
                        </React.Fragment> : <Button
                            type="button"
                            sx={{ fontStyle: "Lato" }}
                            color="neutral"
                            disabled={!pinCode || pinCodeVerified}
                            variant="contained"
                            onClick={(e) => onPinVerifyClick(e)}>
                            Validate
                        </Button>}
                    </div>

                </Grid>
                <Grid item xs={6}>
                    <Box className='form' sx={{ mt: 1 }}>
                        <CInput type="number" maxLength={10} name="loanAmount" value={loanAmount} onChange={onLoanAmountChange} label="Loan Amount*"
                            disabled={!pinCodeVerified} eval={true}
                        />
                    </Box>

                </Grid>
                <Grid item xs={6} sx={{ mt: 1 }}>

                    {/* dummy grid */}

                </Grid>
                <Grid item xs={6} sx={{ mt: 1 }}>
                    <CSelectBox onChange={(e) => loanTenureHandler(e)} optionList={loanTenureList} defaultValue={loanTenure ?? ""} name="lonTenure" label="Loan Tenure*"
                        disabled={!loanAmount}
                    />
                </Grid>
                <Grid item xs={6} sx={{ mt: 1 }}>

                    {/* dummy grid */}

                </Grid>


                <Grid item xs={6} sx={{ mt: 1 }} >
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <CInput value={emi} name="emi" label="EMI Amount" disabled={true} />

                    </div>
                </Grid>

                <Grid item xs={12} sx={{ mt: 1 }}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        onChange={onRadioChange}
                        name="radio-buttons-group"
                        disabled={!loanTenure}

                    >
                        <Typography color='textColor' variant='inputLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Do you maintain minimum average bank balance to pay EMI shown above ?</Typography>
                        <FormControlLabel value="yes" control={<Radio />} label={<Typography color='textColor' variant='inputLabel' >Yes</Typography>} />
                        <FormControlLabel value="no" control={<Radio />} label={<Typography color='textColor' variant='inputLabel' >No</Typography>} />

                    </RadioGroup>
                </Grid>
                <Grid item xs={6}>
                    <Box className='form' sx={{ mt: 1 }}>
                        <CInput type="text" helperText="Invalid PAN, please try again" isError={error ? true : false} maxLength={10} name="phone" value={panNumber} onChange={(e) => setPanNumber(e.toUpperCase())} label="PAN number*" disabled={!loanTenure || panNumberVerified} />
                    </Box>

                </Grid>
                <Grid item xs={6}>
                    <div style={{ padding: "45px 0px 0px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        {panNumberVerified ? <React.Fragment>
                            <img src={success} />
                        </React.Fragment> : <Button
                            type="button"
                            sx={{ fontStyle: "Lato" }}
                            color="neutral"
                            disabled={!panNumber || panNumberVerified}
                            variant="contained"
                            onClick={(e) => onPanVerifyClick(e)}>
                            Validate
                        </Button>}
                    </div>

                </Grid>
                <Grid item sx={{ textAlign: "left" }} xs={12}>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <FormGroup >
                            <FormControlLabel
                                control={<Checkbox style={{ color: "#D32F2F" }} checked={isAccepted} onChange={() => setIsAccepted(!isAccepted)} name="gilad" />}
                            />
                        </FormGroup>
                        <div style={{ fontSize: "16px" }}>
                            <div style={{ marginTop: "7px" }}>I/We hereby agree and acknowledge that:</div>
                            <div>
                                <div>I/ We are above 18 years of age.</div>
                                <div>I/ We are the Citizen/s and Resident/s of India.</div>
                                <div>I/ We can understand, read and write in the English language.</div>
                                <div>I/ We have read and understood the terms and conditions</div>
                                <div>
                                    <a onClick={openModel} style={{ cursor: "pointer", color: "#D32F2F", borderBottom: "1px solid #D32F2F" }}>Terms And Conditions</a>
                                </div>
                                <div>{/* Additional content can go here if needed */}</div>
                                {/* <a onClick={openPrivacyModel} style={{ cursor: "pointer", color: "#D32F2F", borderBottom: "1px solid #D32F2F" }}>Privacy and Security Policy*. </a>
                <span>
                    I also authorise Flyhi Finance to check my credit score for loan eligiblity. I also understand that eligibility is provisional and subject to more exhaustive checks upon actual application. By submitting my details, I override my NDNC registration and authorize Fly Hi Financial Services Ltd and its representatives to contact me through call, RCS, WhatsApp or E-mail.
                </span> */}
                            </div>
                        </div>
                    </div>
                </Grid>


            </Grid>
            <div style={{ padding: "20px 0px 0px", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    variant="contained"
                    disabled={!panNumberVerified || !isAccepted}>
                    Check my Eligibility
                </Button>
            </div>

            {openTC && <TermsAndCondition open={openTC} setOpen={setOpenTC} />}
            {openPrivacy && <Privacy open={openPrivacy} setOpen={setOpenPrivacy} />}
            {openOtp && <OTPModal mobileNumber={mobileNumber} details={details} otp={otp} openOtp={openOtp} setOpenOtp={setOpenOtp} setOtp={setOtp} setMobileNumberVerified={setMobileNumberVerified} setUserId={setUserId} />}
            {openAddr && <AddressModal mobileNumber={mobileNumber} details={details} otp={otp} openOtp={openOtp} setOpenOtp={setOpenOtp} setOtp={setOtp} setMobileNumberVerified={setMobileNumberVerified} />}
            {locationError && <ErrorModal open={locationError} setOpen={setLocationError} />}
            {technicalError && <ErrorModal open={technicalError} setOpen={setTechnicalError} isTechnicalError={true} />}
            {openEligibility && <EligibilityModal userId={userId} setOpenEligibility={setOpenEligibility} eligibility={eligibility} />}
            {/* {openDecl && <EvaluationDeclaration openDecl={openDecl} setOpenDecl={setOpenDecl} />} */}
        </form >
    )
}