import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import ApplicationRecord from './ApplicationRecord';
import { getUserDetails } from '../../services/userService';
import { assignRecordToAdmin, getSchemeDetails } from '../../services/AdminServices';
import { getBreResponse } from '../../services/lmsService';
import { useDispatch } from "react-redux";
import { setAppLoader } from "../../actions/setAppLoader";
import CAlert from '../utils/CAlert';
import { getEsignData, getMandateData } from '../../services/loanApprovalService';
import { getPaymentDetails } from '../../services/easeBuzzService';

const columns = [
   { id: 'loanType', label: 'Loan Type', minWidth: 80, align: 'left', },
  // { id: 'instituteFcpId', label: 'FCP ID', minWidth: 80, align: 'left', },
  // { id: 'salesPersonId', label: 'Sales Person ID', minWidth: 170, align: 'left', },
  { id: 'name', label: 'Name', minWidth: 100, align: 'left', },
  { id: 'applicationNumber', label: 'App Id', minWidth: 100, align: 'left', },
  {
    id: 'instituteName',
    label: 'Institute Name',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'city',
    label: 'Location',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'updatedStatus',
    label: 'Application Status',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'timestamp',
    label: 'Submission Time',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'breCompletionTimeStamp',
    label: 'Decision Time',
    minWidth: 100,
    align: 'left',
  },
];


function createData(loanType, applicationNumber, instituteName, instituteFcpId, salesPersonId, name, city, status, date, breCompletionTimeStamp) {

  const timestamp = format(new Date(date), "yyyy-MM-dd HH:mm:ss")
  let updatedStatus = (status === "Submit" ? "Submitted" : status);
  return { loanType, applicationNumber, instituteName, instituteFcpId, salesPersonId, name, city, updatedStatus, timestamp, breCompletionTimeStamp };
}

export default function AdminTable({ adminData, getAdminData, filterValue }) {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState()
  const [breResponse, setBreResponse] = React.useState()
  const [adminDeviationResponse, setAdminDeviationResponse] = React.useState()
  const [breException, setBreException] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState('')
  const [schemeData, setSchemeData] = React.useState()
  const [eSignData, setESignData] = React.useState()
  const [paymentData, setPaymentData] = React.useState()
  const [mandateData, setMandateData] = React.useState()
  const [count, setCount] = React.useState()
  const [breFallBackResponse, setBreFallBackResponse] = React.useState()
  const userData = localStorage.getItem('mobileData') ? JSON.parse(localStorage.getItem('mobileData')) : null
  const userName = userData?.userName;


  React.useEffect(() => {
    const tempRows = []
    adminData?.map((item) => {
      tempRows.push(createData(item?.loanType, item?.applicationNumber, item?.instituteName, item?.instituteFcpId,item?.salesPersonId,item?.name, item?.city, item?.status, item?.timestamp, item?.breCompletionTimeStamp))
    })
    setCount(adminData && adminData[0]?.count)
    setRows(tempRows)
  }, [adminData])


  React.useEffect(() => {
    let offSet = page * rowsPerPage
    getAdminData(offSet, rowsPerPage, "timestamp", filterValue,)
  }, [page, rowsPerPage, open])

  React.useEffect(() => {
    if (filterValue) {
      setPage(0)
      setRowsPerPage(10)
      getAdminData(0, 10, "timestamp", filterValue,)
    }
  }, [filterValue])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  async function fetchUserData(id) {
    dispatch(setAppLoader(true))
    const userData = await getUserDetails(id)
    const breResponseData = await getBreResponse(id)
    const resp = await assignRecordToAdmin(id, userName)
    const eSignResp = await getEsignData(id)
    const paymentResp = await getPaymentDetails(id)
    const mandateResp = await getMandateData(id)
    setESignData(eSignResp?.data);
    setPaymentData(paymentResp?.data);
    setMandateData(mandateResp?.data);
    if(resp.status === 'success' ) {
      setRowData(userData)
      setBreResponse(breResponseData?.lmsResponse ? JSON.parse(breResponseData?.lmsResponse) : null)
      setAdminDeviationResponse(breResponseData?.lmsAdminDeviationResponse ? JSON.parse(breResponseData?.lmsAdminDeviationResponse) : null)
      if (breResponseData?.leadException != null || breResponseData?.cbException != null || breResponseData?.onboardingException != null) {
        setBreException(true)
      } else setBreException(false)
      setBreFallBackResponse(breResponseData?.lmsAdminFallbackResponse)
    } else {
      setError(true)
      setErrorMsg(resp.message)
    }
    dispatch(setAppLoader(false))

  }

  React.useEffect(() => {
    (async () => {
      if(rowData?.loanTenure) {

        const scheme = await getSchemeDetails(rowData?.instituteCourse?.isOtherCourse ? 0 : +rowData?.instituteCourse?.instituteCourseId, +rowData?.loanTenure)
        setSchemeData(scheme);
        setOpen(true)
      }
    })();
  }, [rowData])

  return (
    <div>
      {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
      }
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .map((row) => {
                  return (
                    <TableRow style={{cursor: "pointer"}} hover role="button" tabIndex={-1} key={row.code} onClick={() => fetchUserData(+row.applicationNumber)}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Done there is no default value for pagination drop down, 10 should be default */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, 200, 300]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {rowData && <ApplicationRecord open={open} setOpen={setOpen} selectedRow={rowData} scheme={schemeData} breData={breResponse} adminDeviationResponse={adminDeviationResponse} breException={breException} breFallBackResponse={breFallBackResponse} eSignData={eSignData} paymentData={paymentData} mandateData={mandateData} fetchUserData={fetchUserData}/>}
    </div>
  );
}
