import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
  'Sign Up',
  'Institite & Course Selection',
  'Student Detail',
  'Income & Employement',
  'Aadhaar & PAN',
  'FaceMatch & Liveliness',
  'Loan Agreement',
  'Mandate Registration & Payments',
  'Finish'
];

const stepStyle = {
  root: {
    width: '100%',
    marginRight: 700,
  },
  '& .MuiStepLabel-root .Mui-completed': {
    color: '#D32F2F', // circle color (COMPLETED)
  },
  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
  {
    color: '#A3A3A3', // Just text label (COMPLETED)
    fontSize: "11px"
  },
  '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel':
  {
    color: '#A3A3A3', // Just text label (COMPLETED)
    fontSize: "11px"
  },
  '& .MuiStepLabel-root .Mui-active': {
    color: '#D32F2F', // circle color (ACTIVE)
  },
  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
  {
    color: '#4D4948', // Just text label (ACTIVE)
    fontSize: "11px"
  },
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: '#ffffff', // circle's number (ACTIVE)
  },
}

export default function CStepper(props) {
  const { tabValue } = props;
  return (
    <Box sx={{ width: '100%', display: { xs: 'none', md: 'block', lg: 'block' } }}>
      <Stepper activeStep={tabValue} alternativeLabel>
        {steps.map((label) => (
          <Step sx={stepStyle} key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
