import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { InputLabel, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

export default function CTextField(props) {
    const { label, value, onChange, disabled } = props;
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    function isValidFunction(event) {
        let value = event.target.value
        onChange(value)
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "-webkit-fill-available" }}>

            <InputLabel htmlFor="input-with-icon-adornment">
                <Typography fontSize={matches ? "14px" : "10px"} color="inputLabelColor" sx={{ fontWeight: "bold", fontFamily: "lato" }} align='left' variant="inputLabel">
                    {label}
                </Typography>
            </InputLabel>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '40ch' },
                }}
                noValidate
                autoComplete="off"

            >
                <div>

                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        onChange={isValidFunction}
                        defaultValue={value}
                        fullWidth
                        disabled={disabled}
                    />
                </div>

            </Box>
        </div>
    );
}
