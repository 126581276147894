
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Button, FormGroup, FormControlLabel, Checkbox, useMediaQuery, Divider } from "@mui/material";
import Grid from '@mui/material/Grid';
import CInput from "../utils/CInput";
import CSelectBox from "../utils/CSelectBox";
import { getEmiAndProcessingFee, saveInstituteData } from "../../services/applicationFormService";
import { getInstituteTypes } from "../../actions/getInstituteType";
import { getInstituteName } from "../../actions/getInstituteName";
import { getInstituteAddress } from "../../actions/getInstituteAddress";
import { getInstituteCourse } from "../../actions/getInstituteCourse";
import { getLoanTenureData } from "../../actions/getLoanTenureData";
import { getUserData } from "../../actions/getUserData";
import CloseIcon from '@mui/icons-material/Close';
import CAlert from "../utils/CAlert";
import { setAppLoader } from "../../actions/setAppLoader";
import Tooltip from '@mui/material/Tooltip'
import TermsAndCondition from "../common/TermAndCondition";
import { saveFormPath } from "../../services/userService";
import CDialogue from "../utils/CDialogue";
import FCDModal from "./FCDModal";
import { userConsent } from "../../services/authenticationService";

export default function InstituteSelection(props) {
    const { setTabValue, setStepperCount, userDetailId } = props;
    const [isOtherSelected, setIsOtherSelected] = React.useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [instituteType, setInstituteType] = React.useState([]);
    const [selectedInstituteType, setSelectedInstituteType] = React.useState('');
    const [instituteNames, setInstituteNames] = React.useState([]);
    const [otherInstituteNames, setOtherInstituteNames] = React.useState('');
    const [selectedInstituteName, setSelectedInstituteName] = React.useState('');
    const [otherInstituteNameId, setOtherInstituteNameid] = React.useState(null);
    const [otherAddressId, setOtherAddressid] = React.useState(null);
    const [otherCourseId, setOtherCourseid] = React.useState(null);
    const [instituteAddress, setInstituteAddress] = React.useState([]);
    const [otherInstituteAddress, setOtherInstituteAddress] = React.useState('');
    const [selectedInstituteAddress, setSelectedInstituteAddress] = React.useState('');
    const [instituteCourse, setInstituteCourse] = React.useState([]);
    const [OtherinstituteCourse, setOtherInstituteCourse] = React.useState('');
    const [selectedInstituteCourse, setSelectedInstituteCourse] = React.useState('');
    const [courseDuration, setCourseDuration] = React.useState('');
    const [courseFees, setCourseFees] = React.useState('');
    const [loanAmount, setLoanAmount] = React.useState('');
    const [loanAmountError, setLoanAmountError] = React.useState(false);
    const [loanTenureList, setLoanTenureList] = React.useState([]);
    const [loanTenure, setLoanTenure] = React.useState('');
    const [advanceEMI, setAdvanceEMI] = React.useState(0);
    const [emi, setEMI] = React.useState(0);
    const [processingFees, setProcessingFees] = React.useState(0);
    const [isAccepted, setIsAccepted] = React.useState(false);
    const [openTC, setOpenTC] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('');
    const [error, setError] = React.useState(false)
    const [showRiskModal, setShowRiskModal] = React.useState(false)
    const [showFCPModal, setShowFCPModal] = React.useState(false)
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const storedFcp = localStorage.getItem('fcpId')
    const userId = userData?.userId;
    const [riskCode, setRiskCode] = React.useState()
    const [FCPID, setFCPID] = React.useState('')
    const [schemeMoratorium, setSchemeMoratorium] = React.useState(null)
    const [interestBourneByInstitute, setInterestBourneByInstitute] = React.useState(false)
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));


    // fetch data Institute Type 
    useEffect(() => {
        dispatch(getInstituteTypes());
        dispatch(getUserData(userDetailId))
    }, []);

    const instituteData = useSelector((state) => state?.getInstituteType?.data);
    const instituteName = useSelector((state) => state?.getInstituteName?.data);
    const instituteAddressData = useSelector((state) => state?.getInstituteAddress?.data);
    const instituteCourseData = useSelector((state) => state?.getInstituteCourse?.data);
    const loadTenureData = useSelector((state) => state?.getLoanTenure?.data);
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);

    useEffect(() => {
        dispatch(setAppLoader(isFetching))
    }, [isFetching])

    // Check for button disabled
    const disabledButton = localStorage.getItem("eligibility") === 'true' ? !selectedInstituteType || (selectedInstituteName === 'other' ? !otherInstituteNames : !selectedInstituteName) || (selectedInstituteAddress === 'other' ? !otherInstituteAddress : !selectedInstituteAddress) || (selectedInstituteCourse === 'other' ? !OtherinstituteCourse : !selectedInstituteCourse)
        || !courseFees || !loanAmount || !loanTenure || !emi : !selectedInstituteType || (selectedInstituteName === 'other' ? !otherInstituteNames : !selectedInstituteName) || (selectedInstituteAddress === 'other' ? !otherInstituteAddress : !selectedInstituteAddress) || (selectedInstituteCourse === 'other' ? !OtherinstituteCourse : !selectedInstituteCourse)
        || !courseFees || !loanAmount || !loanTenure || !emi || !isAccepted

    // Get All Institute List Data
    useEffect(() => {
        if (userDetails?.instituteType?.instituteTypeId) {
            dispatch(getInstituteName(userDetails?.instituteType?.instituteTypeId));
            dispatch(getInstituteAddress(userDetails?.instituteName?.instituteId));
            dispatch(getInstituteCourse(userDetails?.instituteAddress?.instituteAddressId));
            if (userDetails?.instituteCourse?.isOtherCourse) {
                dispatch(getLoanTenureData(''));
            } else {
                dispatch(getLoanTenureData(userDetails?.instituteCourse?.instituteCourseId));
            }
        }
        setRiskCode(userDetails?.instituteAddress?.riskCode)
        setFCPID(userDetails?.instituteAddress?.instituteFcpId)
        setInterestBourneByInstitute(userDetails?.instituteAddress?.interestBourneByInstitute)
    }, [userDetails])

    // Get Institute Detail and set in to data
    useEffect(() => {
        setSelectedInstituteType(userDetails?.instituteType?.instituteTypeId);
        if (userDetails?.instituteAddress?.isOtherAddress) {
            setSelectedInstituteAddress("other");
            setOtherInstituteAddress(userDetails?.instituteAddress?.instituteAddress)
            setOtherAddressid(userDetails?.instituteAddress?.instituteAddressId)
        } else {
            setSelectedInstituteAddress(userDetails?.instituteAddress?.instituteAddressId);
            setRiskCode(userDetails?.instituteAddress?.instituteRiskCode)
            setFCPID(userDetails?.instituteAddress?.instituteFcpId)
            setInterestBourneByInstitute(userDetails?.instituteAddress?.interestBourneByInstitute)

        }
        if (userDetails?.instituteCourse?.isOtherCourse) {
            setSelectedInstituteCourse("other")
            setOtherInstituteCourse(userDetails?.instituteCourse?.instituteCourses)
            setOtherCourseid(userDetails?.instituteCourse?.instituteCourseId)
        } else {
            setSelectedInstituteCourse(userDetails?.instituteCourse?.instituteCourseId);
        }
        if (userDetails?.instituteName?.isOtherName) {
            setSelectedInstituteName("other")
            setOtherInstituteNames(userDetails?.instituteName?.instituteName)
            setOtherInstituteNameid(userDetails?.instituteName?.instituteId)
        } else {
            setSelectedInstituteName(userDetails?.instituteName?.instituteId);
        }
        setCourseFees(userDetails?.courseFeesDetail);
        setLoanAmount(userDetails?.loanAmount);
        setLoanTenure(userDetails?.loanTenure);
        setSchemeMoratorium(userDetails?.schemeDetails?.schemeMoratorium);
        setAdvanceEMI(userDetails?.schemeDetails?.numberOfAdvanceEmis);
        setEMI(userDetails?.emiAmount);
        setIsAccepted(userDetails?.isAccepted)
        setProcessingFees(userDetails?.processingFees);
        setCourseDuration(userDetails?.instituteCourse?.courseDuration)
    }, [userDetails])


    // Filter Institute Type Data
    useEffect(() => {
        if (instituteData && instituteData.length) {
            const tempInstituteData = []
            instituteData.map(item => {
                tempInstituteData.push({
                    name: item?.instituteTypeName,
                    value: item?.instituteTypeId
                })
            })
            setInstituteType(tempInstituteData)
        }
    }, [instituteData])

    // Filter Loan Tenure Data
    useEffect(() => {
        const tempLoanData = []
        for (const props in loadTenureData) {
            tempLoanData.push({
                name: loadTenureData[props].schemeTenure,
                value: loadTenureData[props].schemeTenure,
            })
        }
        setLoanTenureList(tempLoanData)
    }, [loadTenureData])

    // Filter Institute Name Data
    useEffect(() => {
        if (instituteName && instituteName.length) {
            const tempInstituteName = []
            instituteName.map(item => {
                if (item.instituteName.toLowerCase() === "other") {
                    tempInstituteName.push({
                        name: "Other",
                        value: "other"
                    })
                } else {
                    tempInstituteName.push({
                        name: item?.instituteName,
                        value: item?.instituteId
                    })
                }
            })
            setInstituteNames(tempInstituteName)
        }
    }, [instituteName])

    // Filter Institute Address Data
    useEffect(() => {
        if (instituteAddressData && instituteAddressData.length) {
            const tempInstituteAddress = []
            instituteAddressData.map(item => {
                if (item.instituteAddress.toLowerCase() === "other") {
                    tempInstituteAddress.push({
                        name: "Other",
                        value: "other"
                    })
                } else {
                    tempInstituteAddress.push({
                        name: item?.instituteAddress,
                        value: item?.instituteAddressId,
                        riskCode: item?.riskCode,
                        fcpID: item?.instituteFcpId,
                        interestBourneByInstitute: item?.interestBourneByInstitute
                    })
                }
            })
            setInstituteAddress(tempInstituteAddress)
        }
    }, [instituteAddressData])

    // Filter Institute Course Data
    useEffect(() => {
        if (instituteCourseData && instituteCourseData.length) {
            const tempInstituteCourse = []
            instituteCourseData.map(item => {
                if (item.instituteCourses.toLowerCase() === "other") {
                    tempInstituteCourse.push({
                        name: "Other",
                        value: "other"
                    })
                } else {
                    tempInstituteCourse.push({
                        name: item?.instituteCourses,
                        value: item?.instituteCourseId,
                        riskCode: item?.instituteAddressMaster?.instituteRiskCode,
                        instituteFcpId: item?.instituteAddressMaster?.instituteFcpId

                    })
                }

            })

            setInstituteCourse(tempInstituteCourse)
            tempInstituteCourse.filter((obj) => {
                if (obj.riskCode) {
                    setRiskCode(obj?.riskCode)
                }

            })
            tempInstituteCourse.filter((obj) => {
                if (obj.instituteFcpId) {
                    setFCPID(obj?.instituteFcpId)
                }

            })
        }

    }, [instituteCourseData])


    // On Change Institute Type
    function changeInstituteType(value) {
        setSelectedInstituteType(value)
        dispatch(getInstituteName(value));
        setSelectedInstituteName('');
        setSelectedInstituteAddress('');
        setSelectedInstituteCourse('')
        setCourseFees('');
        setLoanAmount('');
        setLoanTenure('');
        setInterestBourneByInstitute(false)
        setAdvanceEMI(0);
        setEMI(0);
        setProcessingFees(0);
    }

    // on change Institute Address
    function changeInstituteName(value) {
        setSelectedInstituteName(value)
        if (value === 'other') {
            setSelectedInstituteAddress(value)
            setSelectedInstituteCourse(value)
        } else {
            setSelectedInstituteAddress('');
            setSelectedInstituteCourse('')
        }
        setInterestBourneByInstitute(false)
        setOtherInstituteAddress('');
        setOtherInstituteCourse('');
        setOtherInstituteNames('');
        setCourseFees('');
        setCourseDuration('')
        setLoanAmount('');
        setLoanTenure('');
        setLoanTenureList([])
        setAdvanceEMI(0);
        setEMI(0);
        setProcessingFees(0);
        if (value !== 'other')
            dispatch(getInstituteAddress(value));
        else {
            dispatch(getLoanTenureData(''));
        }
    }

    // on change Institute Address
    function changeInstituteAddress(value) {
        setRiskCode(instituteAddress?.riskCode)
        setFCPID(instituteAddress?.instituteFcpId)
        const addrObj = instituteAddress.filter((add) => { return add.value === value })
        //setInterestBourneByInstitute(addrObj[0]?.interestBourneByInstitute)
        setSelectedInstituteAddress(value)
        if (value === 'other') {
            setSelectedInstituteCourse(value)
        } else {
            setSelectedInstituteCourse('')
        }
        setOtherInstituteAddress('');
        setOtherInstituteCourse('');
        setCourseFees('');
        setCourseDuration('')
        setLoanAmount('');
        setLoanTenure('');
        setLoanTenureList([])
        setAdvanceEMI(0);
        setEMI(0);
        setProcessingFees(0);
        if (value !== 'other')
            dispatch(getInstituteCourse(value));
        else {
            dispatch(getLoanTenureData(''));
        }
    }

    // on change Institute Course
    function changeInstituteCourse(value) {
        setSelectedInstituteCourse(value)
        setCourseFees('');
        setLoanAmount('');
        setLoanTenure('');
        setAdvanceEMI(0);
        setCourseDuration('')
        setInterestBourneByInstitute(false)
        setEMI(0);
        setProcessingFees(0);
        setOtherInstituteCourse('');
        if (value !== 'other') {
            dispatch(getLoanTenureData(value));
            const course = instituteCourseData?.filter(data => data.instituteCourseId === value)
            setCourseFees(course[0]?.instituteCoursesFees)
        }
        else {
            setCourseFees('')
            dispatch(getLoanTenureData(''));
        }

    }

    function getSaveObj() {
        const obj = {
            userId: +userId,
            instituteType: selectedInstituteType,
            instituteName: selectedInstituteName === 'other' ? otherInstituteNameId : selectedInstituteName,
            instituteAddress: selectedInstituteAddress === 'other' ? otherAddressId : selectedInstituteAddress,
            instituteCourse: selectedInstituteCourse === 'other' ? otherCourseId : selectedInstituteCourse,
            courseFeesDetail: +courseFees,
            loanAmount: +loanAmount,
            loanTenure: loanTenure,
            numberOfAdvanceEmi: advanceEMI,
            emiAmount: emi,
            processingFees: processingFees,
            monthlyRateOfInterest: 0,
            apr: 0,
            monthlyInterestAmount: 0,
            isOtherName: selectedInstituteName === 'other' ? true : false,
            isOtherAddress: selectedInstituteAddress === 'other' ? true : false,
            isOtherCourse: selectedInstituteCourse === 'other' ? true : false,
            otherInstituteName: otherInstituteNames,
            otherInstituteAddress: otherInstituteAddress,
            otherInstituteCourse: OtherinstituteCourse,
            courseDuration: courseDuration,
            isAccepted: isAccepted,
            schemeId: loadTenureData.filter((obj) => obj.schemeTenure === loanTenure)[0].schemeId,
            fcpId: storedFcp || FCPID,
            moratorium: schemeMoratorium
        }
        return obj
    }
    // Save Institute Data
    function saveInstitueData(e) {
        dispatch(setAppLoader(true))
        const consentObj = {
            userId: userId,
            source: "web",
            consent: true
        }
        userConsent(consentObj).then(data => {
            console.log(data)
        });

        e.preventDefault();
        if (riskCode && riskCode === 5 && selectedInstituteAddress !== 'other') {
            setShowRiskModal(true)
            dispatch(setAppLoader(false))
        }
        if (!FCPID && !storedFcp) {
            setShowFCPModal(true)
            dispatch(setAppLoader(false))
            return
        }
        const obj = getSaveObj()
        if (userDetails?.detailId) {
            obj.detailId = userDetails?.detailId
            // saveFormPath("/instituteDetails", userDetails?.detailId)

        }
        if (riskCode && riskCode === 5 && selectedInstituteAddress !== 'other') {
            obj.userFormStatus = "Terminated"

        } else {
            obj.userFormStatus = "Draft"
        }
        saveInstituteData(obj).then(data => {
            if (data.status === 200 || data.status === 201) {
                setErrorMsg("Data Saved Successfully")
                if (selectedInstituteName === 'other' || selectedInstituteAddress === 'other' || selectedInstituteCourse === 'other') {
                    setIsOtherSelected(true)
                } else {
                    setIsOtherSelected(false)
                }
                if (!(riskCode && riskCode === 5 && selectedInstituteAddress !== 'other')) {
                    setTabValue(3)
                    setStepperCount(3)
                    localStorage.setItem("detailId", data.data.response.detailId)
                    saveFormPath("/instituteDetails", data.data.response.detailId);
                    navigate("/studentDetails", { state: { tabValue: 3, stepperCount: 3, userDetailId: data.data.response.detailId } })
                }
            } else {
                setError(true)
                setErrorMsg(data?.response?.data?.message)
            }
            dispatch(setAppLoader(false))
        }).catch(error => {
            console.log('error', error)
            dispatch(setAppLoader(false))
        })
    }

    // Save Data from FCP Modal and redirect to next page
    function onSaveFCDID() {
        dispatch(setAppLoader(true))
        const obj = getSaveObj()
        if (userDetails?.detailId) {
            obj.detailId = userDetails?.detailId
            saveFormPath("/instituteDetails", userDetails?.detailId)

        }
        if (riskCode && riskCode === 5 && selectedInstituteAddress !== 'other') {
            obj.userFormStatus = "Terminated"

        } else {
            obj.userFormStatus = "Draft"
        }
        saveInstituteData(obj).then(data => {
            if (data.status === 200 || data.status === 201) {
                setErrorMsg("Data Saved Successfully")
                if (selectedInstituteName === 'other' || selectedInstituteAddress === 'other' || selectedInstituteCourse === 'other') {
                    setIsOtherSelected(true)
                } else {
                    setIsOtherSelected(false)
                }
                if (!(riskCode && riskCode === 5 && selectedInstituteAddress !== 'other')) {
                    setTabValue(3)
                    setStepperCount(3)
                    localStorage.setItem("detailId", data.data.response.detailId)
                    saveFormPath("/instituteDetails", data.data.response.detailId)
                    navigate("/studentDetails", { state: { tabValue: 3, stepperCount: 3, userDetailId: data.data.response.detailId } })
                }
                dispatch(setAppLoader(false))
            } else {
                setError(true)
                setErrorMsg(data?.response?.data?.message)
                dispatch(setAppLoader(false))
            }
        }).catch(error => {
            console.log('error', error)
            dispatch(setAppLoader(false))
        })
    }

    function changeInputValue(value, event) {
        setCourseFees(value);
    }

    // call API on change of Loan Amount and tenure 
    function callEmiAndProcessingFee(obj) {
        getEmiAndProcessingFee(obj).then(data => {
            const response = {
                emi: data?.emi,
                processingFee: data?.processingFee,
                numberOfAdvanceEmi: data?.numberOfAdvanceEmi,
                interestBourneByInstitute: data?.interestBourneByInstitute
            }
            setEMI(response.emi);
            setProcessingFees(response.processingFee);
            setAdvanceEMI(response.numberOfAdvanceEmi);
            setInterestBourneByInstitute(response.interestBourneByInstitute)

        }).catch(error => {
            console.log('error', error)
        })
    }

    // on Change LoanAmount
    function onLoanAmountChange(value) {
        console.log(loadTenureData)
        console.log(loadTenureData.filter((obj) =>  obj.schemeTenure == loanTenure))
        if (+value <= courseFees) {
            setLoanAmountError(false)
            setLoanAmount(value);
            const obj = {
                tenure: loanTenure,
                advanceEmis: advanceEMI,
                loanAmount: +value,
                courseId: selectedInstituteCourse === 'other' ? 0 : selectedInstituteCourse,
                isOtherCourse: selectedInstituteCourse === 'other' ? true : false,
                moratorium: loadTenureData.filter((obj) => obj.schemeTenure === loanTenure)[0]?.schemeMoratorium
            }
            if (value && loanTenure)
                callEmiAndProcessingFee(obj)
        } else {
            setLoanAmountError(true)
        }
    }


    function loanTenureHandler(value) {
        setLoanTenure(value)
        setSchemeMoratorium(loadTenureData.filter((obj) => obj.schemeTenure == value)[0].schemeMoratorium)
        const obj = {
            tenure: value,
            advanceEmis: advanceEMI,
            loanAmount: +loanAmount,
            courseId: selectedInstituteCourse === 'other' ? 0 : selectedInstituteCourse,
            isOtherCourse: selectedInstituteCourse === 'other' ? true : false,
            moratorium: loadTenureData.filter((obj) => obj.schemeTenure == value)[0].schemeMoratorium
        }
        callEmiAndProcessingFee(obj)
    }

    function openModel() {
        setOpenTC(true)
    }

    // Confirm Risk Factor Modal
    function onConfirmRiskFactor(isChoseOtherAddress) {
        if (!isChoseOtherAddress) {
            localStorage.clear()
            navigate('/welcome')
        }

    }
    return (
        <form onSubmit={(e) => saveInstitueData(e)} className='applicationFormContainer' >
            {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
            }
            {
                errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
            }
            {
                matches ?
                    <Typography color='textColor' variant={matches ? "subtitle1" : 'subtitle3'} sx={{ fontWeight: { xs: "bold", sm: "none" }, textAlign: { xs: "center", md: "left" } }}>Institute Details</Typography>
                    : null
                    // <Typography color='textColor' variant={matches ? "subtitle1" : 'subtitle3'} sx={{ fontWeight: { xs: "bold", sm: "none" }, textAlign: { xs: "center", md: "left" } }}>Institute & Course</Typography>

            }
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                    <CSelectBox
                        onChange={changeInstituteType}
                        optionList={instituteType}
                        defaultValue={selectedInstituteType ?? ""}
                        name="instituteType"
                        label="Institute Type*" />
                </Grid>
                <Grid item xs={12} md={6}>
                    {selectedInstituteName !== 'other' ? <CSelectBox
                        onChange={changeInstituteName}
                        optionList={instituteNames}
                        defaultValue={selectedInstituteName ?? ""}
                        name="instituteName"
                        label="Institute Name(OTHER if not listed)*" /> :
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CInput
                                label="Institute Name*"
                                isOther={selectedInstituteName === 'other' ? true : false}
                                placeholder="Please Type Other Institute Name"
                                value={otherInstituteNames}
                                onChange={(e) => { setSelectedInstituteAddress('other'); setSelectedInstituteCourse('other'); setOtherInstituteNames(e.toUpperCase()) }}
                                name="instituteName" />
                            <Tooltip title="Click here to go back to Institute Name List" aria-label="add" placement="top">
                                <CloseIcon sx={{ mt: 4 }} onClick={() => { setSelectedInstituteName(''); setSelectedInstituteAddress(''); setSelectedInstituteCourse(''); setCourseFees(''); setLoanAmount(''); setLoanTenureList([]); }} />
                            </Tooltip>
                        </div>
                    }

                </Grid>
                <Grid item xs={12}>
                    {(selectedInstituteAddress !== 'other' && selectedInstituteName !== 'other') ? <CSelectBox
                        name="instituteAddress"
                        onChange={changeInstituteAddress}
                        optionList={instituteAddress}
                        defaultValue={selectedInstituteAddress ?? ""}
                        label="Institute Address(OTHER if not listed)*" /> :
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CInput
                                label="Institute Address"
                                isOther={selectedInstituteAddress === 'other' ? true : false}
                                placeholder="Please Type Other Institute Address"
                                value={otherInstituteAddress}
                                onChange={(e) => { setOtherInstituteAddress(e.toUpperCase()) }} />
                            {selectedInstituteName !== 'other' &&
                                <Tooltip title="Click here to go back to Institute Name List" aria-label="add" placement="top">
                                    <CloseIcon sx={{ mt: 4 }} onClick={() => { setSelectedInstituteAddress(''); setSelectedInstituteCourse(''); setLoanTenureList([]); setCourseFees(''); setLoanAmount(''); }} />
                                </Tooltip>}
                        </div>
                    }

                </Grid>
                <Grid item xs={12} md={6}>
                    {(selectedInstituteCourse !== 'other' && selectedInstituteName !== 'other' && selectedInstituteAddress !== 'other') ? <CSelectBox
                        name="instituteCourse"
                        onChange={changeInstituteCourse}
                        optionList={instituteCourse}
                        defaultValue={selectedInstituteCourse ?? ""}
                        label="Choose Course(OTHER if not listed)*" /> :
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CInput
                                label="Institute Course"
                                isOther={selectedInstituteCourse === 'other' ? true : false}
                                value={OtherinstituteCourse}
                                placeholder="Please Type Other Institute Course"
                                onChange={(e) => { setSelectedInstituteCourse('other'); setOtherInstituteCourse(e.toUpperCase()); }} />
                            {selectedInstituteAddress !== 'other' &&
                                <Tooltip title="Click here to go back to Institute Name List" aria-label="add" placement="top">
                                    <CloseIcon sx={{ mt: 4 }} onClick={() => { setSelectedInstituteCourse(''); setLoanTenureList([]); setCourseFees(''); setLoanAmount(''); }} />
                                </Tooltip>
                            }
                        </div>
                    }

                </Grid>
                {
                    selectedInstituteCourse === 'other' &&
                    <Grid item xs={12} md={6}>
                        <CInput type="number" maxLength={2} value={courseDuration} onChange={setCourseDuration} placeholder="Please type course duration in months" name="courseDuration" label="Course Duration In Months*" />
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <CInput type="number" disabled={selectedInstituteCourse !== 'other' ? true : false} value={courseFees} onChange={changeInputValue} name="courseFees" label="Course Fees*" />
                </Grid>
                {
                    !matches &&
                    <Grid item sx={{ my: 2 }} xs={12}>
                        <Divider style={{ background: '#616161' }}  />
                    </Grid>
                }
                {!matches && <Typography color='textColor' variant='subtitle3' sx={{ fontWeight: "bold", textAlign: "center", fontFamily: "Poppins" }}>Loan amount & tenure </Typography>}
                <Grid item xs={12} md={6}>
                    <CInput helperText={"Loan amount can’t be more than course fees"} isError={loanAmountError} value={loanAmount} onChange={onLoanAmountChange} name="instituteName" label="Enter Loan Amount" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CSelectBox onChange={loanTenureHandler} optionList={loanTenureList} defaultValue={loanTenure ?? ""} name="instituteName" label="Choose Loan Tenure*" />
                </Grid>
                {
                    !matches &&
                    <Grid item sx={{ my: 2 }} xs={12}>
                        <Divider style={{ background: '#616161' }}  />
                    </Grid>
                }
                <Grid item xs={4} md={6}>
                    <CInput value={advanceEMI} type="number" name="instituteName" label={matches ? "Number Of Advance EMI(s)" : "No of Adv EMI"} disabled={true} />
                    {matches && <Typography color='textColorR' variant='body2' sx={{ fontWeight: "bold", textAlign: "left" }}>To be paid before disbursement.</Typography>}
                </Grid>
                <Grid item xs={4} md={6}>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <CInput value={emi} name="instituteName" label={matches ? "EMI" : "EMI Amount"} disabled={true} />
                        {interestBourneByInstitute ?
                            <Typography color='textColorR' variant='body2' sx={{ fontWeight: "bold", textAlign: "left" }}>This Amount is not inclusive of interest. Interest to be paid by Institute </Typography> : null}
                    </div>
                </Grid>
                <Grid item xs={4} md={6}>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <CInput
                            name="processingFees" label={matches ? "Processing Fees (GST Applicable)" : "PF Amount"} disabled={true} value={processingFees} />
                        {matches && <Typography color='textColorR' variant='body2' sx={{ fontWeight: "bold", textAlign: "left" }}>To be paid before disbursement.</Typography>}
                    </div>
                </Grid>
                <Grid item xs={4} md={6}>
                    <div>
                        {schemeMoratorium > 0 && <CInput
                            name="moratorium" label={"Moratorium"} disabled={true} value={schemeMoratorium} />}
                    </div>
                </Grid>{
                    localStorage.getItem("eligibility") === 'true' ? null :
                    <Grid item sx={{ textAlign: "left" }} xs={12}>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <FormGroup >
                            <FormControlLabel
                                control={<Checkbox style={{ color: "#D32F2F" }} checked={isAccepted} onChange={() => setIsAccepted(!isAccepted)} name="gilad" />}
                            />
                        </FormGroup>
                        <div style={{ fontSize: "16px" }}>
                            <div style={{ marginTop: "7px" }}>I/We hereby agree and acknowledge that:</div>
                            <div>
                                <div>I/ We are above 18 years of age.</div>
                                <div>I/ We are the Citizen/s and Resident/s of India.</div>
                                <div>I/ We can understand, read and write in the English language.</div>
                                <div>I/ We have read and understood the terms and conditions</div>
                                <div>
                                    <a onClick={openModel} style={{ cursor: "pointer", color: "#D32F2F", borderBottom: "1px solid #D32F2F" }}>Terms And Conditions</a>
                                </div>
                                <div>{/* Additional content can go here if needed */}</div>
                                {/* <a onClick={openPrivacyModel} style={{ cursor: "pointer", color: "#D32F2F", borderBottom: "1px solid #D32F2F" }}>Privacy and Security Policy*. </a>
                <span>
                    I also authorise Flyhi Finance to check my credit score for loan eligiblity. I also understand that eligibility is provisional and subject to more exhaustive checks upon actual application. By submitting my details, I override my NDNC registration and authorize Fly Hi Financial Services Ltd and its representatives to contact me through call, RCS, WhatsApp or E-mail.
                </span> */}
                            </div>
                        </div>
                    </div>
                </Grid>}
            </Grid>
            <div style={{ padding: "20px 0px 0px", display: "flex", flexDirection: "row", justifyContent: `${!matches ? "space-around" : "space-between"}` }}>
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    disabled={disabledButton}
                    variant="contained">
                    Save & Submit
                </Button>
            </div>

            {openTC && <TermsAndCondition open={openTC} setOpen={setOpenTC} />}
            {showRiskModal && <CDialogue message="Sorry! We have temporarily stopped funding fees at the selected institute. Do you want to apply for fee funding at a course at some other institute?" open={showRiskModal} setOpen={setShowRiskModal} isConfirm={onConfirmRiskFactor} />}
            {showFCPModal && <FCDModal onSaveFCDID={onSaveFCDID} open={showFCPModal} setOpen={setShowFCPModal} FCPID={FCPID} setFCPID={setFCPID} />}
        </form >
    )
}