import { httpservice } from './httpService'


/**
 * Save Bank Data
 */
export function saveBankData(bankData) {
    return httpservice()
        .put(`/api/userData/bankStatement`, bankData)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Save Additonal Address Data
 */
 export function saveAdditionalData(additionalData) {
    return httpservice()
        .put(`/api/userData/additionalAddress`, additionalData)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Save Bank Details
 */
export function saveBankDetails(obj) {
    return httpservice()
        .post(`/api/userData/bankDetails`, obj)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Fetch From AA
 */
export function fetchAccountAgg(detailId) {
    return httpservice()
        .post(`/api/aa/redirect?id=${detailId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Fetch From AA
 */
export function poll(detailId) {
    return httpservice()
        .post(`/api/aa/pollStatus/${detailId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}
