import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CInput from "../../utils/CInput";
import CButton from "../../utils/CButton";
import { saveBlackListedPin } from '../../../services/AdminServices';
import CDialogue from '../../utils/CDialogue';

export default function AddNewBlackListedPin(props) {
    const { open, setOpen, reFetch, selectedRow, handleCloseForm } = props;
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [blackListedPin, setBlackListedPin] = React.useState('')
    const [confirmModel, setConfirmModel] = React.useState(false)

    const handleClose = () => {
        setOpen(false);
        handleCloseForm();
    };

    React.useEffect(() => {
        const pin = selectedRow?.pin;
        const id = selectedRow?.id;
        setBlackListedPin(pin)

    }, [selectedRow])

    // To Do: Data is hardcoded
    async function saveData() {
        const updatedData = await saveBlackListedPin(selectedRow?.id ? +selectedRow?.id : +null, blackListedPin);
        handleClose()
        reFetch(9)
    }

    function confirmAction() {
        setConfirmModel(true)
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Add New Black Listed PIN</DialogTitle>
                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            pt: 2,
                        }}
                    >
                        <CInput onChange={setBlackListedPin} value={blackListedPin} name="blackListedPin" label="Black Listed PIN*" />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CButton disabled={!blackListedPin} handleClick={confirmAction} variant='contained' name="Add" />
                </DialogActions>
            </Dialog>
            <CDialogue open={confirmModel} message={selectedRow?.id ? "Are you Sure you want to Edit Data" : "Are you Sure you want to Create new Entry"} setOpen={setConfirmModel} isConfirm={saveData} />
        </React.Fragment>
    );
}
