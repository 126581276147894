import React from "react";
import { Typography } from "@mui/material";
import CInput from "../utils/CInput";
import CButton from "../utils/CButton";
import { useNavigate } from "react-router-dom";
import LockImg from "../../assets/images/lock.svg"
import { signUP } from "../../services/authenticationService";
import CAlert from "../utils/CAlert";
import ErrorModal from "../common/ErrorModal";
export default function SignUp(props) {
    let navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = React.useState('');
    const [pin, setPin] = React.useState('');
    const [errorMsg, setErrorMsg] = React.useState('');
    const [error, setError] = React.useState(false)
    const [locationError, setLocationError] = React.useState(false)

    function onSubmitClick() {
        setError(false)
        const signUpData = {
            mobileNumber,
            pin
        }
        signUP(signUpData).then(data => {
            if (data.status === 200 || data.status === 201) {
                const mobileData = {
                    details: data?.data?.details,
                    mobile: mobileNumber
                }
                localStorage.setItem('mobileData', JSON.stringify(mobileData));
                localStorage.setItem('newUser', true);
                navigate('/otpLogin')
            } else if (data.response.status === 400 ) {
                
                setError(true)
                //setLocationError(true)
                setErrorMsg(data?.response?.data?.message)
                // Show Error
            }else if (data.response.status === 451 ) {
                
                //setError(true)
                setLocationError(true)
                //setErrorMsg(data?.response?.data?.message)
                // Show Error
            }else{
                
                setError(true)
                //setLocationError(true)
                setErrorMsg(data?.response?.data?.message)
                // Show Error
            }
        }).catch(error => {
            console.log('error', error)
        })
    }
    
    function handleMobileNumber(value) {
        setMobileNumber(value.toString().slice(0,10))
    }

    return (
        <div className='mainContainer' >
            {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
            }
            <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Sign Up</Typography>
            <div style={{ display: 'flex' }}>
                <img style={{ marginLeft: "5px" }} src={LockImg} alt="logo" ></img>
                <Typography color='textColorG' variant='body2' sx={{ textAlign: "left" }}>Your data is 100% safe and secure</Typography>

            </div>
            <div>
                <CInput maxLength={10} type="number" onChange={handleMobileNumber} name="otp" label="Mobile Number*" value={mobileNumber} />
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <Typography color='textColorR' variant='body2' sx={{ fontWeight: "bold", textAlign: "left" }}>Enter mobile number linked with Aadhaar</Typography>
                    <Typography color='textColorR' variant='body2' sx={{ fontWeight: "bold", textAlign: "left" }}>without 0 or country code.</Typography>
                </div>

            </div>

            <CInput type="number" maxLength={6} onChange={setPin} name="otp" label="Pin Code Of Current Residence*" />
            <div style={{ paddingTop: "20px", display: "flex", flexDirection: "row", gap: "25px" }}>
                <CButton handleClick={onSubmitClick} name="Send OTP" />
            </div>
            <ErrorModal open={locationError} setOpen={setLocationError} />
        </div >
    )
}