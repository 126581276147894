
import { getUserDataService, getUserDetails } from '../services/userService'

export const getUserData = (detailId) => dispatch => {

    dispatch({
        payload: [],
        type: 'GET_USER_DATA_PENDING'
    })
    getUserDetails(detailId).then((res) => {
        dispatch({
            payload: {
                data: res
            },
            type: 'GET_USER_DATA_SUCCESS'
        })
    }).catch((err) => {
        dispatch({
            payload: {
                data: {
                    data: []
                },
                error: err
            },
            type: 'GET_USER_DATA_ERROR'
        })
    })

}