import axios from 'axios'
import { httpservice, baseURL } from './httpService'



/**
 * Get All Dropdown Data
 */
export function getDropdownDataService() {
    return httpservice()
        .get(`/api/masterData/getDropDownList`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}


/**
 * save Student Personal Data
 */
export function saveStudentPersData(studentPersonalData) {
    return httpservice()
        .put(`/api/userData/personalData`, studentPersonalData)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * save student Data
 */
export function saveStudentData(studentData) {
    return httpservice()
        .put(`/api/userData/student`, studentData)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Upload File
 */
export function uploadFile(formData, isFaceVerified) {
    let jwt = localStorage.getItem('jwt')
    const auth = 'Bearer ' + jwt

    let URL = baseURL + `/api/storage/uploadFile/false`
    if (isFaceVerified) {
        URL = baseURL + `/api/storage/uploadFile/true`
    }
    return new Promise((resolve, reject) => {
        axios.post(
            URL,
            formData,
            {
                headers: {
                    "Authorization": auth,
                    "Content-type": "multipart/form-data",
                },
            }
        ).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            reject(error)
        })
    })
}

/**
 * Cartbi Upload File
 */
 export function cartbiUpload(formData) {
    let jwt = localStorage.getItem('jwt')
    const auth = 'Bearer ' + jwt

    let URL = baseURL + `/api/cartbi/upload`

    return new Promise((resolve, reject) => {
        axios.post(
            URL,
            formData,
            {
                headers: {
                    "Authorization": auth,
                    "Content-type": "multipart/form-data",
                },
            }
        ).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(error.response)
        })
    })
}

/**
 * cartbi save
 */
 export function cartbiSave(obj) {
    return httpservice()
        .post(`/api/cartbi/Save?detailId=${obj}`,)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Get File
 */
export function getFile() {
    return httpservice()
        .get(`/api/storage/getFile`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}


export function deleteFile(fileUrl) {
    return httpservice()
        .delete(`/api/storage/deleteFile/?userImageUrl=${fileUrl}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Delete File
 */
// export function deletFile(fileUrl) {
//     return httpservice()
//         .delete(`/api/storage/uploadFile/${fileUrl}`)
//         .then(function (response) {
//             return response
//         })
//         .catch(function (error) {
//             return error.data
//         })
// }

