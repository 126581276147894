
import { getInstituteNameService } from '../services/applicationFormService'

export const getInstituteName = (value) => dispatch => {

    dispatch({
        payload: [],
        type: 'GET_INSTITUTE_NAME_PENDING'
    })
    getInstituteNameService(value).then((res) => {
        dispatch({
            payload: {
                data: res
            },
            type: 'GET_INSTITUTE_NAME_SUCCESS'
        })
    }).catch((err) => {
        dispatch({
            payload: {
                data: {
                    data: []
                },
                error: err
            },
            type: 'GET_INSTITUTE_NAME_ERROR'
        })
    })

}