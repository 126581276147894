import { httpservice } from './httpService'


export function getAccessKey(detailId) {
    return httpservice()
        .post(`/api/easeuzz/initiatePayment?id=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function savePaymentData(obj) {
    return httpservice()
        .post(`/api/easeuzz/savePayment`, obj)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function getPaymentDetails(detailId) {
    return httpservice()
        .get(`/api/easeuzz/getPayment?id=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}