import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function EligibilityModal(props) {
    const { jwt, userId, setOpenEligibility, eligibility } = props
    const navigate = useNavigate();




    const handleClose = () => {
        setOpenEligibility(false)
        navigate('/welcome')
    };


    const onApplyClick = () => {

        navigate('/userDashboard', { state: { tabValue: 2, stepperCount: 2, userDetailId: null } })

    };


    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={setOpenEligibility}

            >
                {eligibility ? <div> <DialogContent sx={{ margin: "30px" }}>
                    <Typography color='textColor' variant='body1' sx={{ textAlign: "left" }}>You are eligible!</Typography>
                </DialogContent>
                    <div style={{ padding: "10px 0px 20px", display: "flex", justifyContent: "center" }}>
                        <Button
                            type="button"
                            sx={{ fontStyle: "Lato" }}
                            color="neutral"
                            variant="contained"
                            onClick={onApplyClick}
                        >
                            Apply Now
                        </Button>
                    </div> </div> :<div> <DialogContent sx={{ margin: "30px" }}>
                    <Typography color='textColor' variant='body1' sx={{ textAlign: "left" }}>You are not eligible right now</Typography>
                    <Typography color='textColor' variant='body1' sx={{ textAlign: "left" }}>Please come back later</Typography>
                </DialogContent>
                <div style={{ padding: "10px 0px 20px", display: "flex", justifyContent: "center" }}>
                    <Button
                        type="button"
                        sx={{ fontStyle: "Lato" }}
                        color="neutral"
                        variant="contained"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </div></div>}
            </BootstrapDialog>
        </div>
    );
}
