import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserData } from "../../actions/getUserData";
import { setAppLoader } from "../../actions/setAppLoader";
import success from "../../assets/images/Vector.svg";
import { equifaxSoftPull, loanEligibilityCheck, verifyPanExtensive } from "../../services/authenticationService";
import { generateEquifax } from "../../services/pdfService";
import { saveFormPath, updateOpportunity } from "../../services/userService";
import { savePAN, sendAadhaarOtp, verifyAadhar, verifyPan } from "../../services/userVerifications";
import ErrorModal from "../common/ErrorModal";
import CAlert from "../utils/CAlert";
import CButton from "../utils/CButton";
import CInput from "../utils/CInput";
import EligibilityModal from "./EligibilityModal";

export default function PANAadharVerification({ setTabValue, setStepperCount, userDetailId }) {


    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation()
    const [PAN, setPAN] = React.useState('')
    const [aadhaar, setAadhaar] = React.useState('')
    const [otp, setOtp] = React.useState('')
    const [name, setName] = React.useState('')
    const [isPANVerify, setIsPANVerify] = React.useState(false)
    const [isAadharVerify, setIsAadharVerify] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [requestId, setRequestId] = React.useState(null);
    const [verificationStatus, setVerificationStatus] = React.useState(null)
    const [errorMsg, setErrorMsg] = React.useState('');
    const params = new URLSearchParams(location.search);
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const userId = userData?.userId;
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const disabledButton = !isPANVerify || !isAadharVerify;
    const [eligibility, setEligibility] = React.useState();
    const [openEligibility, setOpenEligibility] = React.useState(false);
    const [technicalError, setTechnicalError] = React.useState(false);
    const [disableForThirtySeconds, setDisableForThirtySeconds] = React.useState(false);

    useEffect(() => {
        dispatch(getUserData(userDetailId || localStorage.getItem('detailId')))
    }, []);

    //Show Loader
    React.useEffect(() => {
        dispatch(setAppLoader(isFetching))
    }, [isFetching])

    React.useEffect(() => {
        if (verificationStatus === "failure") {
            setError(true);
            setErrorMsg("Aadhaar Verification Failed, Please Try again")
        }
    }, [verificationStatus])


    React.useEffect(() => {
        if (userDetails?.aadharDetails) {
            setAadhaar(userDetails.aadharDetails.aadharId)
            setIsAadharVerify(true)
        }
        if (userDetails?.userPanCardNumber) {
            setIsPANVerify(true)
            setPAN(userDetails?.userPanCardNumber)
        }
    }, [userDetails])

    async function sendAadharOtp() {
        dispatch(setAppLoader(true))
       // setRequestId("abcd")
        console.log(otp)
        setError(false)
        const otpResp = await sendAadhaarOtp(aadhaar);
        if (otpResp) {
            setRequestId(otpResp.data.requestId)
        } else {
            setError(true)
            setErrorMsg("Try again")
        }
        dispatch(setAppLoader(false))
        setDisableForThirtySeconds(true); // Disable the button
    
    // Set a timer to re-enable the button after 30 seconds
    setTimeout(() => {
        setDisableForThirtySeconds(false);
    }, 30000);
    dispatch(setAppLoader(false))
    }

    // Verify Pan
    async function onVerifyPan() {
        dispatch(setAppLoader(true))
        setError(false)
        const obj = {
            detailId: localStorage.getItem('detailId'),
            name: name,
            pan: PAN
        }
        const panVerify = await verifyPan(obj);
        if (panVerify?.response?.result?.number) {
            const obj1 = {
                name: panVerify?.response?.result?.name,
                panNumber: panVerify?.response?.result?.number,
                fatherName: panVerify?.response?.result?.fatherName
            }
            obj1.userInstituteDetailId = userDetails?.detailId
            const panObj = {
                pan: PAN,
                userId: userId
            }

            await verifyPanExtensive(panObj).then(async data => {
                console.log(data)
                if (data?.data) {
                    await equifaxSoftPull(data?.data?.panDetailsId).then(async resp => {
                        console.log(resp)
                        if (!resp?.status)
                            setTechnicalError(true)
                        else {
                            setIsPANVerify(true)
                            await savePAN(obj1);
                            const obj = {
                                loanAmount: 100000,
                                tenure: 6,
                                emi: 1000,
                                maintainBalance: true,
                                pan: PAN,
                                userId: userId,
                                selfEvaluation: false,
                                detailId: localStorage.getItem('detailId')
                            }
                            await loanEligibilityCheck(obj).then(data => {
                                console.log(data?.data?.Eligibility)
                                setEligibility(data?.data?.Eligibility)
                                if (!data?.data?.Eligibility) {
                                    setOpenEligibility(true)

                                    var fields = [];
                                    // Create and push the first object
                                    fields.push({
                                        "SchemaName": "mx_custom_28",
                                        "Value": "Terminated by system"
                                    });

                                    let opp = {
                                        "ProspectOpportunityId": null,
                                        "OpportunityNote": "OPPORTUNITY_FIELDS_SCHEMA capture api overwrite",
                                        "Fields": fields
                                    }

                                    // updateOpportunity(localStorage.getItem('detailId'), opp)

                                }
                            });
                        }
                    })
                }
            });



        } else {
            setError(true)
            setIsPANVerify(false)
            setErrorMsg("PAN Verification failed Please try again")
        }
        dispatch(setAppLoader(false))
    }

    // Verify Aadhaar
    async function onVerifyAadhar() {
        dispatch(setAppLoader(true))
        const okycData = generateAadhaarRequestBody()
        const aadharVerify = await verifyAadhar(userDetailId, okycData);
        if (aadharVerify && aadharVerify.statusCode !== 422) {
            console.log(aadharVerify)
            setIsAadharVerify(true)
            dispatch(setAppLoader(false))
            // TO do wrong otp
        } else {
            // setError(true)
            // setErrorMsg("Wrong or Expired OTP")
            dispatch(setAppLoader(false))
        }
        
    }

    function generateAadhaarRequestBody() {
        const aadharObj = {
            requestId: requestId,
            otp: otp
        }
        return aadharObj;
    }


    // On Save and submit
    async function onSubmitVerified(e) {
        e.preventDefault();
        saveFormPath("/aadhaarDetails", userDetails?.detailId)
        dispatch(getUserData(userDetailId))
        setTabValue(6)
        generateEquifax(userDetails?.user?.userName, userDetails?.detailId)
        navigate("/aadhaarDetails", { state: { tabValue: 6, stepperCount: 5, userDetailId: userDetailId } })


    }

    function handleBack() {
        if (userDetails?.instituteAddress?.instituteRiskCode === 3) {
            setTabValue(3);
            setStepperCount(3);
            navigate("/studentDetails", { state: { tabValue: 3, stepperCount: 3, userDetailId: userDetailId || localStorage.getItem('detailId') } })
        } else {
            setTabValue(4);
            setStepperCount(4);
            navigate("/personalDetails", { state: { tabValue: 4, stepperCount: 4, userDetailId: userDetailId || localStorage.getItem('detailId') } })
        }

    }

    function getPanOrAadhaarTemplate() {

        if (isPANVerify && isAadharVerify) {

           return renderAlreadyVerfied()
        }

        else if (isPANVerify) {
            return renderVerifyAadhaar()
            
        } else {
            return renderVerifyPan()
        }
    }

    function renderAlreadyVerfied() {
        return (
            <>
                <Typography color='textColor' variant='h5' sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}>Validation Complete</Typography>

                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={8} md={6}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item >
                                <CInput
                                    label="Aadhaar Number *"
                                    placeholder="Aadhaar Number"
                                    disabled={true}
                                    value={aadhaar}
                                    name="aadhaar"
                                />
                            </Grid>
                            <Grid item sx={{mt:5}} >
                                <img src={success}  alt="success" />
                            </Grid>
                           
                        </Grid>

                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <CInput
                                    label="PAN *"
                                    placeholder="PAN"
                                    disabled={true}
                                    value={PAN}
                                    name="otp"
                                />
                            </Grid>
                            <Grid item sx={{mt:5}}>
                                <img src={success}  alt="success" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </>
        )
    }

    function renderVerifyAadhaar() {
        return (
            <>
                <Typography color='textColor' variant='h5' sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}>Aadhaar Validation</Typography>

                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={8} md={6}>
                        <CInput
                            label="Aadhaar Number *"
                            placeholder="Aadhaar Number"
                            disabled={isAadharVerify}
                            value={aadhaar}
                            onChange={setAadhaar}
                            name="aadhaar"

                        />
                        <CButton
                            disabled={!aadhaar || aadhaar.length < 12 || otp || disableForThirtySeconds}
                            handleClick={sendAadharOtp}
                            variant="contained"
                            name="Generate OTP"
                            mt={6} // Adjust width as necessary
                        />
                        {requestId ? 
                        <Typography color='textColor' variant='body2' sx={{ textAlign: "left" }}>OTP sent to mobile number registered with Aadhaar.</Typography> : null }
                        <CInput
                            label="OTP *"
                            placeholder="OTP"
                            disabled={!requestId}
                            value={otp}
                            onChange={setOtp}
                            name="otp"

                        />
                        <CButton
                            disabled={!otp}
                            handleClick={onVerifyAadhar}
                            variant="contained"
                            name="Validate OTP"
                            mt={6} // Adjust width as necessary
                        />
                    </Grid>
                </Grid>
            </>
        )
    }

    function renderVerifyPan() {
        return (
            <>
                <Typography color='textColor' variant='h5' sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}>PAN Validation</Typography>

                <Typography color='textColor' variant='body2' sx={{ textAlign: "left" }}>Enter PAN number and Click the button below to initiate Pan verification.</Typography>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={8} md={6}>
                        <CInput
                            label="PAN Number *"
                            placeholder="Enter PAN number"
                            disabled={isPANVerify}
                            value={PAN}
                            onChange={setPAN}
                            name="pan"

                        />
                        <CButton
                            disabled={isPANVerify}
                            handleClick={onVerifyPan}
                            variant="contained"
                            name="Verify PAN Number"
                            mt={6} // Adjust width as necessary
                        />
                    </Grid>
                </Grid>
            </>
        )
    }


    return (
        <form onSubmit={(e) => onSubmitVerified(e)} className='applicationFormContainer'>
            {error && <CAlert open={error} message={errorMsg} severity="error" />}
            {errorMsg === "Aadhaar Verified" && <CAlert open={true} message={errorMsg} severity="success" />}
            {
                !matches &&
                <Box sx={{ display: "flex" }}>
                    <ArrowBackIosIcon onClick={() => handleBack()} />
                </Box>
            }

            {getPanOrAadhaarTemplate()}

            <div style={{ pt: 12, padding: "100px 0px 0px", display: "flex", flexDirection: "row", justifyContent: `${!matches ? "space-around" : "space-between"}` }}>
                {matches && <CButton handleClick={() => handleBack()} name="Back" />}
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    disabled={disabledButton}
                    variant="contained">
                    Save & Submit
                </Button>
            </div>

            {openEligibility && <EligibilityModal userId={userId} setOpenEligibility={setOpenEligibility} eligibility={false} />}
            {technicalError && <ErrorModal open={technicalError} setOpen={setTechnicalError} isTechnicalError={true} />}
        </form>
    )
}

