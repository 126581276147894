import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CInput from "../../utils/CInput";
import CSelectBox from "../../utils/CSelectBox";
import CButton from "../../utils/CButton";
import { Grid } from '@mui/material';
import { saveInstituteAddress, saveInstituteCourse } from '../../../services/AdminServices';
import CDialogue from '../../utils/CDialogue';
import { useDispatch, useSelector } from 'react-redux';
import { getInstituteName } from '../../../actions/getInstituteName';
import { getInstituteAddress } from '../../../actions/getInstituteAddress';
import CSwitch from '../../utils/CSwitch';


export default function AddNewInstituteCourse(props) {
    const { open, setOpen, selectedRow, handleCloseForm, instituteAddress, instituteName, instituteType, reFetch } = props;
    const dispatch = useDispatch()
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [courseName, setCourseName] = React.useState('')
    const [courseFees, setCourseFees] = React.useState('')
    const [courseDuration, setCourseDuration] = React.useState('')


    const [instituteTypeList, setinstitueTypeList] = React.useState([])
    const [selectedInstituteType, setSelectedInstitueType] = React.useState([])

    const [instituteNameList, setinstitueNameList] = React.useState([])
    const [selectedInstituteName, setSelectedInstitueName] = React.useState([])

    const [instituteAddressList, setInstitueAddressList] = React.useState([])
    const [selectedInstituteAddress, setSelectedInstituteAddress] = React.useState([])
    const [confirmModel, setConfirmModel] = React.useState(false)
    const [isDisabledCourse, setIsDisabledCourse] = React.useState(false)

    const instituteNameData = useSelector((state) => state?.getInstituteName?.data);
    const instituteAddressData = useSelector((state) => state?.getInstituteAddress?.data);

    React.useEffect(() => {
        setCourseName(selectedRow?.name)
        setCourseFees(selectedRow?.fees)
        setCourseDuration(selectedRow?.duration)
        setSelectedInstitueType(+selectedRow?.typeId)
        setIsDisabledCourse(selectedRow?.disabledCourse == "false" ? false : true)
        if(+selectedRow?.typeId){
            dispatch(getInstituteName(+selectedRow?.typeId))
        }
        setSelectedInstitueName(+selectedRow?.nameId)
        if(+selectedRow?.nameId){
            dispatch(getInstituteAddress(+selectedRow?.nameId))
        }
        setSelectedInstituteAddress(+selectedRow?.addressId)
    }, [selectedRow])


    React.useEffect(() => {
        const tempData = []
        instituteType?.map(item => {
            tempData.push({
                value: item?.instituteTypeId,
                name: item?.instituteTypeName
            })
        })
        setinstitueTypeList(tempData)
    }, [instituteType])


    // Filter Institute Name Data
    React.useEffect(() => {
        if (instituteNameData && instituteNameData.length) {
            const tempInstituteName = []
            instituteNameData.map(item => {
                if (item.instituteName.toLowerCase() === "other") {
                    return
                }
                tempInstituteName.push({
                    name: item?.instituteName,
                    value: item?.instituteId
                })
            })
            setinstitueNameList(tempInstituteName)
        }
    }, [instituteNameData])

    // Filter Institute Address Data
    React.useEffect(() => {
        if (instituteAddressData && instituteAddressData.length) {
            const tempInstituteAddress = []
            instituteAddressData.map(item => {
                if (item.instituteAddress.toLowerCase() === "other") {
                    return
                }
                tempInstituteAddress.push({
                    name: item?.instituteAddress,
                    value: item?.instituteAddressId
                })

            })
            setInstitueAddressList(tempInstituteAddress)
        }
    }, [instituteAddressData])


    function onChangeType(valueId) {
        setInstitueAddressList([])
        if (valueId) {
            dispatch(getInstituteName(valueId))
            setSelectedInstitueType(valueId)
        }
    }
    function onChangeName(valueId) {
        setInstitueAddressList([])
        if (valueId) {
            dispatch(getInstituteAddress(valueId))
            setSelectedInstitueName(valueId)
        }
    }


    const handleClose = () => {
        setinstitueNameList([])
        setInstitueAddressList([])
        setSelectedInstitueType('')
        setSelectedInstitueName('')
        setSelectedInstituteAddress('')
        setIsDisabledCourse(false)
        setOpen(false);
        handleCloseForm();
    };
    async function saveData() {
        const selectedName = instituteNameList?.find(item => item?.value === selectedInstituteName)
        const selectedType = instituteTypeList?.find(item => item?.value === selectedInstituteType)
        const selectedAddress = instituteAddressList?.find(item => item?.value === selectedInstituteAddress)

        const obj = {
            instituteTypeId: selectedInstituteType,
            instituteNameId: selectedInstituteName,
            instituteAddressId: selectedInstituteAddress,
            instituteCourseId: null,
            instituteSchemeId: null,
            instituteTypeName: selectedType?.name,
            instituteName: selectedName?.name,
            instituteAddressName: selectedAddress?.name,
            instituteCourseName: courseName,
            instituteCourseTenure: courseDuration,
            instituteCourseFee: courseFees,
            createdBy: "8946992225",
            instituteAddressCity: null,
            instituteAddressPin: null,
            instituteAddressState: null,
            instituteAddressEMail: null,
            instituteAddressBankAccount: null,
            instituteAddressPhoneNumber: null,
            instituteAddressIfsc: null,
            instituteAddressContactPerosnName: null,
            disabledCourse: isDisabledCourse
        }
        if (selectedRow && selectedRow?.id) {
            obj.instituteCourseId = selectedRow?.id
        }
        const updatedData = await saveInstituteCourse(obj);
        reFetch(3)
        handleClose()
    }

    function confirmAction() {
        setConfirmModel(true)
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Add New Institute Course</DialogTitle>
                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            pt: 2,
                        }}
                    >
                        <Grid container>
                        <Grid item xs={12}>
                                <CSelectBox onChange={onChangeType} defaultValue={selectedInstituteType} optionList={instituteTypeList} label="Institute Type*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CSelectBox onChange={onChangeName} defaultValue={selectedInstituteName} optionList={instituteNameList} label="Institute Name*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CSelectBox onChange={setSelectedInstituteAddress} defaultValue={selectedInstituteAddress} optionList={instituteAddressList} label="Institute Address*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setCourseName} value={courseName} label="Course Name*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput type="number" onChange={setCourseFees} value={courseFees} label="Course Fees*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput type="number" onChange={setCourseDuration} value={courseDuration} label="Course Duration*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CSwitch setIsChecked={setIsDisabledCourse} checked={isDisabledCourse} label="Disable Course" />
                            </Grid>
                        </Grid>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <CButton disabled={!courseName || !courseFees || !courseDuration || !selectedInstituteAddress || !selectedInstituteType || !selectedInstituteName} handleClick={confirmAction} variant='contained' name="Add" />
                </DialogActions>
            </Dialog>
            <CDialogue open={confirmModel} message={selectedRow?.id ? "Are you Sure you want to Edit Institute Course" : "Are you Sure you want to Create new Institute Course"} setOpen={setConfirmModel} isConfirm={saveData} />

        </React.Fragment>
    );
}
