import { getInstituteTypesService } from '../services/applicationFormService'

export const getInstituteTypes = () => dispatch => {
    dispatch({
        payload: [],
        type: 'GET_INSTITUTE_TYPE_PENDING'
    })
    getInstituteTypesService().then((res) => {
        dispatch({
            payload: {
                data: res
            },
            type: 'GET_INSTITUTE_TYPE_SUCCESS'
        })
    }).catch((err) => {
        dispatch({
            payload: {
                data: {
                    data: []
                },
                error: err
            },
            type: 'GET_SYSLOG_LIST_ERROR'
        })
    })

}