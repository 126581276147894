import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CInput from "../../utils/CInput";
import CButton from "../../utils/CButton";
import { Grid } from '@mui/material';
import { saveDropDownData } from '../../../services/AdminServices';
import { useEffect } from 'react';
import CDialogue from '../../utils/CDialogue';


export default function AddNewDropDownData(props) {
    const { open, selectedRow, reFetch, setOpen, handleCloseForm, dropDownData, selectedType } = props;
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [valueData, setValueData] = React.useState('')
    const [keyId, setKeyId] = React.useState('')
    const [valueId, setValueId] = React.useState('')
    const [title, setTitle] = React.useState('')
    const [confirmModel, setConfirmModel] = React.useState(false)

    const dropDownKey = ["RELATIONSHIP_WITH_APPLICANT", "MARITAL_STATUS", "EMPLOYMENT_TYPE", "RESIDENTIAL_STATUS"]

    React.useEffect(() => {
        if (dropDownData?.length) {
            dropDownData?.forEach((obj) => {
                if (selectedType == 5 && obj?.keyDesc === dropDownKey[0]) {
                    setKeyId(obj?.keyId)
                    setTitle("Relationship")
                } else if (selectedType == 6 && obj?.keyDesc === dropDownKey[1]) {
                    setTitle("Marital Status")
                    setKeyId(obj?.keyId)
                } else if (selectedType == 7 && obj?.keyDesc === dropDownKey[2]) {
                    setTitle("Employement Type")
                    setKeyId(obj?.keyId)
                } else if (selectedType == 8 && obj?.keyDesc === dropDownKey[3]) {
                    setTitle("Residential Status")
                    setKeyId(obj?.keyId)
                }
            })
        }
    }, [dropDownData, selectedType])

    const handleClose = () => {
        setValueData('')
        setValueId('')
        setKeyId('')
        handleCloseForm();
        setOpen(false);
    };

    useEffect(() => {
        if (Object.keys(selectedRow)?.length) {
            setValueId(+selectedRow?.valueid)
            setValueData(selectedRow?.valuedesc)
        }
    }, [selectedRow])

    async function saveData() {
        const obj = {
            keyId: keyId,
            valueId: null,
            valueDetail: valueData
        }
        if (valueId) {
            obj.valueId = valueId
        }
        const updatedData = await saveDropDownData(obj);
        reFetch(6)
        handleClose()
    }

    function confirmAction() {
        setConfirmModel(true)
    }
    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{title} </DialogTitle>
                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            pt: 2,
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <CInput onChange={setValueData} value={valueData} label="Value" />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CButton disabled={!valueData} handleClick={confirmAction} variant='contained' name="Add" />
                </DialogActions>
            </Dialog>
            <CDialogue open={confirmModel} message={selectedRow?.id ? "Are you Sure you want to Edit Data" : "Are you Sure you want to Create new Entry"} setOpen={setConfirmModel} isConfirm={saveData} />
        </React.Fragment>
    );
}
