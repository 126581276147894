export default (state, action) => {
    if (typeof state === 'undefined') {
        state = {
            step: 1
        }
    }
    switch (action.type) {
        case 'CHANGE_STATE':
            return { step: action.payload }
        default:
            return state
    }
};