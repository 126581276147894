import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function PrefillModal(props) {
    const { showPrefill, setShowPrefill, navigateUser  } = props
    const navigate = useNavigate();


    const handleClose = () => {
        setShowPrefill(false)
        navigateUser(false)
    };


    const prefillData = () => {
        setShowPrefill(false)
        navigateUser(true)

    };


    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={showPrefill}

            >
                <DialogContent sx={{ margin: "30px" }}>
                    <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Do you want to prefill application from your last loan?</Typography>

                </DialogContent>
                <div style={{ padding: "10px 0px 20px", display: "flex", justifyContent: "center", gap: "20px" }}>
                    <Button
                        type="button"
                        sx={{ fontStyle: "Lato" }}
                        color="neutral"
                        variant="contained"
                        onClick={prefillData}
                    >
                        Yes 
                    </Button>
                    <Button
                        type="button"
                        sx={{ fontStyle: "Lato" }}
                        color="neutral"
                        variant="contained"
                        onClick={handleClose}
                    >
                        No
                    </Button>
                </div>
            </BootstrapDialog>
        </div>
    );
}