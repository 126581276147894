import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../actions/getUserData";
import { setAppLoader } from "../../actions/setAppLoader";
import success from "../../assets/images/Vector.svg";
import failure from "../../assets/images/failure.svg";
import { getAccessKey, getPaymentDetails, savePaymentData } from "../../services/easeBuzzService";
import { registerPaymentWithOneFin } from "../../services/lmsService";
import { saveFormPath } from "../../services/userService";
import { synofinLoanSave } from "../../services/synofinService";
import CAlert from "../utils/CAlert";
import CButton from "../utils/CButton";

export default function PaymentDetails({ setTabValue, setStepperCount, userDetailId }) {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [error, setError] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('')
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const [paymentData, setPaymentData] = React.useState(null);
    const [message, setMessage] = React.useState('');
    const [paymentError, setPaymentError] = React.useState(null);
    const [isAccepted, setIsAccepted] = React.useState(false);
    const [total, setTotal] = React.useState(0);

    const userId = userData?.userId;

    useEffect(() => {
        dispatch(getUserData(userDetailId))
    }, []);

    useEffect(() => {
        (async () => {
            dispatch(setAppLoader(true))
            const resp = await getPaymentDetails(userDetailId);
            setPaymentData(resp?.data)
            calculatePaymentData();
            dispatch(setAppLoader(false))
        })();
    }, []);

    React.useEffect(() => {
        dispatch(setAppLoader(false))
    }, [isFetching])

    async function onSubmitFaceVerication(e) {

        e.preventDefault();
        dispatch(setAppLoader(true))
        await synofinLoanSave(userDetails?.detailId);
        await saveFormPath("/loanCompleted", userDetails?.detailId)
        dispatch(setAppLoader(false))
        navigate("/loanCompleted", { state: { tabValue: 9, stepperCount: 9, userDetailId: userDetailId } })
    }

    async function makePayment(e) {

        const accessKey = await getAccessKey(userDetailId)
        var easebuzzCheckout = new window.EasebuzzCheckout('2786S2PL63', 'prod')
        var options = {
            access_key: accessKey?.data?.data, // access key received via Initiate Payment
            onResponse: async (response) => {
                console.log(response);
                const obj = {
                    userDetailId: userDetailId,
                    paymentAddedon: response.addedon,
                    paymentAmount: response.amount,
                    paymentBankName: response.bank_name,
                    paymentBankRefNum: response.bank_ref_num,
                    paymentBankcode: response.bankcode,
                    paymentCardCategory: response.cardCategory,
                    paymentCardType: response.card_type,
                    paymentCardnum: response.cardnum,
                    paymentCashBackPercentage: response.cash_back_percentage,
                    paymentDeductionPercentage: response.deduction_percentage,
                    paymentEasepayid: response.easepayid,
                    paymentEmail: response.email,
                    paymentError: response.error,
                    paymentErrorMessage: response.error_Message,
                    paymentFirstname: response.firstname,
                    paymentFlag: response.flag,
                    paymentIssuingBank: response.issuing_bank,
                    paymentKey: response.key,
                    paymentMode: response.mode,
                    paymentNameOnCard: response.name_on_card,
                    paymentNetAmountDebit: response.net_amount_debit,
                    paymentPhone: response.phone,
                    paymentStatus: response.status,
                    paymentTxnid: response.txnid,
                    paymentUpiVa: response.upi_va
                }
                dispatch(setAppLoader(true))
                const resp = await savePaymentData(obj);
                setPaymentData(resp?.data)
                if (response.status === "success") {
                    // await registerPaymentWithOneFin(userDetailId);
                } else {
                    alert("Payment Failed " + response.error)
                }
                dispatch(setAppLoader(false))

            },
            theme: "#123456" // color hex
        }
        easebuzzCheckout.initiatePayment(options);
    }

    function isDisabled() {
        if (!isAccepted)
            return true
        if (paymentData && paymentData.paymentStatus === 'success')
            return true
        return false
    }

    function calculatePaymentData(returnType) {

        // Providing default values if properties are undefined or null
        const totalAdvEmiAmount = (userDetails?.emiAmount * userDetails?.schemeDetails?.numberOfAdvanceEmis) || 0
        
        // Calculating total processing fees including tax
        const totalPF = (userDetails?.processingFees + userDetails?.processingFees * 0.18) || 0
        
        // Deciding which total to return based on returnType
        let result;
        switch (returnType) {
            case 'totalAdvEmiAmount':
                result = totalAdvEmiAmount;
                break;
            case 'totalPF':
                result = totalPF;
                break;
            case 'totalAmount':
                result = Math.round(totalAdvEmiAmount + totalPF).toFixed(2);;
                break;
            default:
                // Optionally, handle invalid returnType by returning a default value or an error
                console.error('Invalid returnType specified.');
                return null;
        }
        
        return Math.round(result).toFixed(2);

        }


        return (
            <form onSubmit={(e) => onSubmitFaceVerication(e)} c className='applicationFormContainer' >
                {
                    error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
                }
                {
                    errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
                }
                <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left", mb: 4 }}>Advance EMI & Processing fee Payment </Typography>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid sx={{ textAlign: "left" }} item xs={5}>
                        <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Processing Fees</Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "left" }} item xs={5}>
                        <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>INR {calculatePaymentData('totalPF')} (Inclusive GST)</Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "left" }} item xs={5}>
                        <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Advance EMI</Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "left" }} item xs={5}>
                        <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>INR {calculatePaymentData('totalAdvEmiAmount')}</Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "left" }} item xs={5}>
                        <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Total</Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "left" }} item xs={5}>
                        <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>INR {calculatePaymentData('totalAmount')}</Typography>
                    </Grid>
                </Grid>

                <Grid sx={{ py: 4 }}>
                    <Divider />
                </Grid>

                <Grid item sx={{ textAlign: "left", display: "flex", mt: 2 }} xs={12}>
                    <FormGroup sx={{ marginRight: "-20px" }}>
                        <FormControlLabel
                            control={<Checkbox style={{ color: "#D32F2F" }} name="gilad" checked={isAccepted} onChange={() => setIsAccepted(!isAccepted)} />}
                        />
                    </FormGroup>
                    <div style={{ fontSize: "16px", marginTop: "7px" }}>
                        <span >
                            I understand that i will be redirected to a third party payment gateway to complete the payment.
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ float: "left" }} >
                        <CButton disabled={isDisabled()} handleClick={(e) => makePayment(e)} variant="outlined" name="Make Payment" />
                    </div>
                    <Grid sx={{ display: "flex", alignItems: "center", pl: 4 }} item xs={12}>
                        {paymentData && paymentData.paymentStatus === 'success' ?
                            <React.Fragment>
                                <img src={success} />
                                <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Payment Successful</Typography>
                            </React.Fragment> :
                            paymentData && paymentData.paymentStatus !== 'success' ?
                                <React.Fragment>
                                    <img src={failure} />
                                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Payment Failed. Please Retry</Typography>
                                </React.Fragment> : null
                        }
                    </Grid>
                </Grid>
                <div style={{ padding: "30px 0px 0px", display: "flex", justifyContent: "center" }}>
                    <Button
                        type="submit"
                        sx={{ fontStyle: "Lato" }}
                        color="neutral"
                        variant="contained"
                        disabled={paymentData?.paymentStatus !== 'success' || !isAccepted}>
                        Save & Submit
                    </Button>
                </div>
            </form >
        )
    }