import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider, useMediaQuery } from '@mui/material';
import CButton from './CButton';
import success from "../../assets/images/Vector.svg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default function CBankData({ bankDetails, handleBack, onSubmit }) {
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    return (
        <>
       
            <Card sx={{ textAlign: "left", display: "flex", width: { sm: "384px", xs: "300px" }, height: "245px" }}>
                <CardContent sx={{ width: "inherit" }}>
                    <Typography sx={{ display: "flex", alignItems: "center", pb: 2 }} color='textColor' variant='body2'>
                        <img src={success} /> Statement Uploaded Successfully
                    </Typography>
                    <Divider />
                    <Typography sx={{ pt: 2 }} color='textColor' variant='body2'>
                        {bankDetails?.accountName}
                    </Typography>
                    <Typography color='textColor' variant='body2'>
                        {bankDetails?.bankName} - {bankDetails?.accountType}
                    </Typography>
                    <Typography color='textColor' variant='body2'>
                        XXXX{bankDetails?.accountNumber.substr(-5)}
                    </Typography>
                </CardContent>

            </Card>
            <div style={{ padding: "20px 0px 0px", display: "flex", flexDirection: "row", justifyContent: `${!matches ? "space-around" : "space-between"}` }}>
                {matches && <CButton handleClick={() => handleBack()} name="Back" />}
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    variant="contained"
                    onClick={onSubmit}>
                    Save & Submit
                </Button>
            </div>

        </>

    );
}
