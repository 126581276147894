
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import CButton from "../utils/CButton";
import CAlert from "../utils/CAlert";
import { getUserData } from "../../actions/getUserData";
import { setAppLoader } from "../../actions/setAppLoader";
import success from "../../assets/images/Vector.svg";
import failure from "../../assets/images/failure.svg"
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getEsignData, saveEsignTransactionData, uploadEsignDoc } from "../../services/loanApprovalService";
import { saveFormPath } from "../../services/userService";

export default function LoanAggrementSignature({ setTabValue, setStepperCount, userDetailId }) {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = React.useState(false)
    const [docId, setDocId] = React.useState('')
    const [errorMsg, setErrorMsg] = React.useState('');
    const [txnId, setTxnId] = React.useState();
    const [eSignData, setEsignData] = React.useState(null);
    const [message, setMessage] = React.useState('');
    const [esignError, setEsignError] = React.useState(null);
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const userId = userData?.userId;
    const disabledButton = false;

    useEffect(() => {
        dispatch(getUserData(userDetailId))
    }, []);

    useEffect(() => {
        (async () => {
            const resp = await getEsignData(userDetailId);
            setEsignData(resp?.data)
        })();
    }, []);

    //Show Loader
    React.useEffect(() => {
        dispatch(setAppLoader(isFetching))
    }, [isFetching])

    // On Save and submit
    async function onSubmitVerified(e) {
        e.preventDefault();
        dispatch(setAppLoader(true))
        await saveFormPath("/loanRepayment", userDetails?.detailId)
        navigate("/loanRepayment", { state: { tabValue: 7, stepperCount: 6, userDetailId: userDetailId } })
        dispatch(setAppLoader(false))
    }

    async function eSign(e) {
        e.preventDefault();
        dispatch(setAppLoader(true))
        const res = await uploadEsignDoc(userDetails?.detailId)
        if (res?.status === 200) {
            setDocId(res?.data?.body?.id);
        }
        dispatch(setAppLoader(false))
        var options = {
            //environment: "sandbox",
            environment: "production",
            callback: async function (response) {
                var resp = null;
                if (!response.hasOwnProperty("error_code")) {
                    console.log("Signing completed successfully", response);
                    setTxnId(response?.txn_id);
                    setMessage(response?.message)
                    setEsignError(null);
                    dispatch(setAppLoader(false))

                    resp = await saveEsignTransactionData(userDetailId, response?.txn_id, response?.message, null)
                } else {
                    console.log("Signing Failed", response);
                    setTxnId(null);
                    setMessage(response?.message)
                    setEsignError(response.error_code);
                    dispatch(setAppLoader(false))
                    resp = await saveEsignTransactionData(userDetailId, null, response?.message, response.error_code)
                }

                setEsignData(resp?.data)
            },
            is_iframe: true,

        }
        var digio = new window.Digio(options)
        digio.init()
        digio.submit(res?.data?.body?.id, userDetails?.user?.userName)

    }

    return (
        <form onSubmit={(e) => onSubmitVerified(e)} className='applicationFormContainer' >
            {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
            }
            {
                errorMsg === "Aadhaar Verified" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
            }

            <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Loan Agreement Signature</Typography>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Typography color='textColor' variant='body2' sx={{ mt: 2, textAlign: "left" }}>Click the below button to initiate E-Signature process.</Typography>
                </Grid>
                <Grid item sx={{ my: 4 }} xs={12}>
                    <Divider />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div style={{ float: "left" }} >
                    <CButton disabled={eSignData && eSignData.transactionId} handleClick={(e) => eSign(e)} variant="outlined" name="Applicant E-Sign" />
                </div>
                <Grid sx={{ display: "flex", alignItems: "center", pl: 4 }} item xs={12}>
                    {eSignData && eSignData.transactionId ?
                        <React.Fragment>
                            <img src={success} />
                            <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Applicant E-Signature Recorded</Typography>

                        </React.Fragment>
                        : esignError ?
                            <React.Fragment>
                                <img src={failure} />
                                <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}> E-Signature Failed. Please Retry.</Typography>
                            </React.Fragment> : null}
                    {/* {eSignData ?
                        txnId !== 'Not Started' ?
                            <React.Fragment>
                                <img src={success} />
                                <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Applicant E-Signature Recorded</Typography>

                            </React.Fragment>
                            : <div></div>
                        :
                        <React.Fragment>
                            <img src={failure} />
                            <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}> E-Signature Failed. Please Retry.</Typography>
                        </React.Fragment>} */}
                </Grid>
            </Grid>
            <Grid item sx={{ mt: 10, mb: 20 }} xs={12}>
                <Divider />
            </Grid>
            {/* <Grid item sx={{ textAlign: "left", display: "flex", mt: 3 }} xs={12}>
                <a style={{ fontSize: "18px", cursor: "pointer", color: "#D32F2F", borderBottom: "1px solid #D32F2F" }} >View and Download Signed Loan Agreement. </a>
            </Grid> */}
            <div style={{ padding: "50px 0px 0px", display: "flex", justifyContent: "center" }}>
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    variant="contained"
                    disabled={!eSignData?.transactionId}>
                    Save & Submit
                </Button>
            </div>
        </form >
    )
}

