import * as React from 'react';
import Button from '@mui/material/Button';
export default function CButton(props) {
    const { name, handleClick, type, disabled, variant, minWidth, isLato, fontWeight, fontSize,width, mt } = props;
    return (
        <div >
            <Button
                fullWidth={minWidth ? false : true}
                type={type}
                
                onClick={(e) => handleClick(e)}
                sx={{width:width, fontStyle: isLato ? "Lato" : "", fontWeight: fontWeight, fontSize: fontSize, mt:mt }}
                color="neutral"
                disabled={disabled}
                variant={variant ? variant : "contained"}>
                {name}
            </Button>
        </div>
    );
}
