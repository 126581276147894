import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import '../assets/jsx/WelcomeStyle.css'
import "../assets/jsx/ApplicationForm.css";
import { Grid, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import FlyLogo from "../assets/images/flyhi_logo_full.svg"
import AdminTable from '../components/admin/AdminTable';
import MasterData from '../components/admin/MasterData';
import CMenu from '../components/utils/CMenu';
import CButton from '../components/utils/CButton';
import { getListingData } from '../services/AdminServices';
import { useEffect } from 'react';
import CSelectBox from '../components/utils/CSelectBox';
import Analytics from './Analytics';
import Consent from './Consent';

const ApplicationForm = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false)
    const [analyticsOpen, setAnalyticsOpen] = React.useState(false)
    const [consentOpen, setConsentOpen] = React.useState(false)
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(3);
    const [filterValue, setFilterValue] = React.useState('all')
    const [adminData, setAdminData] = React.useState([])
    const userData = localStorage.getItem('mobileData') ? JSON.parse(localStorage.getItem('mobileData')) : null
    const userName = userData?.userName;

    const filterData = [{ name: "Show All", value: "all" }, { name: "Submitted", value: "submit" }, { name: "Approved", value: "approved" }, { name: "Rejected", value: "rejected" }, { name: "Terminated", value: "terminated" }, { name: "Deviated", value: "deviated" }]
    function onClickMasterUpdate() {
        setOpen(true)
    }

    function onCLickAnalytics() {
        setAnalyticsOpen(true)
    }
    function onCLickConsent() {
        setConsentOpen(true)
    }

    async function getAdminData(offset, limit, sortBy, filterName) {
        const obj = {
            offset,
            limit,
            sortBy,
            filterName,
            userName
        }
        const data = await getListingData(obj);
        setAdminData(data)
    }

    return (
        <div style={{ height: "auto" }}>
            <AppBar sx={{ backgroundColor: theme.palette.headerColor }} position="static">
                <Container maxWidth="xl">
                    <Toolbar sx={{ display: "grid", gridAutoFlow: 'column', justifyContent: 'space-between' }} disableGutters>
                        <img style={{ marginTop: "10px", marginBottom: "10px",width: "50px", height: "50px" }} src={FlyLogo} alt="logo" />

                        <div sx={{ gridColumn: '4 / 5', textAlign: "left", fontFamily: "lato", cursor: "pointer" }} variant="body1">
                            <CMenu />
                        </div>

                    </Toolbar>
                </Container>
            </AppBar>
            <Grid sx={{ py: 3, pr: 4 }} container justifyContent="flex-end" spacing={2}>
                <div style={{ display: "flex", gap: "20px" }}>
                    {/* <div style={{ width: "400px", padding: "4px" }}>
                        <CButton handleClick={onCLickConsent} name="Consent Data" />
                    </div> */}
                    <div style={{ width: "230px", padding: "4px" }}>
                        <CButton handleClick={onCLickAnalytics} name="Analytics" />
                    </div>
                    <div style={{ width: "400px", padding: "4px" }}>
                        <CButton handleClick={onClickMasterUpdate} name="Master Update" />
                    </div>
                    <CSelectBox optionList={filterData} onChange={setFilterValue} defaultValue={filterValue} />
                </div>
            </Grid>

            <Grid sx={{ px: 4 }} item xs={12} sm={6} md={6} >
                <Typography color='textColor' variant='body2' sx={{ fontWeight: "bold", textAlign: "left" }}>Active Loans</Typography>
                <AdminTable getAdminData={getAdminData} adminData={adminData} filterValue={filterValue} />
            </Grid>
            {open &&
                <MasterData open={open} setOpen={setOpen} />}
            {analyticsOpen &&
                <Analytics open={analyticsOpen} setOpen={setAnalyticsOpen} />}
            {consentOpen &&
                <Consent open={consentOpen} setOpen={setConsentOpen} />}
        </div >
    );
};

export default ApplicationForm;
