
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserDataPDF(props) {

  const { pdf, open, setOpen, setPdf } = props

  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
    setPdf(null)
  };

  return (
    <div>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ backgroundColor: theme.palette.headerColor, position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon sx={{ color: "red" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        {pdf ? <iframe height="100%" width="100%" src={pdf}></iframe> : "PDF is unavailable"}
      </Dialog>
    </div>
  );
}
