import * as React from 'react';
import Paper from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import { format } from 'date-fns'
import { Grid, TableHead, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getUserEvent } from '../../../services/userVerifications';


export default function UserEvent(props) {
    const { open, setOpen, selectedRow, scheme } = props;
    const [userEvent, setUserEvent] = React.useState(null)


    React.useEffect(() => {
        (async () => {
            const resp = await getUserEvent(selectedRow?.detailId)
            setUserEvent(resp)
        })();
    }, [])

    function getEventBody() {
        const rows = [];
        for (let key in userEvent?.data?.eventDetails) {
            const date = format(new Date(userEvent?.data?.eventDetails[key].time), "dd-MM-yyyy hh:mm:ss aaaaa'm'")
            rows.push(
                <TableRow>
                    <TableCell sx={{ borderLeft: "0.1px inset gray", borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {key}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {userEvent?.data?.eventDetails[key].module}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {userEvent?.data?.eventDetails[key].action}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {userEvent?.data?.eventDetails[key].status}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {userEvent?.data?.eventDetails[key].error}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {date}
                    </TableCell>

                </TableRow>

            );
        }
        return rows;
    }


    return (
        <React.Fragment>
            <DialogContent>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>User Event</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <Grid>
                                
                                    <TableContainer sx={{ maxHeight: 440 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ borderRight: "0.1px inset gray", borderLeft: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }}>Event#</TableCell>
                                                    <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Module</TableCell>
                                                    <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Action</TableCell>
                                                    <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Status</TableCell>
                                                    <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Error</TableCell>
                                                    <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >TimeStamp (DD-MM-YYYY HH:MM:SS)</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {getEventBody()}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                               
                            </Grid>
                        </Paper>
                    </AccordionDetails>
                </Accordion>

            </DialogContent>

        </React.Fragment>
    );
}
