
import { getLoanTenureService } from '../services/applicationFormService'

export const getLoanTenureData = (value) => dispatch => {

    dispatch({
        payload: [],
        type: 'GET_LOAN_TENURE_PENDING'
    })
    getLoanTenureService(value).then((res) => {
        dispatch({
            payload: {
                data: res
            },
            type: 'GET_LOAN_TENURE_SUCCESS'
        })
    }).catch((err) => {
        dispatch({
            payload: {
                data: {
                    data: []
                },
                error: err
            },
            type: 'GET_LOAN_TENURE_ERROR'
        })
    })

}