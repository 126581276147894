import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import CInput from "../utils/CInput";
import CButton from "../utils/CButton";
import { sendOTP, verifyOTP, signUPOTP } from "../../services/authenticationService";
import CAlert from "../utils/CAlert";
import { getStep } from "../../actions/getStep";
import { getUserDataService, saveFormPath } from "../../services/userService";
export default function OTPLogin(props) {

    const { setTabValue, setStepperCount } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [otp, setOTP] = React.useState();
    const [seconds, setSeconds] = React.useState(60);
    const [error, setError] = React.useState(false)
    const mobileData = localStorage.getItem('mobileData') ? JSON.parse(localStorage.getItem('mobileData')) : null
    const phoneNumber = mobileData?.mobile;
    const hiddenMobileNumber = phoneNumber ? phoneNumber.substring(0, 2) + "XXXXXX" + phoneNumber.substring(8, 10) : null
    let step = (new URLSearchParams(window.location.search)).get("step");
    const isNewUser = localStorage.getItem('newUser')


    //Timer Start
    React.useEffect(() => {
        if (seconds !== 0) {
            let myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
            }, 1000)
            return () => {
                clearInterval(myInterval);
            };
        }
    });


    // Verify OTP
    function onSubmitClick(e) {
        e.preventDefault();
        const otpData = {
            otp: otp,
            phoneNo: mobileData.mobile,
            details: mobileData.details,
        }
        if (isNewUser) {
            signUPOTP(otpData).then(data => {
                if (data.status === 200 || data.status === 201) {
                    localStorage.setItem("jwt", data?.data?.jwt)
                    localStorage.setItem("userData", JSON.stringify(data?.data))
                    dispatch(getStep(2))
                    setTabValue(2)
                    setStepperCount(2)
                    navigate("/userDashboard")
                } else {
                    setError(true)
                }
            }).catch(error => {
                setError(true)
                console.log('error', error)
            })
        } else {
            verifyOTP(otpData).then(async (data) => {
                if (data.status === 200 || data.status === 201) {
                    localStorage.setItem("jwt", data?.data?.jwt)
                    localStorage.setItem("userData", JSON.stringify(data?.data))
                    dispatch(getStep(2))
                    setTabValue(2)
                    setStepperCount(2)
                 //  const userData = await getUserDataService(data?.data?.userId)
                    //saveFormPath("/instituteDetails", data?.data?.detailId)
                    navigate(`/userDashboard`, {state: {tabValue: 2, stepperCount: 2}})
                } else {
                    setError(true)
                }
            }).catch(error => {
                setError(true)
                console.log('error', error)
            })
        }
    }

    // Resend OTP
    function resendOTP() {
        setSeconds(60)
        const mobile = mobileData.mobile
        sendOTP(mobile).then(data => {
            const mobileData = {
                details: data?.data?.details,
                mobile: phoneNumber
            }
            localStorage.setItem('mobileData', JSON.stringify(mobileData));
        }).catch(error => {
            console.log('error', error)
        })
    }

    function handleInput(value) {
        
            setError(false)
            setOTP(value)
    }

    return (
        <form onSubmit={(e)=>onSubmitClick(e)} className='mainContainer' >
            {error ? <CAlert open={error} message="Wrong OTP" severity="error" /> : null}
            <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Enter OTP</Typography>
            <Typography color='textColorB' variant='inputLabel' sx={{ textAlign: "left" }}>{`OTP has been shared on your mobile number ${hiddenMobileNumber}`}</Typography>
            <div>
                {/* Done focus the field bring cursor automatically to input box */}
                <CInput type="number" placeholder="xxx-xxx" autoFocus={true} onChange={handleInput} name="otp" label="OTP" />
                <div style={{ display: "flex" }}>
                    <Typography color='textColorG' variant='body2' sx={{ textAlign: "left" }}>Available Time: </Typography>
                    <Typography color='textColorY' variant='body2' sx={{ textAlign: "left" }}>{` ${seconds} seconds`}</Typography>
                </div>
            </div>
            <div style={{ paddingTop: "20px", display: "flex", flexDirection: "row", gap: "25px" }}>
                <CButton type="submit" disabled={!otp || seconds === 0}  name="Submit" />
                <CButton disabled={seconds !== 0} handleClick={resendOTP} name="Resend OTP" />
            </div>
        </form >
    )
}