

import { combineReducers } from 'redux'
import getInstituteType from './getInstituteType'
import getInstituteName from './getInstituteName'
import getInstituteAddress from './getInstituteAddress'
import getInstituteCourse from './getInstituteCourse'
import getLoanTenure from './getLoanTenure'
import getStep from './getStep'
import getDropDownData from './getDropDownData'
import getUserData from './getUserData'
import setAppLoader from './setAppLoader'
import setAadharData from './setAadharData'

const appReducer = combineReducers({
  getInstituteType,
  getInstituteName,
  getInstituteAddress,
  getInstituteCourse,
  getLoanTenure,
  getStep,
  getDropDownData,
  getUserData,
  setAppLoader,
  setAadharData
})

const rootReducer = (state, action) => {
  if (action.type === 'APP_LOGOUT_SUCCESS') {
    state = undefined
  }

  return appReducer(state, action)
}
export default rootReducer
