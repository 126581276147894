import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CInput from '../components/utils/CInput';
import CButton from '../components/utils/CButton';
import "../assets/jsx/WelcomeStyle.css"
import { useNavigate } from "react-router-dom";
import { AppBar, Grid, Link, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import FlyLogo from "../assets/images/flyhi_logo_full.svg"
import { sendOTP, adminLoginAPI, OTPForgotPassword, verifyOTP, resetPassword } from '../services/authenticationService';
import { validMobileNumber } from '../custom-hooks/validRegex';
import CAlert from '../components/utils/CAlert';
import signUPImage from "../assets/images/signUP.svg"
import group from "../assets/images/Group.png"


export default function Welcome(props) {
    const { adminLogin } = props
    const theme = useTheme()
    let navigate = useNavigate();
    const [error, setError] = React.useState(false)
    const [phone, setPhone] = React.useState('');
    const [alertErrorMsg, setAlertErrorMsg] = React.useState('');
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    // const [adminLogin, setAdminLogin] = React.useState(false)
    const [user, setUser] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [phoneError, setPhoneError] = React.useState(false)
    const [forgotPasswordScreen, setForgotPasswordScreen] = React.useState(false)
    const [otpScreen, setOtpScreen] = React.useState(false)
    const [enterPasswordScreen, setEnterPasswordScreen] = React.useState(false)
    const [otpDetails, setOtpDetails] = React.useState('')
    const [otp, setOTP] = React.useState('')

    const [errorMsg, setErrorMsg] = React.useState('')
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl).get("fcpId");;

    React.useEffect(() => {
        localStorage.clear();
        if (params) {
            localStorage.setItem('fcpId', atob(params))
        }
    }, [])

    function onSendOTPClick() {
        // dispatch(setAppLoader(true))
        if (!validMobileNumber.test(phone)) {
            setPhoneError(true)
            setErrorMsg("Please Enter Valid Mobile Number")
        } else {
            sendOTP(phone).then(data => {
                if (data.status === 200 || data.status === 201) {
                    // dispatch(setAppLoader(false))
                    const mobileData = {
                        details: data?.data?.details,
                        mobile: phone
                    }
                    localStorage.setItem('mobileData', JSON.stringify(mobileData));
                    navigate('/otpLogin')
                } else {
                    setPhoneError(true)
                    // dispatch(setAppLoader(false))
                    setErrorMsg(data?.response?.data?.message)
                }
            }).catch(error => {
                // dispatch(setAppLoader(false))
                console.log('error', error)
            })
        }
    }

    function onSignUpClick() {
        //navigate('/applicationForm?step=1')
        navigate('/evaluate')
    }

    function onInputChange(value) {
        setPhone(value.toString().slice(0, 10))
        //setPhone(value)
        setPhoneError(false)
    }

    // async function onAdminLoginClick() {
    //     setAdminLogin(true)
    // }

    async function onAdminLoginAPI() {
        const obj = {
            userName: user,
            password,
        }
        const adminLoginData = await adminLoginAPI(obj)
        if (adminLoginData?.status === 200) {
            localStorage.setItem("jwt", adminLoginData?.data?.jwt)
            localStorage.setItem("mobileData", JSON.stringify(adminLoginData?.data))
            navigate("/admin")
        } else {
            setError(true)
            setAlertErrorMsg("Wrong Crendential")
        }
    }

    function onForgotPasswordClick() {
        setForgotPasswordScreen(true)
    }

    // Send OTP for reset password
    async function onSendOTPResetPassword() {
        if (!validMobileNumber.test(phone)) {
            setPhoneError(true)
            setErrorMsg("Please Enter Valid Mobile Number")
        } else {
            const data = await OTPForgotPassword(phone, user)
            if (data.status === 200) {
                setOtpScreen(true)
                setPhoneError(false)
                setErrorMsg("")
                setOtpDetails(data?.data?.details)
            }
        }
    }

    // verify OTP for Admin Reset Password
    function onVerifyOTP() {
        const otpData = {
            otp: otp,
            phoneNo: phone,
            details: otpDetails,
        }
        verifyOTP(otpData).then(data => {
            if (data.status === 200 || data.status === 201) {
                setEnterPasswordScreen(true)
                setOtpScreen(false)
            } else {
                setError(true)
                setAlertErrorMsg("Wrong OTP")
            }
        }).catch(error => {
            console.log('error', error)
        })
    }

    // Reset Password
    async function onResetPassword() {
        const obj = {
            password,
            mobileNumber: phone
        }
        const onReset = await resetPassword(obj)
        setPassword('')
        setPhone('')
        setEnterPasswordScreen(false)
        setForgotPasswordScreen(false)
        //    / setAdminLogin(true)
    }
    return (
        <Box sx={{ height: { md: '100vh', xs: "" }, backgroundColor: { md: theme.palette.headerColor, xs: "" } }}>
            {error ? <CAlert open={error} message={alertErrorMsg} severity="error" /> : null}

            <AppBar sx={{ backgroundColor: theme.palette.headerColor }} position="static">
                <Toolbar sx={{ display: "flex", justifyContent: 'space-between' }} disableGutters>
                    <img style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "10px",width: "50px", height: "50px" }} src={FlyLogo} alt="logo" ></img>
                </Toolbar>
            </AppBar>
            {/* sx={{ p: { xs: 0, sm: 0, lg: 0, md: 0 } }}  */}
            <Container color='#E5E5E5' component="main">
                <CssBaseline />
                <Box className='vertical-center'>
                    <Grid sx={{ pt: { md: 12, xs: 6 }, flexGrow: 1 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container sx={{ flexDirection: { xs: "column", md: "row" } }} justifyContent="center" spacing={2}>
                                <Grid sx={{ mr: 8, backgroundColor: "white", p: 2 }} display={{ xs: "none", lg: "block" }} item xs={12} md={5} sm={5}>

                                    <div style={{ marginTop: "20px" }}>
                                        <div style={{ display: "flex", gap: "8px" }}>
                                            <Typography color="textColorR" sx={{ fontFamily: "Lato", fontWeight: "700" }} align='left'>
                                                Flyhi Finance
                                            </Typography>
                                            <Typography color="textColor" sx={{ fontFamily: "Lato", fontWeight: "400" }} align='left'>
                                                offers study loans for
                                            </Typography>
                                        </div>
                                        <Typography color="textColor" sx={{ fontFamily: "Lato" }} align='left' component="h1" variant="body1">
                                            everyone. With Flyhi, getting a study loan is easier than ever.
                                        </Typography>
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                        <div style={{ display: "flex", gap: "8px" }}>
                                            <Typography color="textColorR" sx={{ fontFamily: "Lato", fontWeight: "700" }} align='left' component="h1" variant="body1">
                                                Login
                                            </Typography>
                                            <Typography color="textColor" sx={{ fontFamily: "Lato" }} align='left' component="h1" variant="body1">
                                                or
                                            </Typography>
                                            <Typography color="textColorR" sx={{ fontFamily: "Lato", fontWeight: "700" }} align='left' component="h1" variant="body1">
                                                Sign up
                                            </Typography>
                                            <Typography color="textColor" sx={{ fontFamily: "Lato" }} align='left' component="h1" variant="body1">
                                                for a new account using
                                            </Typography>
                                        </div>
                                        <Typography color="textColor" sx={{ fontFamily: "Lato" }} align='left' component="h1" variant="body1">
                                            your mobile number to begin your Flyhi
                                        </Typography>
                                        <Typography color="textColor" sx={{ fontFamily: "Lato" }} align='left' component="h1" variant="body1">
                                            journey today.
                                        </Typography>
                                    </div>
                                </Grid>
                                {
                                    // Normal User Login Screen
                                    !adminLogin ?
                                        matches ? <Grid sx={{ backgroundColor: "white", p: 2 }} item xs={12} md={5} sm={5}>
                                            <div style={{ justifyContent: "center", display: "flex", gap: "10px" }}>
                                                <Typography color="textColor" sx={{ fontFamily: "Lato", fontWeight: "bold" }} align='left' variant={matches ? "subtitle1" : "subtitle2"} >
                                                    Welcome to
                                                </Typography>
                                                <Typography color="textColorR" sx={{ fontFamily: "Lato", fontWeight: "bold" }} align='left' variant={matches ? "subtitle1" : "subtitle2"} >
                                                    Flyhi Finance.
                                                </Typography>
                                            </div>
                                            <Typography color="textColor" sx={{ pt: 2, fontWeight: "700", fontFamily: "Lato" }} align='left' variant="body1" >
                                                Existing User - Login
                                            </Typography>
                                            <Box className='form' sx={{ mt: 1 }}>
                                                <CInput type="number" maxLength={10} isError={phoneError} helperText={errorMsg} autoFocus={true} onChange={onInputChange} name="phone" label='Mobile Number*' value={phone} />
                                                <CButton disabled={!phone} handleClick={onSendOTPClick} name="Send OTP" />
                                            </Box>
                                            <Divider sx={{ mt: 2, mb: 1 }} />
                                            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>
                                                    <Typography color="textColor" sx={{ fontFamily: "Lato", fontWeight: "700", fontSize: "20px" }} align='left' >
                                                        New User
                                                    </Typography>
                                                    <Box className='form' sx={{ mt: 1 }}>
                                                        <CButton handleClick={onSignUpClick} name="Sign Up" />
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </Grid> :
                                            <Grid sx={{ backgroundColor: "white", p: 2 }} item xs={12} md={5} sm={5}>
                                                <Toolbar sx={{ display: "flex", justifyContent: 'center', pb:4 }} disableGutters>
                                                    <img width={"90px"}  src={FlyLogo} alt="logo" ></img>
                                                </Toolbar>
                                                <div style={{ justifyContent: "center" }}>
                                                    <Typography color="textColor" sx={{ fontFamily: "Lato", fontWeight: "bold" }} align='center' variant="subtitle1" >
                                                        Welcome to
                                                    </Typography>
                                                    <Typography color="textColorR" sx={{ fontFamily: "Lato", fontWeight: "bold", lineHeight: 1 }} align='center' variant="subtitle1" >
                                                        Financial Possibilities.
                                                    </Typography>
                                                </div>
                                                <Box className='form' sx={{ mt: 1 }}>
                                                    <CInput isBold={true} type="number" maxLength={10} isError={phoneError} helperText={errorMsg} autoFocus={true} onChange={onInputChange} name="phone" label='Registered Mobile Number' value={phone} />
                                                </Box>
                                                <Grid xs={12} sx={{ mt: 2, width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <CButton width={"170px"}  disabled={!phone} handleClick={onSendOTPClick} name="Login" />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{ px: 4, py: 6 }}
                                                >
                                                    <img width={"250px"} src={signUPImage} alt="signup" />
                                                    <img width={"100px"} src={group} alt="signup" />

                                                </Grid>
                                                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <Typography color="textColor" variant='Label16' sx={{ fontFamily: "Lato", fontWeight: "bold" }} align='center' >
                                                            No Account?{" "}
                                                        </Typography>
                                                        <Typography onClick={onSignUpClick} color="textColorR" sx={{ fontFamily: "Lato", fontWeight: "bold" }} variant="Label16" align='center'>
                                                            Sign up
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid> :
                                        !forgotPasswordScreen ?
                                            // Admin Login Screen
                                            <Grid sx={{ backgroundColor: "white", p: 2 }} item xs={12} md={5} sm={5}>
                                                <Typography color="textColorR" sx={{ fontFamily: "Lato" }} align='left' variant="subtitle1" >
                                                    Admin Login
                                                </Typography>
                                                <Box className='form' sx={{ mt: 1 }}>
                                                    {/* Done Bring cusrsor automatically here */}
                                                    <CInput autoFocus={true} onChange={setUser} name="user" label='User*' />
                                                    <CInput type="password" isError={phoneError} helperText={errorMsg} onChange={setPassword} name="password" label='Password*' />
                                                </Box>
                                                <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                                                    <CButton disabled={!user || !password} handleClick={() => onAdminLoginAPI()} name="Login" />
                                                    <Link onClick={onForgotPasswordClick} component="button" underline="hover">
                                                        Forgot Password
                                                    </Link>
                                                </div>

                                            </Grid>
                                            :
                                            <React.Fragment>
                                                <React.Fragment>
                                                    {/* // Forgot password Screen */}
                                                    {
                                                        !otpScreen && !enterPasswordScreen && <Grid sx={{ backgroundColor: "white", p: 2 }} item xs={12} md={5} sm={5}>
                                                            <Typography color="textColorR" sx={{ fontFamily: "Lato" }} align='left' variant="subtitle1" >
                                                                Admin Login
                                                            </Typography>
                                                            <Box className='form' sx={{ mt: 1 }}>
                                                                {/* Done Bring cusrsor automatically here */}
                                                                <CInput maxleng={10} onChange={setUser} name="user" label='User*' autoFocus={true} />
                                                                <CInput type="number" maxleng={10} isError={phoneError} helperText={errorMsg} onChange={onInputChange} name="phone" label='Mobile Number*' />

                                                                {/* Done Verify if admin login was successfull only then navigate else display message */}
                                                            </Box>
                                                            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                                                                <CButton disabled={!user || !phone} handleClick={() => onSendOTPResetPassword()} name="send OTP" />
                                                            </div>
                                                            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div>
                                                                    <Typography color="textColorR" sx={{ fontFamily: "Lato" }} align='left' variant="subtitle1" >
                                                                        Return to Admin Login
                                                                    </Typography>
                                                                    <Box className='form' sx={{ mt: 1 }}>
                                                                        <CButton handleClick={() => setForgotPasswordScreen(false)} name="Admin Login " />
                                                                    </Box>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </React.Fragment>
                                                {/* // OTP Screen */}
                                                <React.Fragment>
                                                    {
                                                        otpScreen && <Grid sx={{ backgroundColor: "white", p: 2 }} item xs={12} md={5} sm={5}>
                                                            <Typography color="textColorR" sx={{ fontFamily: "Lato" }} align='left' variant="subtitle1" >
                                                                OTP for Reset Password
                                                            </Typography>
                                                            <Box className='form' sx={{ mt: 1 }}>
                                                                {/* Done Bring cusrsor automatically here */}
                                                                <CInput type="number" autoFocus={true} onChange={setOTP} name="phone" label='OTP*' />

                                                                {/* Done Verify if admin login was successfull only then navigate else display message */}
                                                            </Box>
                                                            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                                                                <CButton disabled={!otp} handleClick={() => onVerifyOTP()} name="Verify OTP" />
                                                            </div>
                                                            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div>
                                                                    <Typography color="textColorR" sx={{ fontFamily: "Lato" }} align='left' variant="subtitle1" >
                                                                        Return to Admin Login
                                                                    </Typography>
                                                                    <Box className='form' sx={{ mt: 1 }}>
                                                                        <CButton handleClick={() => { setForgotPasswordScreen(false); setOtpScreen(false) }} name="Admin Login " />
                                                                    </Box>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </React.Fragment>
                                                {/* // Reset Passsword Screen */}
                                                <React.Fragment>
                                                    {
                                                        enterPasswordScreen && <Grid sx={{ backgroundColor: "white", p: 2 }} item xs={12} md={5} sm={5}>
                                                            <Typography color="textColorR" sx={{ fontFamily: "Lato" }} align='left' variant="subtitle1" >
                                                                Admin Login
                                                            </Typography>
                                                            <Box className='form' sx={{ mt: 1 }}>
                                                                {/* Done Bring cusrsor automatically here */}
                                                                <CInput type="password" autoFocus={true} onChange={setPassword} name="password" label='Password*' />
                                                                {/* <CInput type="Confirm password" maxleng={10} isError={phoneError} helperText={errorMsg} onChange={setPassword} name="password" label='Password*' /> */}

                                                                {/* Done Verify if admin login was successfull only then navigate else display message */}
                                                            </Box>
                                                            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                                                                <CButton disabled={!password} handleClick={() => onResetPassword()} name="Reset Password" />
                                                            </div>
                                                            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div>
                                                                    <Typography color="textColorR" sx={{ fontFamily: "Lato" }} align='left' variant="subtitle1" >
                                                                        Return to Admin Login
                                                                    </Typography>
                                                                    <Box className='form' sx={{ mt: 1 }}>
                                                                        <CButton handleClick={() => { setForgotPasswordScreen(false); setOtpScreen(false); setEnterPasswordScreen(false) }} name="Admin Login " />
                                                                    </Box>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </React.Fragment>
                                            </React.Fragment>

                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box >
    );
}
