export default (state, action) => {
    if (typeof state === 'undefined') {
      state = false
    }
    switch (action.type) {
      case 'SAVE_AADHAR_DATA': {
        return { data: action.payload }
      }
      default:
        return state
    }
  }