import { Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React from "react";
import { setAppLoader } from "../../actions/setAppLoader";
import { getUserData } from "../../actions/getUserData";
import { saveFormPath } from "../../services/userService";
export default function LoanRejected() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const userId = userData?.userId;

    async function onSubmit(e) {
        e.preventDefault();
        localStorage.clear();
        window.location.href = '/welcome';
    }

    return (
        <form onSubmit={(e) => onSubmit(e)} className='applicationFormContainer' >
            <Grid item xs={12} >
                <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Loan Application Declined</Typography>
            </Grid>
            <Grid item sx={{ textAlign: "left" }} xs={10} >
                <Typography color='textColor' variant='body1' sx={{ pt: 2,fontWeight:"bold" }}>
                    Sorry!
                </Typography>
            </Grid>
            <Grid item sx={{ textAlign: "left" }} xs={10} >
                <Typography color='textColor' variant='body1' sx={{ pt: 2 }}>
                    Your loan can not be approved as your application currently does not meet our Policy Norms. Rejection of your Loan in no way represents your credit worthiness. We would be happy to serve you in future. Team FlyHi!
                </Typography>

            </Grid>
            <div style={{ padding: "50px 0px 0px", display: "flex", justifyContent:"center"}}>
                <Button  
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    variant="contained"
                    >
                Done
                </Button>
            </div>
        </form >
    )
}