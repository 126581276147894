import { Avatar, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CButton from "../utils/CButton";
import FlyLogo from "../../assets/images/flyhiImg.svg"
import CAlert from "../utils/CAlert";
import { useDispatch, useSelector } from "react-redux";
import { setAppLoader } from "../../actions/setAppLoader";
import { getUserData } from "../../actions/getUserData";
import { Box } from "@mui/system";
import { downloadSanctionLetter } from "../../services/pdfService";
import { saveFormPath } from "../../services/userService";

export default function LoanApprovedDetails({ setTabValue, setStepperCount, userDetailId }) {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [error, setError] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('')
    const [isTerms, setIsTerms] = React.useState(false)
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const userId = userData?.userId;
   
    useEffect(() => {
        dispatch(getUserData(userDetailId))
    }, []);

    React.useEffect(() => {
        dispatch(setAppLoader(false))
    }, [isFetching])

    async function onSubmitEsign(e) {
        e.preventDefault();
        saveFormPath("/loanApproved", userDetailId)
        navigate("/loanAgreement", { state: { tabValue: 10, stepperCount: 6, userDetailId:userDetailId } })
    }

   async function getSanctionLetter() {
       dispatch(setAppLoader(true))
       const data = await downloadSanctionLetter(userDetailId);
       var blob = new Blob([data], {type: "application/pdf"});
       var link = document.createElement("a");
       link.href = window.URL.createObjectURL(blob);
       link.download = "SanctionLetter-" + new Date().getTime()+ ".pdf";
       link.click();
       dispatch(setAppLoader(false))
    }
   


    return (
        <form onSubmit={(e) => onSubmitEsign(e)} c className='applicationFormContainer' >
            {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
            }
            {
                errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
            }
            <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left", fontWeight: "bold",fontFamily:"lato"  }}>Loan Application Approved</Typography>
            <div>
            <Grid sx={{ textAlign: "left" }} item xs={12}>
                <Typography color='textColorGreen' variant='aadharLabel' sx={{ textAlign: "left",fontWeight: "600", fontFamily:"lato" }}>Congratulations!</Typography>
            </Grid>
            <Grid sx={{ textAlign: "left" }} item xs={12}>
                <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Your loan application is approved</Typography>
            </Grid>
            </div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid sx={{ pt: 1, textAlign: "left" }} item xs={12}>
                    <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left", fontWeight: "bold",fontFamily:"lato" }}>Your Loan Approved Summary</Typography>
                </Grid>
                <Grid sx={{  textAlign: "left" }} item xs={12}>
                    {/* <img style={{width: "50px", height: "50px"}} src={FlyLogo} alt="logo" /> */}
                    <Typography color='textColor' variant='Label20' sx={{ textAlign: "left" ,fontWeight: "bold",fontFamily:"lato" }}> Flyhi Study Loan- {userDetails?.instituteName?.instituteName} - 12 Months</Typography>
                </Grid>

                <Grid sx={{ my: 1, textAlign: "left" }} item xs={12}>
                    <Divider />
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Full Name</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left", fontFamily: "lato" }}>{userDetails?.aadharDetails?.userFullName}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Address</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{userDetails?.aadharDetails?.userAddress}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Date of Birth</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{`${userDetails?.aadharDetails?.userDob}`}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Student Name</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{userDetails?.studentDetails?.studentName}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Loan Amount</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{userDetails?.loanAmount}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Tenor</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{userDetails?.loanTenure}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>EMI</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>INR {Math.ceil(userDetails?.emiAmount)}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Date of Next EMI</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>5th Of every month</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>APR</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{userDetails?.apr}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>EMI Type</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{userDetails?.schemeDetails?.numberOfAdvanceEmis} - Advance</Typography>
                </Grid>
               
                <Grid sx={{ textAlign: "left" }} item xs={12}>
                    <Divider />
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={12}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ borderBottom: "1px solid #4D4948", fontWeight: "bold", textAlign: "left" }}>Payment due today</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Processing Fees</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>INR {userDetails?.processingFees + userDetails?.processingFees * 0.18} (Inclusive of GST)</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Advance EMI</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>INR {Math.ceil(userDetails?.emiAmount * userDetails?.schemeDetails?.numberOfAdvanceEmis)}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Total</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>INR {(Math.round((Math.ceil(userDetails?.emiAmount) * (userDetails?.schemeDetails?.numberOfAdvanceEmis) + userDetails?.processingFees + userDetails?.processingFees * 0.18 )* 100) / 100).toFixed(2)}</Typography>
                </Grid>
            </Grid>
            <Grid>
                <Box sx={{ borderRadius: "20px", textAlign: "left", fontSize: "18px", bgcolor: '#DBEEFF', color: '#4D4948', p: 2, mt: 3 }}>
                    The loan amount includes insurance premium if availed.
                </Box>
            </Grid>
            <Grid sx={{ textAlign: "left" , mt: 3}} item xs={12}>
                <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Kindly note, This sanction is valid for 30 days, after which loan automatically stands cancelled.
                    Please proceed to sign loan agreement, register NACH mandate and Pay PF & Advance EMI.</Typography>
            </Grid>
            <Grid item sx={{ textAlign: "left", display: "flex" , mt: 3}} xs={12}>
                <a onClick={getSanctionLetter} style={{fontSize:"18px", cursor: "pointer", color: "#D32F2F", borderBottom: "1px solid #D32F2F" }} >View and Download my  KFS cum sanction letter  & Loan documents. </a>
            </Grid>
            <Grid item sx={{ textAlign: "left", display: "flex",mt: 2 }} xs={12}>
                <FormGroup sx={{ marginRight: "-20px" }}>
                    <FormControlLabel
                        control={<Checkbox checked={isTerms} onChange={() => setIsTerms(!isTerms)}  style={{ color: "#D32F2F" }} name="gilad" />}
                    />
                </FormGroup>
                <div style={{ fontSize: "16px", marginTop: "7px" }}>
                    <span style={{ color: "#D32F2F" }}>
                        I have read the sanction letter and loan agreement and agree to the terms there in.
                    </span>
                </div>
            </Grid>
            <div style={{ padding: "50px 0px 0px", display: "flex", justifyContent:"center"}}>
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    variant="contained"
                    disabled={!isTerms}>
                    Agree and Proceed to Sign
                </Button>
            </div>
        </form >
    )
}