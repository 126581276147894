import { Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React from "react";
import { setAppLoader } from "../../actions/setAppLoader";
import { getUserData } from "../../actions/getUserData";
import { saveFormPath } from "../../services/userService";
export default function LoanApplicationProgress(props) {
    const alreadySubmitted = props.alreadySubmitted;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const userId = userData?.userId;
    const userDetails = useSelector((state) => state?.getUserData?.data);

    //Show Loader
    React.useEffect(() => {
        dispatch(getUserData(userId))
    }, [])


    //Show Loader
    React.useEffect(() => {
        dispatch(setAppLoader(isFetching))
    }, [isFetching])

    async function onSubmitBankStatement(e) {
        e.preventDefault();
        saveFormPath("/loanApproved", userDetails?.detailId)
        navigate("/loanApproved", { state: { tabValue: 10, stepperCount: 6 } })
    }

    return (
        <form onSubmit={(e) => onSubmitBankStatement(e)} className='applicationFormContainer' >
            {alreadySubmitted ?
                <Grid item xs={12} >
                    <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Loan Application Already Submitted</Typography>
                </Grid>
                :
                <Grid item xs={12} >
                    <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Loan Application in Progress</Typography>
                </Grid>}
            <Grid item sx={{ textAlign: "left" }} xs={10} >
                <Typography color='textColor' variant='body1' sx={{ pt: 2 }}>
                    Thank you for completing your Loan Application. We have received your application. Now sit back and relax.
                    We will process your loan application and get back to you to complete remaining journey. *
                </Typography>

            </Grid>
            <div style={{ padding: "50px 0px 0px", display: "flex", justifyContent: "center" }}>
                {/* <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    variant="contained"
                    >
                    Confirm and Submit
                </Button> */}
            </div>
            <Grid sx={{ textAlign: "left" }}>
                <Typography color="textColorR" sx={{ fontWeight: "bold", fontFamily: "lato" }} align='left' variant="inputLabel">
                    *Terms and Conditions apply
                </Typography>
            </Grid>
        </form >
    )
}