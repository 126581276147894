import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import '../assets/jsx/WelcomeStyle.css'
import "../assets/jsx/ApplicationForm.css";
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CssBaseline, Grid, Paper } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import FlyLogo from "../assets/images/flyhi_logo_full.svg"
import signUPImage from "../assets/images/signUP.svg"
import CStepper from '../components/utils/CStepper';
import OTPLogin from '../components/registration/OTPLogin';
import SignUp from '../components/registration/SignUp';
import InstituteSelection from '../components/forms/InstituteSelection';
import StudentDetails from '../components/forms/StudentDetails';
import PersonalDetails from '../components/forms/PersonalDetails';
import PANAadharVerification from '../components/forms/PANAadharVerification';
import AadharOutput from '../components/forms/AadharOutput';
import NewAddress from '../components/forms/NewAddress';
import BankStatement from '../components/forms/BankStatement';
import FaceVerification from '../components/forms/FaceVerification';
import CMenu from '../components/utils/CMenu';
import LoanApplicationProgress from '../components/forms/LoanApplicationProgress';
import { getDropDownData } from '../actions/getDropDownData';


const ApplicationForm = () => {
    const theme = useTheme();
    const location = useLocation();
    const dispatch = useDispatch()
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const stepNumber = useSelector((state) => state?.getStep?.step);
    const [stepperCount, setStepperCount] = React.useState(0);
    const [tabValue, setTabValue] = React.useState(stepNumber);
    let jwt = localStorage.getItem('jwt')
    const [step, setStep] = React.useState();
    const [isOtherSelected, setIsOtherSelected] = React.useState(true);
    const [isNewAddress, setIsNewAddress] = React.useState(false);


    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const term = queryParams.get("step")
        setStep(+term)
    }, [location])

    React.useEffect(() => {
        if (jwt)
            dispatch(getDropDownData())
    }, [jwt])

    function returnHome() {
        // localStorage.clear()
        // navigate('/')
    }

    return (
        <div style={{ height: "auto" }}>
            {/* <CAlert /> */}
            <AppBar sx={{ backgroundColor: theme.palette.headerColor }} position="static">
                <Container maxWidth="xl">
                    <Toolbar sx={{ display: "grid", gridAutoFlow: 'column', justifyContent: 'space-between' }} disableGutters>
                        <img style={{ marginTop: "10px", marginBottom: "10px",width: "50px", height: "50px" }} src={FlyLogo} alt="logo" />
                        <div sx={{ pt:6 , gridColumn: '3 / 5', display: { xs: 'none', md: 'block', lg: 'none' } }}>
                            <CStepper tabValue={stepperCount} />
                        </div>
                        {
                            !matches &&
                            <div>
                                <Typography color='textColorR' sx={{ textAlign: "left", fontFamily: "lato", }} variant="body1">{`${tabValue}/9`}</Typography>
                            </div>
                        }
                        <div onClick={returnHome} sx={{ gridColumn: '4 / 5', textAlign: "left", fontFamily: "lato", cursor: "pointer" }} variant="body1">
                            <CMenu />
                        </div>

                    </Toolbar>
                </Container>
            </AppBar>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />

                <Grid item xs={12} sm={6} md={6} component={Paper} >
                     <SignUp setStepperCount={setStepperCount} setTabValue={setTabValue} />
                    {(tabValue === 1 && step === 2) ? <OTPLogin setStepperCount={setStepperCount} setTabValue={setTabValue} /> : null}

                    {/* {tabValue === 2 ? <InstituteSelection setIsOtherSelected={setIsOtherSelected} setStepperCount={setStepperCount} setTabValue={setTabValue} /> : null}
                    {tabValue === 3 ? <StudentDetails isOtherSelected={isOtherSelected} setStepperCount={setStepperCount} tabValue={tabValue} setTabValue={setTabValue} /> : null}
                    {tabValue === 4 ? <PersonalDetails setTabValue={setTabValue} setStepperCount={setStepperCount} /> : null}
                    {tabValue === 5 ? <PANAadharVerification setTabValue={setTabValue} setStepperCount={setStepperCount} /> : null}
                    {tabValue === 6 ? <AadharOutput isNewAddress={isNewAddress} setIsNewAddress={setIsNewAddress} setTabValue={setTabValue} setStepperCount={setStepperCount} /> : null}
                    {tabValue === 7 ? <NewAddress setTabValue={setTabValue} setStepperCount={setStepperCount} /> : null}
                    {tabValue === 8 ? <BankStatement isNewAddress={isNewAddress} setTabValue={setTabValue} setStepperCount={setStepperCount} /> : null}
                    {tabValue === 9 ? <FaceVerification setTabValue={setTabValue} setStepperCount={setStepperCount} /> : null}
                    {tabValue === 10 ? <LoanApplicationProgress setTabValue={setTabValue} setStepperCount={setStepperCount} /> : null} */}
 
                    {/* {tabValue !== 1 ? <div style={{ padding: "10px 30px 0px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <CButton name="Back" />
                        <CButton name="Save & Submit" />
                    </div> : null} */}

                </Grid>
                <Grid
                    item
                    display={{ xs: "none", lg: "block", sm: "block" }}
                    sm={6}
                    md={6}
                    sx={{
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <img src={signUPImage} alt="signup" />
                </Grid>
            </Grid>
        </div >
    );
};
export default ApplicationForm;
