import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { uploadFile } from "../../services/studentService";

import CSelectBox from '../utils/CSelectBox';
import { getAdminDropdown, saveAdminDocs } from '../../services/AdminServices';
import secondsToHours from 'date-fns/esm/secondsToHours/index.js';
import { setAppLoader } from '../../actions/setAppLoader';
import { useDispatch } from 'react-redux';

export default function AdminDocUpload(props) {

    const { username, setUrlsAndDocType, docsUploadDropdownUrl1, docsUploadDropdownUrl2, detailId, setShouldFetch, shouldFetch } = props
    const [docsId, setDocId] = React.useState(0)
    const [selectBoxData, setSelectBoxData] = React.useState(null);
    const [uploadedDocUrl, setUploadedDocUrl] = React.useState(null);
    const dispatch = useDispatch()
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAdminDropdown();
                setSelectBoxData(response.data); // Store the API data in the state variable
            } catch (error) {
                console.error('Error fetching data:', error);
                // Optionally, handle errors, for example, by setting an error state
            }
        };

        fetchData();
    }, []);

    


    const inputRef = React.useRef(null);


    // On File Upload
    async function handleFileChange(event, docType) {
        dispatch(setAppLoader(true))
        const fileObj = event.target.files && event.target.files[0];
        //setFileObj(fileObj)
        // setIsFileUpload(false)
        // setFileName(fileObj?.name)
        const formData = new FormData();
        formData.append("file", fileObj);
        formData.append("delimeter", username);
        formData.append("detailId", detailId)
       await uploadFile(formData).then(res => {
            if (res?.status === 200) {
                    const selectedItem = selectBoxData.find(item => item.value === parseInt(docsId));
                    const docObj = {
                        detailId : detailId,
                        uploadUrl : res?.data,
                        dropdownDocTypeId :  docsId,
                        dropdownDocTypeDescription : selectedItem.name

                    }
                    setUploadedDocUrl(res?.data)
                    saveAdminDocs(detailId,docObj)
                    setShouldFetch(true)
                    dispatch(setAppLoader(false))
            }
        }).catch(err => {
            dispatch(setAppLoader(false))
        })
        dispatch(setAppLoader(false))
    }

    function onFileUpload() {
        // 👇️ open file input box on click of other element
        inputRef.current.click();
    }

    function extractAndDecodeDocName(url) {
        // Find the last occurrence of '-' and '.pdf'
        const lastDashIndex = url?.lastIndexOf('-');
        const lastPdfIndex = url?.lastIndexOf('.pdf');
      
        // Extract the document name based on the indices
        // If either '-' or '.pdf' is not found, return an empty string
        if (lastDashIndex === -1 || lastPdfIndex === -1) {
          return '';
        }
      
        // Extract the substring and decode it
        const encodedDocName = url.substring(lastDashIndex + 1, lastPdfIndex);
        const decodedDocName = decodeURIComponent(encodedDocName);
      
        return decodedDocName;
      }
    

    return (

        <Box sx={{ m: 3 }}>
            <Typography>Documents Upload</Typography>
            <Typography color='textColorR' variant='body2' sx={{ fontWeight: "bold", textAlign: "left", mt:2 }}>Document 1</Typography>
            <Grid container xs={6} spacing={2} sx={{mb:6}}>
                <Grid item xs={6}>
                    <input accept="application/pdf" type="file" style={{ display: 'none' }} onChange={(e)=>handleFileChange(e,1)} ref={inputRef} />
                    <Button onClick={()=>onFileUpload()} disabled={!docsId} color="uploadButton" fullWidth variant="contained" startIcon={<FileUploadIcon />}>
                        Upload Document
                    </Button>
                </Grid>
                <Grid item xs={6} >
                <CSelectBox 
                            onChange={setDocId}
                            optionList={selectBoxData}/>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "center", gap: "5px" }} item xs={12} md={6}>
                        { uploadedDocUrl ?
                           
                            <React.Fragment>
                                {/* <img src={success} /> */}
                                <Typography color='textColor' variant='body2' sx={{ textAlign: "left" }}>Document {extractAndDecodeDocName(uploadedDocUrl)} uploaded, Check Admin Upload Section.</Typography>
                            </React.Fragment> : null
                        }
                </Grid>
            </Grid>
        </Box>



    )
}