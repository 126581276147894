import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Box, Button, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import CButton from "../utils/CButton";
import CInput from "../utils/CInput";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CSelectBox from "../utils/CSelectBox";
import { saveStudentData, uploadFile, deleteFile } from "../../services/studentService";
import CAlert from "../utils/CAlert";
import { getDropDownData } from '../../actions/getDropDownData';
import CDateP from "../utils/CDateP";
import { setAppLoader } from "../../actions/setAppLoader";
import CloseIcon from '@mui/icons-material/Close';
import { getUserData } from "../../actions/getUserData";
import success from "../../assets/images/Vector.svg";
import { saveFormPath } from "../../services/userService";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function StudentDetails(props) {
    const { tabValue, setTabValue, setStepperCount, userDetailId } = props;
    const navigate = useNavigate();
    const [studentName, setStudentName] = React.useState('');
    const [studentDob, setStudentDOB] = React.useState('')
    const [enrollementList, setEnrollementList] = React.useState([]);
    const [enrollmentType, setEnrollmentType] = React.useState('')
    const [studentId, setStudentId] = React.useState('')
    const [relationshipList, setRelationshipList] = React.useState([]);
    const [relationship, setRelationship] = React.useState('')
    const [other, setOther] = React.useState('')
    const [otherId, setOtherId] = React.useState('')
    const [fileURL, setFileURL] = React.useState('')
    const [fileName, setFileName] = React.useState('')
    const [errorMsg, setErrorMsg] = React.useState('');
    const [error, setError] = React.useState(false)
    const inputRef = React.useRef(null);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const dropDownData = useSelector((state) => state?.getDropDownData?.data);
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const dispatch = useDispatch();
    const MAX_FILE_SIZE = 15000 // 15MB
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    const disabledButton = !studentName || !studentDob
        || !relationship || !(relationship === 'other' ? other : true) || !(userDetails?.instituteName?.isOtherName || userDetails?.instituteAddress?.isOtherAddress || userDetails?.instituteCourse?.isOtherCourse
            ? fileURL : true) || !enrollmentType

    useEffect(() => {
        dispatch(getUserData(userDetailId))
    }, []);

    //Show Loader
    React.useEffect(() => {
        dispatch(setAppLoader(isFetching))
    }, [isFetching])

    // SetData if there
    React.useEffect(() => {
        setStudentName(userDetails?.studentDetails?.studentName)
        setStudentDOB(userDetails?.studentDetails?.studentDob)
        setEnrollmentType(userDetails?.studentDetails?.enrollmentType?.valueId)
        setStudentId(userDetails?.studentDetails?.studentUniqueId)
        setFileURL(userDetails?.studentDetails?.demandLetterUploadId)
        const fName = userDetails?.studentDetails?.demandLetterUploadId?.split('/')
        if (userDetails?.studentDetails?.relationshipType?.valueDesc === "OTHER") {
            setRelationship("other")
            setOtherId(userDetails?.studentDetails?.relationshipType?.valueId)
            setOther(userDetails?.studentDetails?.other)
        } else {
            setRelationship(userDetails?.studentDetails?.relationshipType?.valueId)
        }
        setFileName(fName && fName[4])
    }, [userDetails])

    React.useEffect(() => {
        dispatch(getDropDownData())
    }, [])

    useEffect(() => {
        // Filter Enrollment List
        let enrolmentObj = dropDownData?.length && dropDownData?.find(x => x.keyDesc === "ENROLLMENT_TYPE");
        const tempEnrollementData = []
        enrolmentObj?.valueData?.map(eItem => {
            tempEnrollementData.push({
                value: eItem?.valueid,
                name: eItem?.valuedesc
            })
        })
        setEnrollementList(tempEnrollementData)

        // Filter RelationShip List
        let relationShiptObj = dropDownData?.length && dropDownData?.find(x => x.keyDesc === "RELATIONSHIP_WITH_APPLICANT");
        const tempRelationShipData = []
        relationShiptObj?.valueData?.map(eItem => {
            if (eItem?.valuedesc?.toLowerCase() === "other") {
                setOtherId(eItem?.valueid)
                tempRelationShipData.push({
                    name: "Other",
                    value: "other"
                })
            } else {
                tempRelationShipData.push({
                    value: eItem?.valueid,
                    name: eItem?.valuedesc
                })
            }
        })
        setRelationshipList(tempRelationShipData)
    }, [dropDownData])

    // Save Institute Data
    function onSubmitStudentData(e) {
        e.preventDefault();
        const obj = {
            studentId: userDetails?.studentDetails?.studentId ? userDetails?.studentDetails?.studentId : null,
            studentName: studentName,
            studentUniqueId: studentId ? studentId : null,
            studentDob: studentDob,
            enrollmentType: +enrollmentType,
            relationshipType: relationship === 'other' ? +otherId : +relationship,
            demandLetterUploadId: fileURL,
            other: relationship === 'other' ? other : null,
            userInstituteDetailId: userDetails?.detailId
        }

        saveStudentData(obj).then(data => {
            if (data.status === 200 || data.status === 201) {
                setErrorMsg("Data Saved Successfully")
                dispatch(getUserData(userDetailId))
                if (userDetails?.instituteAddress?.instituteRiskCode === 3) {
                    saveFormPath("/panAadharDetails", userDetails?.detailId)
                    setTabValue(tabValue + 2)
                    setStepperCount(tabValue + 2)
                    navigate("/panAadharDetails", { state: { tabValue: 6, stepperCount: 5 } })
                } else {
                    saveFormPath("/personalDetails", userDetails?.detailId)
                    setTabValue(tabValue + 1)
                    setStepperCount(tabValue + 1)
                    navigate("/personalDetails", { state: { tabValue: tabValue + 1, stepperCount: tabValue + 1, userDetailId: userDetailId } })
                }
            } else {
                setError(true)
                setErrorMsg(data?.response?.data?.message)
            }
        }).catch(error => {
            console.log('error', error)
        })
    }


    function onFileUpload() {
        // 👇️ open file input box on click of other element
        inputRef.current.click();
    };

    // On RelationShip Changes
    function onRelationChange(value) {
        setOther('')
        setRelationship(value)
    }

    async function handleFileChange(event) {
        setError(false)
        const fileObj = event.target.files && event.target.files[0];
        const extension = fileObj.name.split('.')[1]
        if (event.target.files.length > MAX_FILE_SIZE) {
            setError(true)
            setErrorMsg("File length should be less than 15Mb")
            return;
        }
        if (extension !== 'pdf' && extension !== 'PDF') {
            setError(true)
            setErrorMsg("Only PDF files are allowed")
            return;
        }
        const formData = new FormData();
        formData.append("file", fileObj);
        formData.append("delimeter", userDetails?.user?.userName)
        formData.append("detailId", +localStorage.getItem('detailId'))
        uploadFile(formData).then(res => {
            if (res?.status === 200) {
                setFileName(fileObj?.name)
                setFileURL(res?.data)
            }
        }).catch(err => {
            setError(true)
            setErrorMsg("File length should be less than 5Mb")
        })
    }

    // Delete uploaded file by fileURL
    async function deleteFileByURL() {
        await deleteFile(fileURL)
        setFileURL('')
    }

    function handleBack() {
        setTabValue(tabValue - 1);
        setStepperCount(tabValue - 1);
        navigate("/instituteDetails", { state: { tabValue: tabValue - 1, stepperCount: tabValue - 1, userDetailId: userDetailId } })
    }

    return (
        <form onSubmit={(e) => onSubmitStudentData(e)} className='applicationFormContainer' >
            {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
            }
            {
                errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
            }
            {
                !matches &&
                <Box sx={{ display: "flex" }}>
                    <ArrowBackIosIcon onClick={() => handleBack()} />
                </Box>
            }
            {
                matches &&
            <Typography color='textColor' variant={matches ? "subtitle1" : 'subtitle3'} sx={{ fontWeight: { xs: "bold", sm: "none" }, textAlign: { xs: "center", md: "left" } }}>Student Details </Typography>
            }

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                    <CInput value={studentName} onChange={setStudentName} label="Student's Name*" name="studentName" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CDateP value={studentDob} onChange={setStudentDOB} label="Student's DOB*" name="dob" placeholder="dd-mm-yyy" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CSelectBox
                        onChange={setEnrollmentType}
                        optionList={enrollementList}
                        defaultValue={enrollmentType ?? ""}
                        name="enrollmentType"
                        label="Enrollment Type*" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CInput maxLength={20} value={studentId} onChange={setStudentId} label="Unique Student Id" name="studentId" />
                </Grid>
            </Grid>
            <Grid container sx={{ pt: { md: 6, lg: 6 } }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                    <CSelectBox
                        onChange={onRelationChange}
                        optionList={relationshipList}
                        defaultValue={relationship ?? ""}
                        name="relationship"
                        label="Relationship with applicant*" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CInput disabled={!(relationship === 'other')} value={other} onChange={setOther} label="Please specify if Other" name="other" />
                </Grid>
            </Grid>
            {
                !matches && userDetails?.instituteCourse?.isOtherCourse &&
                <Grid item sx={{ my: 2 }} xs={12}>
                    <Divider style={{ background: '#616161' }} />
                </Grid>
            }
            {
                !matches && userDetails?.instituteCourse?.isOtherCourse &&
                <Typography color='textColor' variant={matches ? "subtitle1" : 'subtitle3'} sx={{ fontWeight: { xs: "bold", sm: "none" }, textAlign: { xs: "center", md: "left" }, fontFamily: "Poppins"}}>Fee demand letter</Typography>

            }

            {userDetails?.instituteCourse?.isOtherCourse &&
                <Grid container rowSpacing={1} sx={{ pt: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {fileURL &&
                        <Grid sx={{ display: "flex", alignItems: "center", gap: "5px" }} item xs={12}>
                            <Typography color='textColor' variant='body1' sx={{ textAlign: "left" }}>{fileName}</Typography>
                            <CloseIcon color="warning" onClick={deleteFileByURL} />
                        </Grid>
                    }
                    <Grid item xs={12} md={6} >
                        <input accept="application/pdf" type="file" style={{ display: 'none' }} onChange={handleFileChange} ref={inputRef} />
                        <Button onClick={onFileUpload} color="uploadButton" fullWidth variant="contained" startIcon={<FileUploadIcon />}>
                            Upload Course Fee Demand Letter
                        </Button>
                    </Grid>
                    <Grid sx={{ display: "flex", alignItems: "center", gap: "5px" }} item xs={12} md={6}>
                        {
                            fileURL &&
                            <React.Fragment>
                                <img src={success} />
                                <Typography color='textColor' variant='body1' sx={{ textAlign: "left" }}>Upload Successful</Typography>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color='textColorR' variant='body2' sx={{ fontWeight: "bold", textAlign: "left" }}>The uploaded file should be PDF should not exceed 5MB</Typography>
                    </Grid>
                </Grid>
            }
            <div style={{ padding: "20px 0px 0px", display: "flex", flexDirection: "row", justifyContent: `${!matches ? "space-around" : "space-between"}` }}>
                {matches && <CButton handleClick={() => handleBack()} name="Back" />}
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    disabled={disabledButton}
                    variant="contained">
                    Save & Submit
                </Button>
            </div>
        </form >
    )
}