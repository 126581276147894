import { useTheme } from '@emotion/react';
import { InputLabel, Typography, useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function CInput(props) {

    const { type, label, disabled, name, value, defaultValue, helperText, isError, onChange, placeholder, isOther, maxLength, autoFocus, isEval, isBold, alignCenter, onBlur } = props;
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    function isValidFunction(event) {
        let value = event.target.value
        if (maxLength && +maxLength < +value?.length)
            return;
        onChange(value, event)
    }

      // Apply conditional styling for center alignment and isEval
    const containerStyle = {
        display: "flex", 
        flexDirection: "column", 
        alignItems: alignCenter ? "center" : "flex-start",
        width: isEval ? "100%" : "-webkit-fill-available",
        justifyContent: alignCenter ? "center" : ""
    };
    // <div style={isEval ? { display: "flex", flexDirection: "column", alignItems: "flex-start"} : { display: "flex", flexDirection: "column", alignItems: "flex-start", width: "-webkit-fill-available" }}></div>

    const textStyle = {
        fontSize: '18px',
        textAlign: alignCenter ? "center" : "left"
    };



    return (
        <div style={containerStyle}>
            <InputLabel htmlFor="input-with-icon-adornment">
                <Typography  color="inputLabelColor" sx={{fontSize: matches ? "14px" : "16px", wordBreak: "break-all", fontWeight: "bold", fontFamily: "lato" }} align={alignCenter ? 'center' : 'left'} variant="inputLabel">
                    {label}
                </Typography>
            </InputLabel>
            <TextField
                fullWidth
                type={type}
                size="small"
                error={isError}
                onChange={isValidFunction}
                disabled={disabled}
                style={{ fontSize: '18px', textAlign: "left" }}
                id="outlined-error-helper-text"
                name={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                value={value}
                inputProps={{ style: {fontWeight:isBold?"bold":"", fontSize: "16px" } }} // font size of input text
                helperText={isError ? helperText : null}
                color={isOther ? "warning" : ''}
                focused={isOther ? true : ''}
                autoFocus={autoFocus}
                onBlur={onBlur}
                onWheel={(e) => e.target.blur()}
            />
        </div>
    )
}
