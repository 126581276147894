import { httpservice } from './httpService'

/**
 * OTP Send
 */
export function sendOTP(phone) {
    return httpservice()
        .post(`/api/client/auth/sendOtp/${phone}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

/**
 * Verify mobile
 */
export function verifyMobile(phone) {
    return httpservice()
        .post(`/api/client/verifyMobile/${phone}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

/**
 * Verify OTP
 */
export function verifyOTP(otpData) {
    return httpservice()
        .post(`/api/client/auth/verifyOtp/`, otpData)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

/**
 * Sign UP
 */
export function signUP(signUpData) {
    return httpservice()
        .post(`/api/client/verifyUser/${signUpData?.mobileNumber}/${signUpData?.pin}`, signUpData)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}
/**
 * Pin Verify
 */
export function verifyPin(pin) {
    return httpservice()
        .post(`/api/client/verifyPin/${pin}`, )
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

//**SignUP OTP Verification */

/**
 * Sign UP
 */
export function signUPOTP(signUpData) {
    return httpservice()
        .post('/api/client/signup', signUpData)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

/**
 * Pin Verify
 */
export function verifyPanExtensive(obj) {
    return httpservice()
        .post(`/api/pan`, obj)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

/**
 * Equifax Soft pull
 */
export function equifaxSoftPull(panDetailId) {
    return httpservice()
        .get(`api/client/equifax/soft/${panDetailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}


/**
 * Eligibility Check
 */
export function loanEligibilityCheck(obj) {
    return httpservice()
        .post(`/api/userData/userLoanEligibilityCheck`, obj)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

/**
 * consent Check
 */
export function userConsent(obj) {
    return httpservice()
        .post(`/api/userData/userConsent`, obj)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}



/**
 * Admin Login
 */
 export function adminLoginAPI(obj) {
    return httpservice()
        .post('/api/client/auth/adminLogin/', obj)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

/**
 * Admin Login
 */
 export function OTPForgotPassword(mobileNumber,userName) {
    return httpservice()
        .post(`api/client/auth/forgotPassword/${mobileNumber}/${userName}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

/**
 * Admin Login
 */
 export function resetPassword(obj) {
    return httpservice()
        .put(`/api/client/auth/updateUserPassword?mobileNumber=${obj?.mobileNumber}&password=${obj?.password}`,)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })
}

