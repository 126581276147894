import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

export default function CSelectBox(props) {
    const { label, placeholder, optionList, onChange, name, defaultValue, disable,isMinWidth } = props;
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const handleChange = (event) => {
        onChange(event.target.value);
    };
    return (
        <div style={isMinWidth ? { display: "flex", flexDirection: "column", alignItems: "flex-start"} : { display: "flex", flexDirection: "column", alignItems: "flex-start", width: "-webkit-fill-available" }}>
            <InputLabel htmlFor="input-with-icon-adornment">
                <Typography fontSize={matches ? "14px" : "10px"} color="inputLabelColor" sx={{ fontWeight: "bold", fontFamily: "lato" }} align='left' variant="inputLabel">
                    {label}
                </Typography>
            </InputLabel>
            <FormControl fullWidth>
                <Select
                    size="small"
                    placeholder={placeholder}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disabled={disable}
                    value={defaultValue}
                    onChange={handleChange}
                    style={{ fontSize: '18px', textAlign: "left" }}
                >
                    {
                        optionList && optionList.length ?
                            optionList.map((menuItem, index) => {
                                return (
                                    <MenuItem id="menu-age" value={menuItem.value}
                                        key={index}>{menuItem.name}</MenuItem>
                                )
                            }) : null
                    }
                </Select>
            </FormControl>
        </div>
    );
}
