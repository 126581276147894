import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import Slide from '@mui/material/Slide';
import CSelectBox from '../utils/CSelectBox';
import { Grid, TextField, useTheme } from '@mui/material';
import { getInstituteTypesAdmin, getInstituteSchemeAdmin, getInstituteNameAdmin, getInstituteCourseAdmin, getInstituteAddressAdmin, getBlackListedPinsAdmin, deleteInstituteType, deleteInstituteName, deleteInstituteAddress, deleteInstituteCourse, deleteInstituteScheme, deleteDropDownValue, deleteBlackListedPin, deleteFcp, getFcp, getFcpDropDown } from '../../services/AdminServices';
import { useEffect } from 'react';
import MasterDataTable from './MasterDataTable';
import AddIcon from '@mui/icons-material/Add';
import AddNewInstituteType from './forms/AddNewInstituteType';
import AddNewInstituteName from './forms/AddNewInstituteName';
import AddNewInstituteCourse from './forms/AddInstituteCourse';
import AddInstituteAddress from './forms/AddInstituteAddress';
import AddInstituteScheme from './forms/AddInstituteScheme';
import { getDropDownData } from '../../actions/getDropDownData';
import AddNewDropDownData from './forms/AddNewDropDownData';
import CDialogue from '../utils/CDialogue';
import AddNewBlackListedPin from './forms/AddBlackListedPin';
import CInput from '../utils/CInput';
import AddFcp from './forms/AddFcp';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MasterData(props) {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { open, setOpen } = props
    const [selectedType, setSelectedType] = React.useState(0)
    const [instituteName, setInstituteNames] = React.useState([])
    const [instituteType, setInstituteType] = React.useState([])
    const [instituteScheme, setInstituteScheme] = React.useState([])
    const [instituteCourse, setInstituteCourse] = React.useState([])
    const [instituteAddress, setInstituteAddress] = React.useState([])
    const [relationshipData, setRelationshipData] = React.useState([])
    const [maritalStatusData, setMaritalStatusData] = React.useState([])
    const [employementTypeData, setEmployementTypeData] = React.useState([])
    const [residentialStatusData, setResidentialStatusData] = React.useState([])
    const [blackListedPin, setBlackListedPin] = React.useState([])
    const [fcp, setFcp] = React.useState([])
    const [fcpDropdown, setFcpDropdown] = React.useState([])
    const dropDownData = useSelector((state) => state?.getDropDownData?.data);
    const [selectedRow, setSelectedRow] = React.useState([])
    const [isAddNew, setIsAddNew] = React.useState(false)
    const [header, setHeader] = React.useState([])
    const [rows, setRows] = React.useState([])
    const [originalrows, setOriginalRows] = React.useState([])

    const [deleteSelectedId, setDeleteSelectedId] = React.useState('');
    const [deleteRow, setDeleteRow] = React.useState('');
    const [confirmModel, setConfirmModel] = React.useState(false)


    //Pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        (async () => {
            const address = await getInstituteAddressAdmin();
            setInstituteAddress(address);
            const type = await getInstituteTypesAdmin();
            setInstituteType(type);
            const course = await getInstituteCourseAdmin();
            setInstituteCourse(course);
            const name = await getInstituteNameAdmin();
            setInstituteNames(name);
            const scheme = await getInstituteSchemeAdmin();
            setInstituteScheme(scheme);
            const pins = await getBlackListedPinsAdmin();
            setBlackListedPin(pins);
            const fcps = await getFcp();
            setFcp(fcps);
            const fcpDropDown = await getFcpDropDown();
            setFcpDropdown(fcpDropDown)
            const allDropDown = dispatch(getDropDownData())
        })();
    }, [])

    useEffect(() => {
        setRelationshipData()
        if (Object.keys(dropDownData)?.length) {
            dropDownData?.forEach((obj) => {
                // relattionship Data
                if (obj.keyDesc === 'RELATIONSHIP_WITH_APPLICANT')
                    setRelationshipData(obj.valueData);

                // maritial data
                if (obj.keyDesc === 'MARITAL_STATUS')
                    setMaritalStatusData(obj.valueData);

                //employement type
                if (obj.keyDesc === 'EMPLOYMENT_TYPE')
                    setEmployementTypeData(obj.valueData);

                //residential status
                if (obj.keyDesc === 'RESIDENTIAL_STATUS')
                    setResidentialStatusData(obj.valueData);
            })
        }
    }, [dropDownData])

    function createData(id, name, instituteTypeCreatedTime) {
        if (instituteTypeCreatedTime)
            instituteTypeCreatedTime = format(new Date(instituteTypeCreatedTime), "yyyy-MM-dd HH:mm:ss")
        return { id, name, instituteTypeCreatedTime };
    }

    function createDataInstituteScheme(id, iName, iType, iCourse, iAddress, tenure, roi, subvention, pf, scheme, tranche, details, emi, disabledScheme, nameId, typeId, addressID, courseId, schemeCreatedTime, schemeMoratorium) {
        if (schemeCreatedTime)
            schemeCreatedTime = format(new Date(schemeCreatedTime), "yyyy-MM-dd HH:mm:ss");
        if (tranche === "false") {
            details = "NA"
        }
        return { id, iName, iType, iCourse, iAddress, tenure, roi, subvention, pf, instituteCourse, scheme, tranche, details, emi, disabledScheme, nameId, typeId, addressID, courseId, schemeCreatedTime,schemeMoratorium };
    }

    function createDataInstituteName(id, name, type, instituteCreatedTime) {
        if (instituteCreatedTime)
            instituteCreatedTime = format(new Date(instituteCreatedTime), "yyyy-MM-dd HH:mm:ss");
        return { id, name, type, instituteCreatedTime };
    }

    function createDataInstituteCourse(id, name, fees, duration, isOther, adddress, nameId, typeId, instituteCouserCreatedTime, addressId, disabledCourse) {
        if (instituteCouserCreatedTime)
            instituteCouserCreatedTime = format(new Date(instituteCouserCreatedTime), "yyyy-MM-dd HH:mm:ss");
        return { id, name, fees, duration, isOther, adddress, typeId, nameId, instituteCouserCreatedTime, addressId, disabledCourse };
    }
    function createDataInstituteAddress(id, adddress, IName, IType, bAccount, state, pNo, city, pin, instituteContactPersonName, instituteEmailId, instituteIfscCode,  instituteBankBranch, beneficiaryName, instituteBankName, instituteRiskCode, instituteFcpId, instituteGst, isOtherAddress, nameId, typeId, instituteAddressCreatedTime, interestBourneByInstitute, byepass, instituteByepassUrl) {
        if (instituteAddressCreatedTime)
            instituteAddressCreatedTime = format(new Date(instituteAddressCreatedTime), "yyyy-MM-dd HH:mm:ss");
        return { id, adddress, IName, IType, bAccount, state, pNo, city, pin, instituteContactPersonName, instituteEmailId, instituteIfscCode, instituteBankBranch, beneficiaryName, instituteBankName, instituteRiskCode, instituteFcpId, instituteGst, isOtherAddress, nameId, typeId, instituteAddressCreatedTime, interestBourneByInstitute, byepass, instituteByepassUrl };
    }

    function createRelationship(valueid, valuedesc, lastUpdated) {
        if (lastUpdated)
            lastUpdated = format(new Date(lastUpdated), "yyyy-MM-dd HH:mm:ss");
        return { valueid, valuedesc, lastUpdated };
    }

    function createMaritalStatus(valueid, valuedesc, lastUpdated) {
        if (lastUpdated)
            lastUpdated = format(new Date(lastUpdated), "yyyy-MM-dd HH:mm:ss");
        return { valueid, valuedesc, lastUpdated };
    }

    function createEmploymentType(valueid, valuedesc, lastUpdated) {
        if (lastUpdated)
            lastUpdated = format(new Date(lastUpdated), "yyyy-MM-dd HH:mm:ss");
        return { valueid, valuedesc, lastUpdated };
    }

    function createResidentialStatus(valueid, valuedesc, lastUpdated) {
        if (lastUpdated)
            lastUpdated = format(new Date(lastUpdated), "yyyy-MM-dd HH:mm:ss");
        return { valueid, valuedesc, lastUpdated };
    }

    function createBlackListedPin(id, pin, lastUpdatedTIme) {
        if (lastUpdatedTIme)
            lastUpdatedTIme = format(new Date(lastUpdatedTIme), "yyyy-MM-dd HH:mm:ss");

        return { id, pin, lastUpdatedTIme };
    }

    function createFcp(fcpId, name, fcpPhoneNumber, fcpEmail,fcpComponyName, fcpCity, fcpNumber, fcpBankBeneficiaryName, fcpBankIfsc,
        fcpbankAccountNumber,fcpReferralCommissionPercent, fcpReferralCommissionRate, fcpAgreementUrl, link) {
            return {fcpId, name, fcpPhoneNumber, fcpEmail,fcpComponyName, fcpCity, fcpNumber, fcpBankBeneficiaryName, fcpBankIfsc,
                fcpbankAccountNumber,fcpReferralCommissionPercent, fcpReferralCommissionRate, fcpAgreementUrl, link}
        }

    useEffect(() => {
        if (selectedType === 0) {
            const tempRows = []
            instituteType && instituteType?.map(type => {
                tempRows.push(createData(type?.instituteTypeId, type?.instituteTypeName, type?.instituteTypeAddedTime))
            })
            setRows(tempRows)
            setOriginalRows(tempRows)
            const columns = [
                { id: 'id', label: 'Id', maxWidth: 10 },
                { id: 'name', label: 'Name', minWidth: 100 },
                { id: 'instituteTypeCreatedTime', label: 'Last Updated', minWidth: 100 },
            ]
            setHeader(columns)
        }

        if (selectedType === 1) {
            const tempRows = []
            instituteScheme?.map(type => {
                tempRows.push(createDataInstituteScheme(type?.schemeId,
                    type?.instituteCourseMaster?.instituteAddressMaster?.instituteNameMaster?.instituteName,
                    type?.instituteCourseMaster?.instituteAddressMaster?.instituteNameMaster?.instituteTypeMaster?.instituteTypeName,
                    type?.instituteCourseMaster?.instituteCourses,
                    type?.instituteCourseMaster?.instituteAddressMaster?.instituteAddress, type?.schemeTenure, type?.schemeRoi, type?.schemeSubvention,
                    type?.schemePf, type?.otherScheme ? "true" : "false", type?.tranche ? "true" : "false", JSON.stringify(type?.tranceDetails), type?.numberOfAdvanceEmis,
                    type?.disabledScheme ? "true" : "false",
                    type?.instituteCourseMaster?.instituteAddressMaster?.instituteNameMaster?.instituteId,
                    type?.instituteCourseMaster?.instituteAddressMaster?.instituteNameMaster?.instituteTypeMaster?.instituteTypeId,
                    type?.instituteCourseMaster?.instituteAddressMaster?.instituteAddressId, type?.instituteCourseMaster?.instituteCourseId, type?.schemeCreatedTime, type?.schemeMoratorium))
            })
            setRows(tempRows)
            setOriginalRows(tempRows)
            const columns = [
                { id: 'id', label: 'Id', maxWidth: 10 },
                { id: 'iName', label: 'Institute Name', minWidth: 100 },
                { id: 'iType', label: 'Institute Type', minWidth: 100 },
                { id: 'iCourse', label: 'Institute Course', minWidth: 100 },
                { id: 'iAddress', label: 'Institute Address', minWidth: 100 },
                { id: 'tenure', label: 'Scheme Tenure', maxWidth: 10 },
                { id: 'roi', label: 'Scheme ROI', maxWidth: 10 },
                { id: 'subvention', label: 'Scheme Subvention', maxWidth: 10 },
                { id: 'pf', label: 'Scheme PF', maxWidth: 10 },
                { id: 'scheme', label: 'Is Other Scheme', maxWidth: 10 },
                { id: 'schemeMoratorium', label: 'Moratorium', maxWidth: 10 },
                { id: 'tranche', label: 'Is Tranche', maxWidth: 10 },
                { id: 'details', label: 'Tranche Details', minWidth: 100 },
                { id: 'emi', label: 'Number Of Advance EMI', maxWidth: 10 },
                { id: 'disabledScheme', label: 'Disabled Scheme', minWidth: 100 },
                { id: 'schemeCreatedTime', label: 'Last Updated', minWidth: 100 },
                
            ]
            setHeader(columns)
        }

        if (selectedType === 2) {
            const tempRows = []
            instituteName?.map(type => {
                tempRows.push(createDataInstituteName(type?.instituteId, type?.instituteName, type?.instituteTypeMaster?.instituteTypeName, type?.instituteCreatedTime))
            })
            setRows(tempRows)
            setOriginalRows(tempRows)
            const columns = [
                { id: 'id', label: 'Id', maxWidth: 10 },
                { id: 'name', label: 'Institute Name', minWidth: 100 },
                { id: 'type', label: 'Institute Type Name', minWidth: 100 },
                { id: 'instituteCreatedTime', label: 'Last Updated', minWidth: 100 },
            ]
            setHeader(columns)
        }
        if (selectedType === 3) {
            const tempRows = []
            instituteCourse?.map(type => {
                tempRows.push(createDataInstituteCourse(type?.instituteCourseId, type?.instituteCourses, type?.instituteCoursesFees, type?.courseDuration, type?.isOtherCourse, type?.instituteAddressMaster?.instituteAddress, type?.instituteAddressMaster?.instituteNameMaster?.instituteId, type?.instituteAddressMaster?.instituteNameMaster?.instituteTypeMaster?.instituteTypeId, type?.instituteCouserCreatedTime, type?.instituteAddressMaster?.instituteAddressId, type?.disabledCourse ? "true" : "false",))
            })
            setRows(tempRows)
            setOriginalRows(tempRows)
            const columns = [
                { id: 'id', label: 'Id', maxWidth: 10 },
                { id: 'name', label: 'Course Name', minWidth: 100 },
                { id: 'fees', label: 'Course Fees', minWidth: 100 },
                { id: 'duration', label: 'Course Duration', maxWidth: 10 },
                // { id: 'isOther', label: 'Other Course', minWidth: 100 },
                { id: 'adddress', label: 'Institute Address', minWidth: 100 },
                { id: 'disabledCourse', label: 'Disabled Course', minWidth:100},
                { id: 'instituteCouserCreatedTime', label: 'Last Updated', minWidth: 100 },

            ]

            setHeader(columns)
        }
        if (selectedType === 4) {
            const tempRows = []
            instituteAddress?.map(type => {
                tempRows.push(createDataInstituteAddress(type?.instituteAddressId, type?.instituteAddress, type?.instituteNameMaster?.instituteName, type?.instituteNameMaster?.instituteTypeMaster?.instituteTypeName
                    , type?.instituteBankAccount, type?.instituteState, type?.institutePhoneNumber, type?.instituteCity,
                    type?.institutePin, type?.instituteContactPersonName, type?.instituteEmailId, type?.instituteIfscCode, type?.instituteBankBranch, type?.beneficiaryName, type?.instituteBankName, type?.instituteRiskCode, type?.instituteFcpId, type?.instituteGst, type?.isOtherAddress, type?.instituteNameMaster?.instituteId, type?.instituteNameMaster?.instituteTypeMaster?.instituteTypeId, type?.instituteAddressCreatedTime, type?.interestBourneByInstitute ? "true" : "false" , type?.byepass, type?.instituteByepassUrl))
            })
            setRows(tempRows)
            setOriginalRows(tempRows)
            const columns = [
                { id: 'id', label: 'Id', maxWidth: 10 },
                { id: 'adddress', label: 'Institute Address', minWidth: 100 },
                { id: 'IName', label: 'Institute Name', minWidth: 100 },
                { id: 'IType', label: 'Institute Type', minWidth: 100 },
                { id: 'bAccount', label: 'Bank Account', minWidth: 100 },
                { id: 'state', label: 'Institute State', minWidth: 100 },
                { id: 'pNo', label: 'Institute phone number', minWidth: 100 },
                { id: 'city', label: 'Institute City', minWidth: 100 },
                { id: 'pin', label: 'Institute Pin', minWidth: 100 },
                { id: 'instituteContactPersonName', label: 'Institute Contact Name', minWidth: 100 },
                { id: 'instituteEmailId', label: 'Institute Email', minWidth: 100 },
                { id: 'instituteIfscCode', label: 'Institute IFSC Code', minWidth: 100 },
                { id: 'instituteBankName', label: 'Institute Bank Name', minWidth: 100 },
                { id: 'instituteBankBranch', label: 'Institute Bank Branch', minWidth: 100 },
                { id: 'beneficiaryName', label: 'Institute Bank Beneficiary Name', minWidth: 100 },
                { id: 'instituteRiskCode', label: 'Institute Risk Code', minWidth: 100 },
                { id: 'instituteFcpId', label: 'Institute Fcp Id', minWidth: 100 },
                { id: 'instituteGst', label: 'Institute GST', minWidth: 100 },
                { id: 'interestBourneByInstitute', label: 'Interest Bourse By Institute', minWidth: 100},
                // { id: 'isOtherAddress', label: 'Is Other Address', maxWidth: 10 },
                { id: 'instituteAddressCreatedTime', label: 'Last Updated', minWidth: 100 },
                { id: 'byepass', label: 'Bye Pass', minWidth: 100 },
                { id: 'instituteByepassUrl', label: 'Bye Pass Url', minWidth: 100 },
            ]

            setHeader(columns)
        }

        if (selectedType === 5) {
            const tempRows = []
            relationshipData?.map(type => {
                tempRows.push(createRelationship(type?.valueid, type?.valuedesc, type?.lastUpdated))
            })
            setRows(tempRows)
            setOriginalRows(tempRows)
            const columns = [
                { id: 'valueid', label: 'Id', minWidth: 170 },
                { id: 'valuedesc', label: 'Relationship Type', minWidth: 100 },
                { id: 'lastUpdated', label: 'Last Updated', minWidth: 100 },
            ]

            setHeader(columns)
        }
        if (selectedType === 6) {
            const tempRows = []
            maritalStatusData?.map(type => {
                tempRows.push(createMaritalStatus(type?.valueid, type?.valuedesc, type?.lastUpdated))
            })
            setRows(tempRows)
            setOriginalRows(tempRows)
            const columns = [
                { id: 'valueid', label: 'Id', minWidth: 170 },
                { id: 'valuedesc', label: 'Marital Status', minWidth: 100 },
                { id: 'lastUpdated', label: 'Last Updated', minWidth: 100 },
            ]

            setHeader(columns)
        }
        if (selectedType === 7) {
            const tempRows = []
            employementTypeData?.map(type => {
                tempRows.push(createEmploymentType(type?.valueid, type?.valuedesc, type?.lastUpdated))
            })
            setRows(tempRows)
            setOriginalRows(tempRows)
            const columns = [
                { id: 'valueid', label: 'Id', minWidth: 170 },
                { id: 'valuedesc', label: 'Employement Type', minWidth: 100 },
                { id: 'lastUpdated', label: 'Last Updated', minWidth: 100 },
            ]

            setHeader(columns)
        }

        if (selectedType === 8) {
            const tempRows = []
            residentialStatusData?.map(type => {
                tempRows.push(createResidentialStatus(type?.valueid, type?.valuedesc, type?.lastUpdated))
            })
            setRows(tempRows)
            setOriginalRows(tempRows)
            const columns = [
                { id: 'valueid', label: 'Id', minWidth: 170 },
                { id: 'valuedesc', label: 'Residential Status', minWidth: 100 },
                { id: 'lastUpdated', label: 'Last Updated', minWidth: 100 },
            ]

            setHeader(columns)
        }

        if (selectedType === 9) {
            const tempRows = []
            blackListedPin?.map(type => {
                tempRows.push(createBlackListedPin(type?.id, type?.pin, type?.lastUpdatedTIme))
            })
            setRows(tempRows)
            setOriginalRows(tempRows)
            const columns = [
                { id: 'id', label: 'Id', minWidth: 170 },
                { id: 'pin', label: 'Pin Code', minWidth: 100 },
                { id: 'lastUpdatedTIme', label: 'Last Updated', minWidth: 100 },
            ]

            setHeader(columns)
        }

        if (selectedType === 10) {
            const tempRows = []
            fcp?.map(type => {
                tempRows.push(createFcp(type?.fcpId, type?.name, type?.fcpPhoneNumber, type?.fcpEmail,type?.fcpComponyName, type?.fcpCity, type?.fcpNumber, type?.fcpBankBeneficiaryName, type?.fcpBankIfsc,
                    type?.fcpbankAccountNumber,type?.fcpReferralCommissionPercent, type?.fcpReferralCommissionRate, type?.fcpAgreementUrl, type?.link))
            })
            setRows(tempRows)
            setOriginalRows(tempRows)
            const columns = [
                { id: 'fcpId', label: 'Fcp Id', minWidth: 80 },
                { id: 'name', label: 'Name', minWidth: 80 },
                { id: 'fcpPhoneNumber', label: 'Phone', minWidth: 100 },
                { id: 'fcpEmail', label: 'Email', minWidth: 100 },
                { id: 'fcpComponyName', label: 'Company Name', minWidth: 100 },
                { id: 'fcpCity', label: 'City', minWidth: 100 }, 
                { id: 'fcpNumber', label: 'FCP#', minWidth: 100 },
                { id: 'fcpBankBeneficiaryName', label: 'Beneficiary Name', minWidth: 100 },
                { id: 'fcpBankIfsc', label: 'Ifsc', minWidth: 100 },
                { id: 'fcpbankAccountNumber', label: 'Account Number', minWidth: 100 },
                { id: 'fcpReferralCommissionPercent', label: 'Referral Commision', minWidth: 100 },
                { id: 'fcpReferralCommissionRate', label: 'Referral Commision Flat Rate', minWidth: 100 },
                { id: 'fcpAgreementUrl', label: 'Agreement Url', minWidth: 100 },
                { id: 'link', label: 'Link', minWidth: 80 },
            ]

            setHeader(columns)
        }

    }, [selectedType, instituteType, instituteScheme, instituteName, instituteCourse, instituteAddress, relationshipData, maritalStatusData, employementTypeData, residentialStatusData, blackListedPin, fcp])

    async function reFetch(value) {
        if (value === 0) {
            const type = await getInstituteTypesAdmin();
            setInstituteType([...type]);
        }
        if (value === 1) {
            const scheme = await getInstituteSchemeAdmin();
            setInstituteScheme([...scheme]);
        }
        if (value === 2) {
            const name = await getInstituteNameAdmin();
            setInstituteNames([...name]);
        }
        if (value === 3) {
            const course = await getInstituteCourseAdmin();
            setInstituteCourse([...course]);
        }
        if (value === 4) {
            const address = await getInstituteAddressAdmin();
            setInstituteAddress([...address]);
        }
        if (value === 9) {
            const pins = await getBlackListedPinsAdmin();
            setBlackListedPin([...pins]);
        }
        if (value === 10) {
            const fcps = await getFcp();
            setFcp([...fcps]);
        }
        else {
            dispatch(getDropDownData())
        }
    }

    function onEdit(selectedType, row) {
        setIsAddNew(true)
        setSelectedRow(row)
        reFetch(selectedType)
    }

    async function onDelete() {
        switch (deleteSelectedId) {
            case 0: {
                //delete Institute Type
                await deleteInstituteType(deleteRow?.id)
                break;
            }

            case 1: {
                //delete Institute Scheme
                await deleteInstituteScheme(deleteRow?.id)
                break;
            }
            case 2: {
                //delete Institute Name
                await deleteInstituteName(deleteRow?.id)
                break;
            }
            case 3: {
                // delete Institute Course
                await deleteInstituteCourse(deleteRow?.id)
                break;
            }
            case 4: {
                // delete Institute Address
                await deleteInstituteAddress(deleteRow?.id)
                break;
            }

            case 9: {
                // delete Black Listed Pins
                await deleteBlackListedPin(deleteRow?.id)
                break;
            }

            case 10: {
                // delete Black Listed Pins
                await deleteFcp(deleteRow?.fcpId)
                break;
            }
            // delete Relationship Data
            case 5:
            // delete Marital Status
            case 6:
            // delete Employement Type
            case 7:
            // delete Residential Status
            case 8: {
                // delete Residential Status
                await deleteDropDownValue(deleteRow?.valueid)
                break;
            }

        }
        reFetch(deleteSelectedId)
    }
    const handleClose = () => {
        setOpen(false);
        setSelectedRow([])
    };

    function handleCloseForm() {
        setIsAddNew(false);
        setSelectedRow([])
    }
    function handleChange(value) {
        setSelectedType(value)
        setRowsPerPage(10);
        setPage(0)
    }

    function onAddNew() {
        setIsAddNew(true)
    }


    function confirmAction(selectedType, rowData) {
        setConfirmModel(true)
        setDeleteSelectedId(selectedType)
        setDeleteRow(rowData)
    }

    const requestSearch = (searchedVal) => {
        if (searchedVal === null || searchedVal === undefined || searchedVal === "")
            setRows(originalrows)
        else {
            if (selectedType === 0) {

                const filteredRows = rows.filter((row) => {
                    return row.name.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setRows(filteredRows)
            } else if (selectedType === 1) {

                const filteredRows = rows.filter((row) => {
                    return row.iAddress?.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setRows(filteredRows)
            } else if (selectedType === 2) {

                const filteredRows = rows.filter((row) => {
                    return row.name.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setRows(filteredRows)
            } else if (selectedType === 3) {

                const filteredRows = rows.filter((row) => {
                    return row.name.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setRows(filteredRows)
            } else if (selectedType === 4) {

                const filteredRows = rows.filter((row) => {
                    return row.adddress.toLowerCase().includes(searchedVal.toLowerCase()) || row.IName.toLowerCase().includes(searchedVal.toLowerCase())
                    || row.city.toLowerCase().includes(searchedVal.toLowerCase()) || row.pin.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setRows(filteredRows)
            } else if (selectedType === 5) {

                const filteredRows = rows.filter((row) => {
                    return row.valuedesc.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setRows(filteredRows)
            } else if (selectedType === 6) {

                const filteredRows = rows.filter((row) => {
                    return row.valuedesc.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setRows(filteredRows)
            } else if (selectedType === 7) {

                const filteredRows = rows.filter((row) => {
                    return row.valuedesc.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setRows(filteredRows)
            } else if (selectedType === 8) {

                const filteredRows = rows.filter((row) => {
                    return row.valuedesc.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setRows(filteredRows)
            } else if (selectedType === 9) {

                const filteredRows = rows.filter((row) => {
                    return row.pin.toLowerCase().includes(searchedVal.toLowerCase());
                });
                setRows(filteredRows)
            }

        }
    }

    const selectBoxData = [{ name: "Institute Type", value: 0 }, { name: "Institute Name", value: 2 }, { name: "Institute Address", value: 4 },
    { name: "Institute Course", value: 3 }, { name: "Institute Scheme", value: 1 },
    { name: "Relationship With Applicant", value: 5 }, { name: "Marital Status", value: 6 }, { name: "Employment Type", value: 7 }, { name: "Residential Status", value: 8 }, { name: "Black Listed Pins", value: 9 },{ name: "FCP", value: 10 }]
    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ backgroundColor: theme.palette.headerColor, position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container sx={{ p: 4 }} spacing={3}>
                    <Grid item xs={3}>
                        <CSelectBox defaultValue={selectedType}
                            onChange={handleChange}
                            optionList={selectBoxData} />
                    </Grid>
                    <Grid item xs={3}>
                        <AddIcon sx={{ pt: 1, cursor: "pointer" }} onClick={onAddNew} fontSize="large" />
                    </Grid>
                    <Grid item xs={4} justifyContent="flex-end">
                        <TextField
                            size="small"
                            style={{ fontSize: '18px', textAlign: "left" }}
                            placeholder='search...'
                            type='search'
                            onInput={(e) => requestSearch(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid sx={12}>
                    <MasterDataTable selectedType={selectedType} onEdit={onEdit} onDelete={confirmAction} rows={rows} setRows={setRows} columns={header}
                        page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                </Grid>
            </Dialog>
            {selectedType === 0 ?
                <AddNewInstituteType selectedRow={selectedRow} open={isAddNew} handleCloseForm={handleCloseForm} reFetch={reFetch} setOpen={setIsAddNew} /> : null}
            {selectedType === 1 ?
                <AddInstituteScheme selectedRow={selectedRow} instituteType={instituteType} instituteName={instituteName} instituteAddress={instituteAddress} instituteCourse={instituteCourse} open={isAddNew} handleCloseForm={handleCloseForm} reFetch={reFetch} setOpen={setIsAddNew} /> : null}
            {selectedType === 2 ?
                <AddNewInstituteName selectedRow={selectedRow} instituteType={instituteType} open={isAddNew} handleCloseForm={handleCloseForm} reFetch={reFetch} setOpen={setIsAddNew} /> : null}
            {selectedType === 3 ?
                <AddNewInstituteCourse selectedRow={selectedRow} instituteType={instituteType} instituteName={instituteName} reFetch={reFetch} instituteAddress={instituteAddress} open={isAddNew} setOpen={setIsAddNew} handleCloseForm={handleCloseForm} /> : null}
            {selectedType === 4 ?
                <AddInstituteAddress reFetch={reFetch} selectedRow={selectedRow} instituteType={instituteType} instituteName={instituteName} fcps={fcpDropdown} open={isAddNew} setOpen={setIsAddNew} handleCloseForm={handleCloseForm} /> : null}
            {selectedType === 5 || selectedType === 6 || selectedType === 7 || selectedType === 8 ?
                <AddNewDropDownData reFetch={reFetch} dropDownData={dropDownData} selectedType={selectedType} selectedRow={selectedRow} open={isAddNew} setOpen={setIsAddNew} handleCloseForm={handleCloseForm} /> : null}
            {selectedType === 9 ?
                <AddNewBlackListedPin reFetch={reFetch} selectedType={selectedType} selectedRow={selectedRow} open={isAddNew} setOpen={setIsAddNew} handleCloseForm={handleCloseForm} /> : null}
             {selectedType === 10 ?
                <AddFcp reFetch={reFetch} selectedType={selectedType} selectedRow={selectedRow} open={isAddNew} setOpen={setIsAddNew} handleCloseForm={handleCloseForm} /> : null}
            <CDialogue open={confirmModel} message={"Are you sure you want to Delete Entry"} setOpen={setConfirmModel} isConfirm={onDelete} />

        </div>
    );
}
