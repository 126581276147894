import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}`;
}


export default function CSlider(props) {
  const { limit, setLimit } = props

  function changeValue(e) {
    setLimit(e.target.value)
  }

  return (
    <Box sx={{ width: 300 }}>
      <Slider
        aria-label="Temperature"
        defaultValue={limit}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={1}
        marks
        min={1}
        onChange={changeValue}
        max={13}
      />
    </Box>
  );
}
