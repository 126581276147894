
import { getInstituteCourseService } from '../services/applicationFormService'

export const getInstituteCourse = (value) => dispatch => {
    dispatch({
        payload: [],
        type: 'GET_INSTITUTE_COURSE_PENDING'
    })
    getInstituteCourseService(value).then((res) => {
        dispatch({
            payload: {
                data: res
            },
            type: 'GET_INSTITUTE_COURSE_SUCCESS'
        })
    }).catch((err) => {
        dispatch({
            payload: {
                data: {
                    data: []
                },
                error: err
            },
            type: 'GET_INSTITUTE_COURSE_ERROR'
        })
    })

}