import { httpservice } from './httpService'

/**
 * Face Verification
 */
export function faceVerify(userImageUrl, aadharImageUrl,detailId) {
    return httpservice()
        .post(`/api/faceMatch?userImageUrl=${userImageUrl}&aadharImageUrl=${aadharImageUrl}&detailId=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Delete File Verification
 */
export function deleteFile(userImageUrl) {
    return httpservice()
        .delete(`/api/storage/deleteFile?userImageUrl=${userImageUrl}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Save Face Data
 */
export function saveFaceData(faceObj) {
    return httpservice()
        .put(`/api/userData/faceMatch`, faceObj)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Save Face Data
 */
export function getFileImage(obj) {
    return httpservice()
        .post(`/api/storage/getFile?fileName=${obj?.fileName}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Save Sales Id
 */
 export function saveSalesId(obj) {
    return httpservice()
        .put(`/api/userData/salesPerson`,obj)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

