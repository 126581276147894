import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Smiley from "../../assets/images/smiley.svg"
import { Typography } from '@mui/material';
import CButton from '../utils/CButton';

export default function ErrorModal(props) {
    const { open, setOpen, isTechnicalError } = props
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="responsive-dialog-title"
            maxWidth="md"
            maxHeight="100px"
        >
            <DialogContent sx={{ p: matches ? 8 : 2 }} >
                <DialogContentText>
                    <div style={{ display: "flex", alignItems: "center", flexDirection: matches ? "row" : "column", gap: matches ? "20px" : "10px" }}>
                        <img width={matches ? "" : "100px"} height={matches ? "" : "50px"} src={Smiley} alt="signup" />
                        {isTechnicalError ? 
                        <Typography fontSize={matches ? "32px" : "15px"} color="textColorR" variant='subtitle1'>We are facing some technical difficulties, Please send "ELIGIBILITY_ERROR" to Whatsapp number +91 8655873308. We will validate your eligibility and share with you.</Typography>:
                        <Typography fontSize={matches ? "32px" : "15px"} color="textColorR" variant='subtitle1'>It’s us! we have not yet reached your location. </Typography>}
                        {!matches && <CButton handleClick={() => handleClose()} name="Close" />}
                    </div>
                    {matches && <div style={{ position: "absolute", bottom: "5px", left: matches ? "48%" : "40%", marginTop: "10px" }}>
                        <CButton handleClick={() => handleClose()} name="Close" />
                    </div>}
                </DialogContentText>
            </DialogContent>
        </Dialog>

    );
}
