import { useMediaQuery } from "@mui/material";
import React from "react";
import Webcam from "react-webcam";
import CButton from "../components/utils/CButton";

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };
  
  export const WebcamCapture = ({setCaptureImage, setOpenCamera}) => {
    const webcamRef = React.useRef(null);
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    function capture(e){
      e.preventDefault()
      webcamRef.current.getScreenshot();
      setCaptureImage(webcamRef.current.getScreenshot());
      setOpenCamera(false)
    }
    return (
      <>
        <Webcam
          audio={false}
          height={300}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={!matches?300:600}
          videoConstraints={videoConstraints}
        />
        <CButton handleClick={(e)=>capture(e)} name="Capture photo"/>
      </>
    );
  };