import { httpservice } from './httpService'

/**
 * Verify Pan
 */

export function verifyPan(panObj) {
    return httpservice()
        .post(`/api/verifyPan`, panObj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * generate Aadhaar OTP
 */
export function sendAadhaarOtp(aadhaar) {
    //let baseUrl = "http://localhost:3000/panAadharDetails"
    return httpservice()
        .post(`/api/okycOtp/${aadhaar}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Verify Aadhaar
 */
export function verifyAadhar(detailId, okycData) {
    //let baseUrl = "http://localhost:3000/panAadharDetails"
    return httpservice()
        .post(`/api/okycData/${detailId}`, okycData)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}



/**
 * Save PAN
 */
export function savePAN(panData) {
    return httpservice()
        .put(`/api/userData/pan`, panData)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}


/**
 * Get User Event
 */
export function getUserEvent(userDetailId) {
    return httpservice()
        .get(`/api/event/get?userDetailId=${userDetailId}`, )
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Save  Residential Data
 */
export function saveResidentialData(detailId, residentialData) {
    return httpservice()
        .put(`/api/userData/residentialStatus/${detailId}`,residentialData)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}
