import { createTheme } from '@mui/material/styles';
import themeObj from "../assets/jsx/theme";

/**
 * Custom hook to set multi theme.
 * @returns {{theme: any, muiTheme: Theme, changeTheme: React.Dispatch<React.SetStateAction<any>> | React.Dispatch<React.SetStateAction<undefined>>}}
 */
export default function useMultiTheme() {
  const theme = themeObj.default
  const muiTheme = createTheme(theme);
  return {
    theme,
    muiTheme,
  };
}
