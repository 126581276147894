import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CInput from "../../utils/CInput";
import CButton from "../../utils/CButton";
import { saveInstituteType } from '../../../services/AdminServices';
import CDialogue from '../../utils/CDialogue';

export default function AddNewInstituteType(props) {
  const { open, setOpen, reFetch, selectedRow, handleCloseForm } = props;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [instituteType, setInstituteType] = React.useState('')
  const [confirmModel, setConfirmModel] = React.useState(false)

  const handleClose = () => {
    setOpen(false);
    handleCloseForm();
  };

  React.useEffect(() => {
    const name = selectedRow?.name;
    const id = selectedRow?.id;
    setInstituteType(name)

  }, [selectedRow])

  // To Do: Data is hardcoded
  async function saveData() {
    const obj = {
      instituteTypeId: null,
      instituteNameId: null,
      instituteAddressId: null,
      instituteCourseId: null,
      instituteSchemeId: null,
      instituteTypeName: instituteType,
      instituteName: null,
      instituteAddressName: null,
      instituteCourseName: null,
      instituteCourseTenure: null,
      instituteCourseFee: null,
      createdBy: "8946992225",
      instituteAddressCity: null,
      instituteAddressPin: null,
      instituteAddressState: null,
      instituteAddressEMail: null,
      instituteAddressBankAccount: null,
      instituteAddressPhoneNumber: null,
      instituteAddressIfsc: null,
      instituteAddressContactPerosnName: null
    }
    if (selectedRow && selectedRow?.id) {
      obj.instituteTypeId = selectedRow?.id
    }
    const updatedData = await saveInstituteType(obj);
    handleClose()
    reFetch(0)
  }

  function confirmAction() {
    setConfirmModel(true)
  }
  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Add New Institute Type</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              pt: 2,
            }}
          >
            <CInput onChange={setInstituteType} value={instituteType} name="instituteType" label="Institute Type*" />
          </Box>
        </DialogContent>
        <DialogActions>
          <CButton disabled={!instituteType} handleClick={confirmAction} variant='contained' name="Add" />
        </DialogActions>
      </Dialog>
      <CDialogue open={confirmModel} message={selectedRow?.id ? "Are you Sure you want to Edit Institute Type" : "Are you Sure you want to Create new Institute Type"} setOpen={setConfirmModel} isConfirm={saveData} />
    </React.Fragment>
  );
}
