import * as React from 'react';
import { format, parse, lastDayOfMonth } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, PieChart, Pie } from 'recharts';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import '../assets/jsx/WelcomeStyle.css'
import "../assets/jsx/ApplicationForm.css";
import { useTheme } from "@mui/material/styles";
import FlyLogo from "../assets/images/flyhi_logo_full.svg"
import CMenu from '../components/utils/CMenu';
import { getPieChartData, getBarChartData, exportCSV, getConsentData, exportBreData } from '../services/AdminServices';
import { useEffect } from 'react';
import CSelectBox from '../components/utils/CSelectBox';
import CSlider from '../components/utils/CSlider';
import CButton from '../components/utils/CButton'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CssBaseline, Grid, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import "../assets/jsx/ApplicationForm.css";
import '../assets/jsx/WelcomeStyle.css';
import CInput from '../components/utils/CInput';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Analytics(props) {
    const theme = useTheme();
    const { open, setOpen } = props
    const [barChartData, setBarChartData] = React.useState([{}])
    const [pieChartData, setPieChartData] = React.useState([])
    const [limit, setLimit] = React.useState(5)
    const [month, setMonth] = React.useState(9)
    const [years, setYears] = React.useState((new Date()).getFullYear())
    const todayYear = (new Date()).getFullYear()
    const months = ["January", "February", "March;", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    const monthsData = [{ name: "January", value: "1" }, { name: "February", value: "2" },
    { name: "March", value: "3" }, { name: "April", value: "4" }, { name: "May", value: "5" },
    { name: "June", value: "6" }, { name: "July", value: "7" }, { name: "August", value: "8" },
    { name: "September", value: "9" }, { name: "October", value: "10" }, { name: "November", value: "11" }, { name: "December", value: "12" },
    ];
    // TO Do : Ayush Jain we Can not hardcode Year Data take curent year and minus 1 that's it 
    const YearData = [{ name: todayYear - 2, value: todayYear - 2 }, { name: todayYear - 1, value: todayYear - 1 },
    { name: todayYear, value: todayYear }, { name: todayYear + 1, value: todayYear + 1 }, { name: todayYear + 2, value: todayYear + 2 },
    ];

    useEffect(() => {
        (async () => {
            const obj = {
                limit: limit,
            }
            const data = await getBarChartData(obj);
            var processedBarData = []
            await data?.forEach((obj) => {
                const temp = {
                    'name': months[obj?.saleMonth - 1].concat(obj?.saleYear),
                    'sales': obj?.sales

                }
                processedBarData.push(temp);
            })
            setBarChartData(processedBarData)
        })();
    }, [limit])

    useEffect(() => {
        (async () => {
            const obj = {
                year: years,
                month: month
            }
            const data = await getPieChartData(obj);
            var processedPieData = []
            await data?.forEach((obj) => {
                const temp = {
                    'name': obj?.name.replaceAll('_', " ").toUpperCase(),
                    'value': obj?.value

                }
                processedPieData.push(temp);
            })
            setPieChartData(processedPieData)
        })();

    }, [month, years])

    async function exportCsv() {

        const parsedDate = parse('01-' + month + '-' + years, 'dd-MM-yyyy', new Date())
        const startDate = format(parsedDate, "yyyy-MM-dd HH:mm:ss.SSS")
        const endDate = format(lastDayOfMonth(parsedDate), "yyyy-MM-dd") + " 23:59:59.000";
        const data = await exportCSV(startDate, endDate);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        let date = new Date();
        link.setAttribute('download', `FlyHI_Records.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    async function exportBre() {

        const parsedDate = parse('01-' + month + '-' + years, 'dd-MM-yyyy', new Date())
        console.log(month)
        console.log(years)
        const startDate = years+'-'+month+-'01'
        const endDate = years+'-'+month+-'31'
        const data = await exportBreData(startDate, endDate);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        let date = new Date();
        const filename = 'FlyHI_Bre_'+startDate+'_'+endDate+'.xls'
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

   


    const handleClose = () => {
        setOpen(false);
    };

    function CustomConsentPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    // <Box sx={{ p: 3 }}>
                    //     <Typography>{children}</Typography>
                    // </Box>

                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {consentColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {consentRows
                                    .map((row) => {
                                        return (
                                            <TableRow style={{ cursor: "pointer" }} hover role="button" tabIndex={-1} key={row.code} >
                                                {consentColumns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.id === 'consent'
                                                                ? 'Yes'
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                )}
            </div>
        );
    }

    function CustomSubjectPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    // <Box sx={{ p: 3 }}>
                    //     <Typography>{children}</Typography>
                    // </Box>

                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {subjectColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subjectRows
                                    .map((row) => {
                                        return (
                                            <TableRow style={{ cursor: "pointer" }} hover role="button" tabIndex={-1} key={row.code} >
                                                {subjectColumns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.id === 'consent'
                                                                ? 'Yes'
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                )}
            </div>
        );
    }

    function CustomLegalPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    // <Box sx={{ p: 3 }}>
                    //     <Typography>{children}</Typography>
                    // </Box>

                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {legalColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {legalRows
                                    .map((row) => {
                                        return (
                                            <TableRow style={{ cursor: "pointer" }} hover role="button" tabIndex={-1} key={row.code} >
                                                {legalColumns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.id === 'aadhaarLinked'
                                                                ? 'Yes' : column.id === 'consent'
                                                                ? <span><a href="https://flyhifinance.com/privacy-security" target="_blank">Privacy & Security</a><br></br><a href="https://flyhifinance.com/mitc" target="_blank">MITC</a></span>
                                                                : value
                                                                }
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                )}
            </div>
        );
    }



    CustomConsentPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const consentColumns = [
        { id: 'consent', label: 'Consent Available', minWidth: 80, align: 'left', value: 'Yes' },
        { id: 'createdAt', label: 'Recorded On', minWidth: 100, align: 'left', },
        { id: 'source', label: 'Source', minWidth: 100, align: 'left', },
        {id: 'createdBy', label: 'Provided By', minWidth: 100,align: 'left'},
    ];

    const subjectColumns = [
        { id: 'name', label: 'Name', minWidth: 80, align: 'left', value: 'Yes' },
        { id: 'typeOfHolder', label: 'Type', minWidth: 100, align: 'left', },
        { id: 'gender', label: 'Gender', minWidth: 100, align: 'left', },
        {id: 'emailId', label: 'Email', minWidth: 100,align: 'left'},
        { id: 'dateOfBirth', label: 'DOB', minWidth: 100, align: 'left', },
    ];

    const legalColumns = [
        { id: 'maskedAadhaarNumber', label: 'Masked Aadhar', minWidth: 80, align: 'left', value: 'Yes' },
        { id: 'number', label: 'Pan Number', minWidth: 100, align: 'left', },
        { id: 'equifaxSoftScore', label: 'CIBIL Soft Pull score', minWidth: 100, align: 'left', },
        {id: 'aadhaarLinked', label: 'Aadhar Linked', minWidth: 100,align: 'left'},
        {id: 'consent', label: 'Consented Documents', minWidth: 100,align: 'left'},
    ];

    const dummyConsent = {
        "user_consent_id": 'NA',
        "source": "web",
        "consent": false,
        "createdAt": "NA",
        "createdBy": "NA",
        "updatedAt": null,
        "updatedBy": null,
        "userId": {
            "id": 'NA',
            "userName": "NA",
            "password": "",
            "role": "NA",
            "mobile": null,
            "eligibility": 'NA',
            "enabled": 'NA',
            "username": "NA",
            "credentialsNonExpired": false,
            "accountNonExpired": false,
            "authorities": [
                {
                    "authority": "STUDENT"
                }
            ],
            "accountNonLocked": false
        }
    }

    

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    async function getConsent() {
        // const data = await getConsentData(pan)
        getConsentData(pan).then(data => {
            var temp = [];
            if(!data.userConsentData) {
                temp.push(dummyConsent);
            } else
                temp.push(data.userConsentData)
            // temp.push(data.userConsentData)
            setConsentRows(temp)
            temp = [];
            temp.push(data.panDetailsData)
            setSubjectRows(temp)
            setLegalRows(temp)
            setError(false)
        }).catch(err => {
            setError(true)
            
        })
        

    }


    const [value, setValue] = React.useState(0);
    const [pan, setPan] = React.useState();
    const [consentRows, setConsentRows] = React.useState([]);
    const [subjectRows, setSubjectRows] = React.useState([]);
    const [legalRows, setLegalRows] = React.useState([]);
    const [error, setError] = React.useState();


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <div style={{ height: "auto" }}>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ backgroundColor: theme.palette.headerColor }} position="static">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container sx={{ pt: 5 }}>
                    <CssBaseline />
                    <Grid sx={{ display: "flex", alignContent: "center", justifyContent: "center" }} item xs={12} sm={6} md={6}>
                        <Typography color="inputLabelColor" sx={{ pr: 2, pt: 0.5, fontWeight: "bold", fontFamily: "lato" }} align='left' variant="inputLabel">
                            Choose Limit
                        </Typography>
                        <CSlider setLimit={setLimit} limit={limit} />
                    </Grid>
                    <Grid sx={{ display: "flex", gap: "10px" }} item xs={12} sm={6} md={6}>
                        <CSelectBox optionList={monthsData} defaultValue={month} onChange={setMonth} isMinWidth={true}/>
                        <CSelectBox optionList={YearData} defaultValue={years} onChange={setYears} isMinWidth={true}/>
                        <CButton handleClick={() => exportCsv()} name="Export" />
                        <CButton handleClick={() => exportBre()} name="Export BRE" />
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 10, height: '50vh' }}>
                    <CssBaseline />
                    <Grid item xs={12} sm={6} md={6}>
                        {/* <ResponsiveContainer width="100%" height="100%"> */}
                            <BarChart
                                width={500}
                                height={300}
                                data={barChartData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="sales" fill="#8884d8" >
                                    <LabelList dataKey="sales" position="top" />
                                </Bar>
                            </BarChart>
                        {/* </ResponsiveContainer> */}

                    </Grid>
                    <Grid
                        item
                        display={{ xs: "none", lg: "block", sm: "block" }}
                        sm={6}
                        md={6}
                        sx={{
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        {/* <CButton handleClick={onClickMasterUpdate} name="Master Update" />
                    <img src={signUPImage} style={{ height: '50vh' }} alt="signup" /> */}
                        {/* <ResponsiveContainer width="100%" height="100%"> */}
                            <PieChart width={400} height={400}>
                                <Pie
                                    dataKey="value"
                                    isAnimationActive={false}
                                    data={pieChartData}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    label
                                />
                                <Tooltip />
                            </PieChart>
                        {/* </ResponsiveContainer> */}
                    </Grid>
                </Grid>
                <div>       
                <Grid container sx={{ pt: 10}}> 
                    <Grid item sx={{ display: "flex", alignContent: "center", justifyContent: "center", mt: 15 }} xs={6} sm={6} md={2}>
                        <CInput helperText={"PAN Not Found"}  isError={error} onChange={setPan} value={pan} placeholder='Enter Pan to search' />
                    </Grid>
                    <Grid item sx={{ display: "flex", gap: "10px", mt: 15}} xs={12} sm={6} md={6} >
                        <CButton handleClick={() => getConsent()} name="Search" disabled={!pan}/>
                    </Grid>
                </Grid>
                    <Box sx={{ width: '100%', mt: 10 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Consent" {...a11yProps(0)} />
                                <Tab label="Subject" {...a11yProps(1)} />
                                <Tab label="Legal Docs" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <CustomConsentPanel value={value} index={0}>
                            Item One
                        </CustomConsentPanel>
                        <CustomSubjectPanel value={value} index={1}>
                            Item Two
                        </CustomSubjectPanel>
                        <CustomLegalPanel value={value} index={2}>
                            Item Three
                        </CustomLegalPanel>
                    </Box>


                </div >
            </Dialog>
        </div >
    );
};


