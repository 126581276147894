import React from "react";
import UserData from "./forms/UserData";
import RulesTable from "./RulesTable";
import { Button, Dialog, Grid } from "@mui/material";
import { Box } from "@mui/system";
import UnderWritingTable from "./UnderWritingTable";
import UserEvent from "./forms/UserEvent";
import { uploadFile } from "../../services/studentService";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AdminDocs from "./forms/AdminDocs";

export default function ApplicationRecord(props) {

    const { open, setOpen, breException, breData, selectedRow, fetchUserData } = props
    const [agreementUrl, setAgreementUrl] = React.useState('')
    const [shouldFetch, setShouldFetch] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    const inputRef = React.useRef(null);

        // On File Upload
        function handleFileChange(event) {
            const fileObj = event.target.files && event.target.files[0];
            //setFileObj(fileObj)
           // setIsFileUpload(false)
           // setFileName(fileObj?.name)
           const formData = new FormData();
            formData.append("file", fileObj);
            formData.append("delimeter", "fcp");
            formData.append("detailId", 0)
            uploadFile(formData).then(res => {
                if (res?.status === 200) {
                    // setFileName(fileObj?.name)
                    setAgreementUrl(res?.data)
                }
            }).catch(err => {
                // setError(true)
                // setErrorMsg("File length should be less than 5Mb")
            })
        }

        function onFileUpload() {
            // 👇️ open file input box on click of other element
            inputRef.current.click();
        }

    return (
        <Dialog
            fullScreen
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
        >

            <div>
                <UserData {...props}></UserData>
            </div>
            <div>
                <UserEvent {...props}></UserEvent>
            </div>
            <div>
                <AdminDocs {...props} shouldFetch={shouldFetch} setShouldFetch={setShouldFetch}></AdminDocs>
            </div>
            {/* <Box sx={{width:"300px", m:3}}>  
            <CButton name={showRuleTable?"Show UnderWriting Table":"Show Rule Table"} handleClick={() => setShowRuleTable(!showRuleTable)} />

            </Box> */}
            {
                !breException && breData ?
                    <Box sx={{ m: 3 }}>
                        <RulesTable {...props} shouldFetch={shouldFetch} setShouldFetch={setShouldFetch}/>
                    </Box> :
                    <Box sx={{ m: 3 }}>
                        <UnderWritingTable {...props} shouldFetch={shouldFetch} setShouldFetch={setShouldFetch}/>
                    </Box>
            }

        </Dialog>
    )
}