import { useTheme } from '@emotion/react';
import { Button, Checkbox, Grid, Slide } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from "react";
import { useDispatch } from "react-redux";
import { setAppLoader } from "../../actions/setAppLoader";
import CButton from '../../components/utils/CButton';
import { flagRecord, saveAdminDocs } from "../../services/AdminServices";
import { onboard, saveDeviatedBre } from "../../services/lmsService";
import AdminModal from "../utils/AdminModal";
import CSelectBox from "../utils/CSelectBox";
import CTextField from '../utils/CTextField';
import AdminDocUpload from "./AdminDocUpload";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function RulesTable(props) {
    const dispatch = useDispatch()
    const theme = useTheme();
    const { breData, selectedRow, adminDeviationResponse, fetchUserData,shouldFetch, setShouldFetch } = props;
    const [rowData, setRowData] = React.useState(breData?.data?.bre_response)
    const [deviatedBre, setDeviatedBre] = React.useState(breData?.data?.bre_response);
    const [isDeviated, setIsDeviated] = React.useState(breData?.data?.status === "Deviated" ? true : false)
    const [comments, setComments] = React.useState('');
    const [disabled, setDisabled] = React.useState(false);
    const [status, setStatus] = React.useState('');
    const [openAlert, setOpenAlert] = React.useState(false);
    const [disableSubmit, setDisableSubmit] = React.useState(false);
    const [checkStatus, setCheckStatus] = React.useState(false);
    const actionList = [{ value: 'Pass', name: 'Pass' }, { value: 'Fail', name: 'Fail' }]
    const [appStatus, setAppStatus] = React.useState("Approved")
    const [disableDropDown, setDisableDropDown] = React.useState(false)
    const [isFlagged, setIsFlagged] = React.useState(false);
    const [dropdownDocsMasterId1, setDropdownDocsMasterId1] = React.useState(0)
    const [dropdownDocsMasterId2, setDropdownDocsMasterId2] = React.useState(0)
    const [uploadUrl1, setUploadUrl1] = React.useState('')
    const [uploadUrl2, setUploadUrl2] = React.useState('')
    const [detailId, setDetailId] = React.useState(0)


    React.useEffect(() => {
        setComments(selectedRow?.comments)
        if ((selectedRow?.userFormStatus === 'Approved' || selectedRow?.userFormStatus === 'Approved-Manual' || selectedRow?.userFormStatus === 'Rejected' || selectedRow?.userFormStatus === 'Rejected-Manual' || selectedRow?.userFormStatus === 'RFD')) {

            setDisabled(true)
            setDisableDropDown(true)
        }
        else
            setDisabled(false)
        setDetailId(selectedRow?.detailId)

        setDisableSubmit(false)
        setIsFlagged(selectedRow.flaggedApplication)
    }, [selectedRow])

    React.useEffect(() => {
        console.log(adminDeviationResponse)
        if (adminDeviationResponse != null) {
            setRowData(adminDeviationResponse)
            setDeviatedBre(adminDeviationResponse)
            setCheckStatus(!checkStatus)
        }
    }, [adminDeviationResponse])


    React.useEffect(() => {
        setDisableSubmit(false)
        Object.keys(deviatedBre).map(
            function (object) {
                if (deviatedBre[object]['deviation'] === true) {
                    if (!deviatedBre[object].hasOwnProperty('admin_response')) {
                        setDisableSubmit(true)

                    }
                }

            });
    }, [checkStatus])

    
    React.useEffect(() => {
        flagRecord(isFlagged, selectedRow?.detailId)
     }, [isFlagged])

    async function actionHandler(appStatus) {
        dispatch(setAppLoader(true))
        await onboard(selectedRow?.detailId, appStatus, comments)
        setDisabled(true)
        setOpenAlert(true);
        dispatch(setAppLoader(false))
    }

    function handleDeviation(key, e) {
        let temp = deviatedBre;
        temp[key]['admin_response'] = e
        setDeviatedBre(temp);
        setRowData(temp)
        setCheckStatus(!checkStatus)
    }

    function onSubmitRecord(e) {
        e.preventDefault();
        setAppStatus("Approved")
        dispatch(setAppLoader(true))
        Object.keys(deviatedBre).forEach(
            function (object) {
                var temp = [];
                if (deviatedBre[object]['deviation'] === true) {
                    if (deviatedBre[object].hasOwnProperty('admin_response')) {
                        if (deviatedBre[object]['admin_response'] === 'Fail') {
                            setAppStatus("Rejected")
                        }
                    }
                }

            });
        setOpenAlert(true);
        dispatch(setAppLoader(false))
    }

    async function forceAction() {
        dispatch(setAppLoader(true))
        await onboard(selectedRow?.detailId, appStatus, comments)
        await saveDeviatedBre(deviatedBre, selectedRow?.detailId, isFlagged)
        setOpenAlert(false);
        setDisableSubmit(true)
        setDisabled(true)
        setDisableDropDown(true)
        dispatch(setAppLoader(false))

    }

    async function saveRulesData(e) {
        dispatch(setAppLoader(true))
        e.preventDefault();
        const resp = await saveDeviatedBre(deviatedBre, selectedRow?.detailId, comments)
        dispatch(setAppLoader(false))
    }


    function getTableData() {
        const rows = [];
        for (let key in rowData) {
            const derivedValue = rowData[key]?.derived_values || {}
            const derivedLastKey = Object.keys(derivedValue)[Object.keys(derivedValue).length - 1]
            const derivedLastObject = derivedValue[derivedLastKey]
            let displayActuals = '';
            if (typeof derivedLastObject == 'number') {
                displayActuals = derivedLastObject
            } else if (Array.isArray(derivedLastObject)) {
                const arrayLastData = derivedLastObject[derivedLastObject?.length - 1]
                const objectLastKey = arrayLastData ? Object.keys(arrayLastData)[Object.keys(arrayLastData).length - 1] : ''
                displayActuals = arrayLastData && objectLastKey ? arrayLastData[objectLastKey] : "Not Present"
            } else if (typeof derivedLastObject == "boolean") {

                displayActuals = derivedLastObject.toString()
            } else {
                displayActuals = "Not Present"
            }
            rows.push(
                <TableRow
                    sx={{ backgroundColor: rowData[key]?.deviation ? '#a9fc03' : '', borderRight: "2px solid black", borderLeft: "2px solid black" }}

                >
                    <TableCell sx={{ borderLeft: "0.1px inset gray", borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {rowData[key]?.description}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {rowData[key]?.definition}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {displayActuals}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {rowData[key]?.deviation ?
                            breData?.data?.status === "Deviated" && (selectedRow?.userFormStatus !== 'Approved' && selectedRow?.userFormStatus !== 'Rejected') ?
                                <Grid sx={{ py: 3, width: "100px" }} container justifyContent="flex-start" >
                                    <CSelectBox disable={disableDropDown} defaultValue={rowData[key]?.admin_response || ""} label="Action" onChange={(e) => handleDeviation(key, e)} optionList={actionList} />
                                </Grid> : rowData[key]?.deviation ? 'True' : 'False' : "False"}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {rowData[key]?.result ? "Pass" : "Fail"}
                    </TableCell>

                </TableRow>

            );
        }
        return rows;
    }
    return (
        <React.Fragment>

            <TableContainer component={Paper}>
                <form onSubmit={(e) => onSubmitRecord(e)} className='applicationFormContainer'>
                    <Table sx={{ minWidth: 650, border: '1px' }} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ borderRight: "0.1px inset gray", borderLeft: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }}>Rules</TableCell>
                                <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Policy</TableCell>
                                <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Actuals</TableCell>
                                <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Deviation</TableCell>
                                <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Result</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {getTableData()}
                        </TableBody>
                        {isDeviated ?
                            <>
                            <div style={{ padding: "4px" }}>
                                <CButton handleClick={(e) => saveRulesData(e)} name="Save Rules" disabled={disableDropDown} />
                            </div> 
                            <div style={{ padding: "4px" }}>
                            <Checkbox style={{ color: "#D32F2F" }} checked={isFlagged} onChange={() => setIsFlagged(!isFlagged)} name="isFLagged" />
                            <span style={{ fontSize: "20px" }}>
                           Flag Record
                        </span>
                    </div>
                            </>: null}
                    </Table>
                    <Grid sx={{ py: 3, pr: 4 }} container justifyContent="flex-start" >
                        <div style={{ display: "flex", gap: "20px", alignItems: "end" }}>
                            <div style={{ padding: "4px" }}>
                                <CTextField placeholder='Comments' label='Comments' value={comments} disabled={disabled} onChange={setComments}></CTextField>
                            </div>
                            <Button
                                type="submit"
                                sx={{ fontStyle: "Lato" }}
                                disabled={disableSubmit}
                                color="neutral"
                                variant="contained">
                                Save & Submit
                            </Button>
                        </div>
                    </Grid>
                    {!disableDropDown ? <AdminDocUpload username={selectedRow.user.userName} detailId={selectedRow?.detailId} shouldFetch={shouldFetch} setShouldFetch={setShouldFetch} />   : null }
                </form>
            </TableContainer >
            {
                openAlert &&
                <AdminModal open={openAlert} appStatus={appStatus} setOpenAlert={setOpenAlert} forceAction={forceAction} comments={comments} shouldFetch={shouldFetch} setShouldFetch={setShouldFetch}/>

            }

        </React.Fragment>
    );
}
