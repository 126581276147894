import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import CInput from '../utils/CInput';
import CAutoComplete from '../utils/CAutoComplete';
import { getFcp, getFcpDropDown } from '../../services/AdminServices';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function FCDModal(props) {
    const { open, setOpen, isConfirm, setFCPID, FCPID, onSaveFCDID } = props

    const [isSales, setIsSales] = React.useState('yes')
    const [fcpList, setFcpList] = React.useState([])

    React.useEffect(()=>{
        async function fetchData() {
            // You can await here
            const fcps = await getFcpDropDown();
            
            setFcpList(fcps)
          }
       
          fetchData();
       
    },[])

    const handleClose = () => {
        setOpen(false);
        isConfirm(false)
    };

    function onConfirmClick() {
        setOpen(false)
        onSaveFCDID()
    }
    const onRadioChange = (event) => {
        setIsSales(event.target.value);
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}

            >
                <DialogContent sx={{ margin: "30px" }}>
                    <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Franchise / Channel Partner</Typography>
                    <Grid sx={{ textAlign: "left", mt: 3 }} item xs={12}>
                        <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Are you coming to us through a franchise /channel partner? </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={isSales}
                            name="radio-buttons-group"
                            onChange={onRadioChange}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label={<Typography color='textColor' variant='aadharLabel' >Yes</Typography>} />
                            <FormControlLabel value="no" control={<Radio />} label={<Typography color='textColor' variant='aadharLabel' >No</Typography>} />

                        </RadioGroup>
                    </Grid>
                    <Grid sx={{ display: "flex", alignItems: "center", textAlign: "left", mt: 6 }} item xs={12}>
                        <Typography color='textColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Enter FCP ID</Typography>
                        <Box sx={{ ml: 3, width: "150px" }}>
                              {/* <CInput value={FCPID} disabled={isSales === 'no'} onChange={setFCPID} /> */}
                              <CAutoComplete
                                    onChange={setFCPID} optionList={fcpList} disable={isSales === 'no'}
                                />
                              </Box>
                    </Grid>
                </DialogContent>
                <div style={{ padding: "10px 0px 20px", display: "flex", justifyContent: "center" }}>
                    <Button
                        type="submit"
                        sx={{ fontStyle: "Lato" }}
                        color="neutral"
                        variant="contained"
                        onClick={onConfirmClick}
                        disabled={!FCPID && !(isSales === 'no')}
                    >
                        OK
                    </Button>
                </div>
            </BootstrapDialog>
        </div>
    );
}
