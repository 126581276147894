import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CInput from "../../utils/CInput";
import CSelectBox from "../../utils/CSelectBox";
import CButton from "../../utils/CButton";
import { Grid } from '@mui/material';
import { saveInstituteName } from '../../../services/AdminServices';
import { useEffect } from 'react';
import CDialogue from '../../utils/CDialogue';


export default function AddNewInstituteName(props) {
    const { open, selectedRow, instituteType, reFetch, setOpen, handleCloseForm } = props;
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [instituteName, setInstituteName] = React.useState('')
    const [institueTypeList, setinstitueTypeList] = React.useState([])
    const [selectedInstitueType, setSelectedInstitueType] = React.useState()
    const [confirmModel, setConfirmModel] = React.useState(false)



    const handleClose = () => {
        setOpen(false);
        handleCloseForm();
    };

    useEffect(() => {
        setInstituteName(selectedRow?.name)
        const selectedTypeId = institueTypeList?.find(item => item?.name === selectedRow?.type)
        setSelectedInstitueType(+selectedTypeId?.value)
    }, [selectedRow])

    React.useEffect(() => {
        const tempData = []
        instituteType?.map(item => {
            tempData.push({
                value: item?.instituteTypeId,
                name: item?.instituteTypeName
            })
        })
        setinstitueTypeList(tempData)
    }, [instituteType])

    async function saveData() {
        const selectedName = institueTypeList?.find(item => item?.value === selectedInstitueType)
        const obj = {
            instituteTypeId: selectedInstitueType,
            instituteNameId: null,
            instituteAddressId: null,
            instituteCourseId: null,
            instituteSchemeId: null,
            instituteTypeName: selectedName?.name,
            instituteName: instituteName,
            instituteAddressName: null,
            instituteCourseName: null,
            instituteCourseTenure: null,
            instituteCourseFee: null,
            createdBy: "8946992225",
            instituteAddressCity: null,
            instituteAddressPin: null,
            instituteAddressState: null,
            instituteAddressEMail: null,
            instituteAddressBankAccount: null,
            instituteAddressPhoneNumber: null,
            instituteAddressIfsc: null,
            instituteAddressContactPerosnName: null
        }
        if (selectedRow && selectedRow?.id) {
            obj.instituteNameId = selectedRow?.id
        }
        const updatedData = await saveInstituteName(obj);
        handleClose()
        reFetch(2)
    }

    function confirmAction() {
        setConfirmModel(true)
    }
    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Add New Institute Name</DialogTitle>
                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            pt: 2,
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <CInput onChange={setInstituteName} value={instituteName} label="Institute Name*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CSelectBox name="enrollmentType" defaultValue={selectedInstitueType} onChange={setSelectedInstitueType} optionList={institueTypeList} label="Institute Type*" />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CButton disabled={!instituteName || !selectedInstitueType} handleClick={confirmAction} variant='contained' name="Add" />
                </DialogActions>
            </Dialog>
            <CDialogue open={confirmModel} message={selectedRow?.id ? "Are you Sure you want to Edit Institute Name" : "Are you Sure you want to Create new Institute Name"} setOpen={setConfirmModel} isConfirm={saveData} />

        </React.Fragment>
    );
}
