
import { getDropdownDataService } from '../services/studentService'

export const getDropDownData = () => dispatch => {

    dispatch({
        payload: [],
        type: 'GET_DROP_DOWN_PENDING'
    })
    getDropdownDataService().then((res) => {
        dispatch({
            payload: {
                data: res
            },
            type: 'GET_DROP_DOWN_SUCCESS'
        })
    }).catch((err) => {
        dispatch({
            payload: {
                data: {
                    data: []
                },
                error: err
            },
            type: 'GET_DROP_DOWN_ERROR'
        })
    })

}