import { Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React from "react";
import { setAppLoader } from "../../actions/setAppLoader";
import { getUserData } from "../../actions/getUserData";
import { saveFormPath } from "../../services/userService";
export default function LoanApplicationCompleted(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const userId = userData?.userId;
    const userDetails = useSelector((state) => state?.getUserData?.data);

      //Show Loader
      React.useEffect(() => {
        dispatch(getUserData(props.userDetailId))
    }, [])
  

    //Show Loader
    React.useEffect(() => {
        dispatch(setAppLoader(isFetching))
    }, [isFetching])
  
    async function onSubmitBankStatement(e) {
        e.preventDefault();
        saveFormPath("/loanCompleted", userDetails?.detailId)
        navigate("/userDashboard", {state: {tabValue: 2, stepperCount: 2}})
    }
    
    return (
        <form onSubmit={(e) => onSubmitBankStatement(e)} className='applicationFormContainer' >
            <Grid item xs={12} >
                <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Loan Application Completed</Typography>
            </Grid>
            <Grid item sx={{ textAlign: "left" }} xs={10} >
                <Typography color='textColor' variant='body1' sx={{ pt: 2 }}>
                    Thank you for completing your Loan journey. Nothing is pending from your side. Now sit back and relax. 
                    {userDetails?.studentDetails?.studentName}'s fee will be disbursed to the institute. *
                </Typography>

            </Grid>
            <div style={{ padding: "50px 0px 0px", display: "flex", justifyContent:"center"}}>
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    variant="contained"
                    >
                    Continue to dashboard
                </Button>
            </div>
            <Grid  sx={{ textAlign: "left" }}>
                <Typography color="textColorR" sx={{ fontWeight: "bold", fontFamily: "lato" }} align='left' variant="inputLabel">
                  *Terms and Conditions apply
                </Typography>
            </Grid>
        </form >
    )
}