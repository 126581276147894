export default (state, action) => {
    if (typeof state === 'undefined') {
      state = false
    }
    switch (action.type) {
      case 'SHOW_LOADER': {
        return true
      }
      case 'HIDE_LOADER': {
        return false
      }
      default:
        return state
    }
  }