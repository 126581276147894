import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import CButton from "../utils/CButton";
import CInput from "../utils/CInput";
import CAlert from "../utils/CAlert";
import failure from "../../assets/images/failure.svg"
import { setAppLoader } from "../../actions/setAppLoader";
import { getUserData } from "../../actions/getUserData";
import success from "../../assets/images/Vector.svg";
import { downloadPhysicalNachForm, getMandateData, registerUserMandate, saveMandateTransactionData, uploadPhysicalNachForm } from "../../services/loanApprovalService";
import { saveFormPath } from "../../services/userService";
import { saveBankDetails } from "../../services/bankService";
import FileUploadIcon from '@mui/icons-material/FileUpload';


export default function LoanRepaymentMethods(props) {

    const { tabValue, setTabValue, setStepperCount, userDetailId } = props;
    const MAX_FILE_SIZE = 15000 // 15MB
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = React.useState('');
    const [error, setError] = React.useState(false)
    const inputRef = React.useRef(null);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const userId = userData?.userId;
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const [txnId, setTxnId] = React.useState('Not Started');
    const [message, setMessage] = React.useState('');
    const [mandateError, setMandateError] = React.useState(null);
    const [mandateData, setMandateData] = React.useState(null);
    const [enachNum, setEnachNum] = React.useState(null);
    const [isChecked, setIsChecked] = React.useState(false);
    const [disabledButton, setDisabledButton] = React.useState(true);
    const [bankName, setBankName] = React.useState(null);
    const [acType, setAcType] = React.useState(null);
    const [acHolderName, setAcHolderName] = React.useState(null);
    const [acNumber, setAcNumber] = React.useState(null);
    const [ifscCode, setIfscCode] = React.useState(null);
    const [bankNameError, setBankNameError] = React.useState(false);
    const [accTypeError, setAccTypeError] = React.useState(false);
    const [accHolderError, setAccHolderError] = React.useState(false);
    const [accNumberError, setAccNumberError] = React.useState(false);
    const [ifscError, setIfscError] = React.useState(false);
    const [isPhysicalMandate, setIsPhysicalMandate] = React.useState(false);
    const [showUpload, setShowUpload] = React.useState(false);




    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserData(userDetailId))
    }, []);

    useEffect(() => {
        setBankName(userDetails?.userBankStatementDetails?.statementBankName)
        setBankNameError(userDetails?.userBankStatementDetails?.statementBankName ? false : true)
        setAcType(userDetails?.userBankStatementDetails?.statementAccountType)
        setAccTypeError(userDetails?.userBankStatementDetails?.statementAccountType ? false : true)
        setAcHolderName(userDetails?.userBankStatementDetails?.nameAsPerStatement)
        setAccHolderError(userDetails?.userBankStatementDetails?.nameAsPerStatement ? false : true)
        setAcNumber(userDetails?.userBankStatementDetails?.statementAccountNumber)
        setAccNumberError(userDetails?.userBankStatementDetails?.statementAccountNumber ? false : true)
        setIfscCode(userDetails?.userBankStatementDetails?.statementBankIfsc)
        setIfscError(userDetails?.userBankStatementDetails?.statementBankIfsc ? false : true)
    }, [userDetails]);


    useEffect(() => {
        if (bankName && bankName !== "")
            setBankNameError(false)
    }, [bankName]);

    useEffect(() => {
        if (acType && acType !== "")
            setAccTypeError(false)
    }, [acType]);

    useEffect(() => {
        if (acHolderName && acHolderName !== "")
            setAccHolderError(false)
    }, [acHolderName]);

    useEffect(() => {
        if (acNumber && acNumber !== "")
            setAccNumberError(false)
    }, [acNumber]);

    useEffect(() => {
        if (ifscCode && ifscCode !== "")
            setIfscError(false)
    }, [ifscCode]);

    useEffect(() => {
        (async () => {
            const resp = await getMandateData(userDetailId);
            setMandateData(resp?.data)
            if (resp?.data?.transactionId) {
                setIsChecked(true)
                setDisabledButton(false)
            }
            if (resp?.data?.mandateMode === "physical")
            showUpload(true)

        })();
    }, []);

    //Show Loader
    React.useEffect(() => {
        dispatch(setAppLoader(isFetching))
    }, [isFetching])


    async function registerMandate(e) {
        e.preventDefault()
        const obj = {
            detailId: userDetailId,
            bankName: bankName,
            accountType: acType,
            accountHolderName: acHolderName,
            accountNumber: acNumber,
            ifscCode: ifscCode

        }
        dispatch(setAppLoader(true))
        await saveBankDetails(obj)
        const mandateResp = await registerUserMandate(userDetailId)
        dispatch(setAppLoader(false))

        var options = {
            //default environment staging (sandbox)
            //for production include: “environment” : “production”; here
            //environment: "sandbox",
            environment: "production",
            "callback": async function (response) {
                if (response.error_code != undefined) {
                    dispatch(setAppLoader(true))
                    console.log("failed to register with error :", response);
                    setTxnId(null);
                    setMessage(response?.message)
                    setMandateError(response?.error_code);
                    setEnachNum(null)
                    await saveMandateTransactionData(userDetailId, null, response?.message, response?.error_code)
                    dispatch(setAppLoader(false))
                }
                else {
                    console.log("register successful for ", response);
                    setTxnId(response?.txn_id);
                    setMessage(response?.message)
                    setMandateError(null);
                    setEnachNum(response?.digio_doc_id)
                    dispatch(setAppLoader(true))
                    const resp = await saveMandateTransactionData(userDetailId, response?.txn_id, response?.message, null)
                    //await getMandateData(userDetailId);
                    setMandateData(resp?.data)
                    if (resp.data)
                        setDisabledButton(false);

                    // await registerEnachWithOneFin(userDetailId)
                  
                    dispatch(setAppLoader(false))
                }
            },
            "is_iframe": true, //open in iframe,
            "dg_preferred_auth_type": "debit"  // debit or net_banking
        };
        if (mandateResp?.data?.mandateMode !== "physical") {
            setIsPhysicalMandate(false)
            var digio = new window.Digio(options)
            digio.init();  // Call init on user action (eg.button press and proceed to asynchronous upload)
            digio.submit(mandateResp?.data?.mandateId, mandateResp?.data?.mandateCreatedBy);
        } else {
            dispatch(setAppLoader(true))
            setIsPhysicalMandate(true)
            const data = await downloadPhysicalNachForm(userDetailId, mandateResp?.data?.mandateId);
            var blob = new Blob([data?.data], { type: "application/pdf" });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "PhysicalNachForm-" + new Date().getTime() + ".pdf";
            link.click();
            dispatch(setAppLoader(false));
            setShowUpload(true);
        }
    }

    // Save Form Path
    async function onSubmitMandateData(e) {
        e.preventDefault();
        dispatch(setAppLoader(true))
        await saveFormPath("/loanRepayment", userDetails?.detailId)
        if (userDetails?.schemeDetails?.numberOfAdvanceEmis === 0 && userDetails?.processingFees === 0)
            navigate("/loanCompleted", { state: { tabValue: 9, stepperCount: 9, userDetailId: userDetailId } })
        else
            navigate("/payment", { state: { tabValue: 4, stepperCount: 4, userDetailId: userDetailId } })

        dispatch(setAppLoader(false))
    }

    function isDisabled() {
        if (!bankName || !acType || !acHolderName || !acNumber || !ifscCode)
            return true
        if (!isChecked)
            return true
        if (mandateData && mandateData.transactionId)
            return true

        return false
    }

    function onFileUpload() {
        // 👇️ open file input box on click of other element
        inputRef.current.click();
    };

    async function handleFileChange(event) {
        setError(false)
        const fileObj = event.target.files && event.target.files[0];
        const extension = fileObj.name.split('.')[1]
        if (event.target.files.length > MAX_FILE_SIZE) {
            setError(true)
            setErrorMsg("File length should be less than 15Mb")
            return;
        }
        if (extension.toLowerCase() !== 'jpg' && extension.toLowerCase() !== 'jpeg' && extension.toLowerCase() !== 'png') {
            setError(true)
            setErrorMsg("Only Image files are allowed")
            return;
        }
        const formData = new FormData();
        formData.append("id", userDetailId)
        formData.append("mandateId", mandateData?.mandateId)
        formData.append("file", fileObj);
        dispatch(setAppLoader(true))
        await uploadPhysicalNachForm(formData).then(res => {
            if (res?.status === 200) {
                console.log(res)
                event = null
                setMandateData(res?.data)
                setShowUpload(false)
                setDisabledButton(false);
                //registerEnachWithOneFin(userDetailId)
                dispatch(setAppLoader(false))
            }
        }).catch(err => {
            setError(true)
            console.log(err)
            if(err?.response?.status === 400)
                setErrorMsg("Invalid Image, Try again")
            else
                setErrorMsg("An Error Occured")
            event = null;
            dispatch(setAppLoader(false))
        })
    }

    return (
        <form onSubmit={(e) => onSubmitMandateData(e)} className='applicationFormContainer' >
            {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
            }
            {
                errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
            }
            <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Loan Re-Payment Method</Typography>


            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item sx={{ textAlign: "left", display: "flex", mt: 2 }} >
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Your bank details to register mandate are as below. Please enter any values that are missing.</Typography>
                </Grid>
                <Grid item xs={6}>
                    <CInput value={bankName} helperText={"Enter Your Bank Name"} isError={bankNameError} disabled={userDetails?.userBankStatementDetails?.statementBankName} onChange={setBankName} label="Bank*" name="studentName" />
                </Grid>
                <Grid item xs={6}>
                    <CInput maxLength={20} value={acType} helperText={"Enter Your Account Type"} isError={accTypeError} disabled={userDetails?.userBankStatementDetails?.statementAccountType} onChange={setAcType} label="A/C Type" name="studentId" />
                </Grid>
                <Grid item xs={6}>
                    <CInput maxLength={20} value={acHolderName} helperText={"Enter Account Holder Name"} isError={accHolderError} disabled={userDetails?.userBankStatementDetails?.nameAsPerStatement} onChange={setAcHolderName} label="A/C Holder Name" name="studentId" />
                </Grid>
                <Grid item xs={6}>
                    <CInput maxLength={20} value={acNumber} helperText={"Enter Your Bank A/C Number"} isError={accNumberError} disabled={userDetails?.userBankStatementDetails?.statementAccountNumber} onChange={setAcNumber} label="Bank A/C No." name="studentId" />
                </Grid>
                <Grid item xs={6}>
                    <CInput maxLength={20} value={ifscCode} helperText={"Enter Your Bank IFSC Code"} isError={ifscError} disabled={userDetails?.userBankStatementDetails?.statementBankIfsc} onChange={setIfscCode} label="IFSC Code" name="studentId" />
                </Grid>
            </Grid>
            <Divider sx={{ mt: 4, mb: 6 }} />
            <Grid item sx={{ textAlign: "left", display: "flex", mt: 2 }} >
            {!isPhysicalMandate ?
                    <Typography color='textColorR' variant='aadharLabel' sx={{ textAlign: "left", fontWeight: "600" }}>Please click on below button to proceed with mandate registration.</Typography>
                    :
                    <Typography color='textColorR' variant='aadharLabel' sx={{ textAlign: "left", fontWeight: "600" }}>Please click on below button to download mandate registeration form.</Typography>
                }
            </Grid>
            <Grid item sx={{ mt: 2, textAlign: "left", display: "flex" }} xs={12}>
                <FormGroup sx={{ marginRight: "-20px" }}>
                    <FormControlLabel
                        control={<Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} style={{ color: "#D32F2F" }} name="gilad" />}
                    />
                </FormGroup>
                <div style={{ fontSize: "16px", marginTop: "7px" }}>
                    <span >
                        I confirm that this is my repayment account.
                    </span>
                </div>
            </Grid>
            <Grid sx={{ mt: 2 }} item xs={12}>
                <div style={{ float: "left" }} >
                    <CButton disabled={isDisabled()} handleClick={(e) => registerMandate(e)} variant="outlined" name="Register Mandate" />
                </div>
                {mandateData && mandateData.transactionId ?
                    <React.Fragment>
                        <img src={success} />
                        <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>E-Mandate Registered</Typography>

                    </React.Fragment>
                    : mandateError ?
                        <React.Fragment>
                            <img src={failure} />
                            <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}> E-Mandate Failed. Please Retry.</Typography>
                        </React.Fragment> : null}
                {/* <Grid sx={{ display: "flex", alignItems: "center", pl: 4 }} item xs={12}>
                    <React.Fragment>
                        <img src={success} />
                        <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>E-Mandate Registered</Typography>
                    </React.Fragment>
                </Grid> */}
            </Grid>
            <div style={{ margin: "20px 0px 0px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div></div>
                {showUpload ?
                    <div>
                        <input accept="image/*" type="file" style={{ display: 'none' }} onChange={(e) => handleFileChange(e)}
                            onClick={(event) => { event.target.value = null }} ref={inputRef} />
                        <Button onClick={onFileUpload} color="uploadButton" fullWidth variant="contained" startIcon={<FileUploadIcon />}>
                            Upload Form
                        </Button>
                    </div> :
                    <Button
                        type="submit"
                        sx={{ fontStyle: "Lato" }}
                        color="neutral"
                        disabled={disabledButton}
                        variant="contained">
                        Save & Submit
                    </Button>

                }

            </div>
        </form >
    )
}