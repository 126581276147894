import * as React from 'react';
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';

export default function CAlert(props) {
    const vertical = 'top';
    const horizontal = 'right';
    const { open, message, severity } = props;
    const [isOpen, setIsOpen] = React.useState(open)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setIsOpen(false);
      };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={isOpen}
                autoHideDuration={6000}
                message={message}
                onClose={handleClose}
                key={vertical + horizontal}
            >
                <Alert onClose={() => { setIsOpen(false) }} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
