import { useTheme } from '@emotion/react';
import { InputLabel, Typography, useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function CDateP(props) {

    const { label, disabled, name, value, defaultValue, helperText, isError, onChange, placeholder, isOther, maxLength } = props;

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    function isValidFunction(event) {
        let value = event.target.value
        if (maxLength && +maxLength < +value?.length)
            return;
        onChange(value, event)
    }


    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "-webkit-fill-available" }}>
            <InputLabel htmlFor="input-with-icon-adornment">
                <Typography fontSize={matches ? "14px" : "10px"} color="inputLabelColor" sx={{ fontWeight: "bold", fontFamily: "lato" }} align='left' variant="inputLabel">
                    {label}
                </Typography>
            </InputLabel>
            <TextField
                fullWidth
                size="small"
                error={isError}
                onChange={isValidFunction}
                disabled={disabled}
                style={{ fontSize: '18px', textAlign: "left" }}
                name={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                value={value}
                inputProps={{ style: { fontSize: 16 } }} // font size of input text
                helperText={isError ? helperText : null}
                color={isOther ? "warning" : ''}
                focused={isOther ? true : ''}
                type="date"
            />
        </div>
    )
}