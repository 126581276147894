import { httpservice } from './httpService'


export function lead(detailId) {
    return httpservice()
        .post(`/api/lms/lead?detailId=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function uploadCb(detailId) {
    return httpservice()
        .post(`/api/lms/uploadCB?detailId=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function onboard(detailId, status, comments) {
    if (status === null)
        return httpservice()
            .post(`/api/lms/onboard?detailId=${detailId}`)
            .then(function (response) {
                return response
            })
            .catch(function (error) {
                return error.data
            })
    else
        return httpservice()
            .post(`/api/lms/onboard?detailId=${detailId}&status=${status}&comments=${comments}`)
            .then(function (response) {
                return response
            })
            .catch(function (error) {
                return error.data
            })
}

export function getBreResponse(detailId) {
    return httpservice()
        .get(`/api/lms/bre?detailId=${detailId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function saveDeviatedBre(deviationJson, detailId, comments, isFlagged) {
    return httpservice()
        .post(`/api/lms/deviation?detailId=${detailId}&comments=${comments}&isFlagged=${isFlagged}`, deviationJson)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function saveFallback(underWriting, detailId, appStatus, comments, isFlagged) {
    return httpservice()
        .post(`/api/lms/fallback?detailId=${detailId}&appStatus=${appStatus}&comments=${comments}&isFlagged=${isFlagged}`, underWriting)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function saveRules(underWriting, detailId, comments) {
    return httpservice()
        .post(`/api/adminData/saveRules?detailId=${detailId}&comments=${comments}`, underWriting)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function registerEnachWithOneFin(detailId) {
    return httpservice()
        .post(`/api/lms/enachRegistrationOneFin?detailId=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function registerPaymentWithOneFin(detailId) {
    return httpservice()
        .post(`/api/lms/repaymentOneFin?detailId=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}
