import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../actions/getUserData";
import { setAppLoader } from "../../actions/setAppLoader";
import { validEmail } from "../../custom-hooks/validRegex";
import { saveFormPath } from "../../services/userService";
import { saveResidentialData } from "../../services/userVerifications";
import CAlert from "../utils/CAlert";
import CButton from "../utils/CButton";
import CDialogue from "../utils/CDialogue";
import CInput from "../utils/CInput";
import CSelectBox from "../utils/CSelectBox";

export default function AadharOutput({ setTabValue, userDetailId }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const [isCurrentAddress, setIsCurrentAddress] = React.useState(false);
    const [aadharData, setAadharData] = React.useState([])
    const [residentialStatus, setResidentialStatus] = React.useState('')
    const [residentialList, setResidentialList] = React.useState([])
    const [isCurrenResident, setIsCurrentResident] = React.useState(false)
    const [showResidentialModal, setShowResidentialModal] = React.useState(false)
    const [noOfYears, setNoOfYears] = React.useState('')
    const [noOfYearsList, setNoOfYearsList] = React.useState([])
    const [email, setEmail] = React.useState('')
    const [emailError, setEmailError] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('')
    const [profileImage, setProfileImage] = React.useState('')


    const dropDownData = useSelector((state) => state?.getDropDownData?.data);
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null

    const disabledButton = !residentialStatus || !noOfYears || !email

    useEffect(() => {
        dispatch(getUserData(userDetailId))
    }, []);

    //Show Loader
    React.useEffect(() => {
        dispatch(setAppLoader(isFetching))
    }, [isFetching])

    // set Aadhaar Data
    React.useEffect(() => {
        if (userDetails?.aadharDetails) {
            setAadharData(userDetails?.aadharDetails)
            setIsCurrentResident(userDetails?.aadharDetails?.isCurrentAddress)
            setResidentialStatus(userDetails?.aadharDetails?.userResidentialStatus?.valueId)
            setNoOfYears(userDetails?.aadharDetails?.userYearsAtCurrentResident?.valueId)
            setEmail(userDetails?.aadharDetails?.userEmail)
            setIsCurrentAddress(userDetails?.aadharDetails?.isCurrentAddress)
            const rawResp = JSON.parse(userDetails.aadharDetails.aadharResponse)
            setProfileImage(rawResp.data.profile_image)
        }
    }, [userDetails])


    React.useEffect(() => {
        // Filter Residential Status
        let residentialObj = dropDownData.find(x => x.keyDesc === "RESIDENTIAL_STATUS");
        const tempResidentialObj = []
        residentialObj?.valueData?.map(eItem => {
            tempResidentialObj.push({
                value: eItem?.valueid,
                name: eItem?.valuedesc
            })
        })
        setResidentialList(tempResidentialObj)

        // Filter No. Of years List
        let residentObj = dropDownData.find(x => x.keyDesc === "YEARS_AT_CURRENT_RESIDENT");
        const tempResidentObj = []
        residentObj?.valueData?.map(eItem => {
            tempResidentObj.push({
                value: eItem?.valueid,
                name: eItem?.valuedesc
            })
        })
        setNoOfYearsList(tempResidentObj)
    }, [])


    async function saveUserResidentialData(isSameAddress) {
        dispatch(setAppLoader(true))
        const updatedCurrentResidential = isSameAddress ? isSameAddress : isCurrenResident
        console.log(updatedCurrentResidential)
        const obj = {
            currentAddress: updatedCurrentResidential,
            userResidentialStatus: +residentialStatus,
            userYearsAtCurrentResident: +noOfYears,
            userEmail: email,
        }
        await saveResidentialData(userDetails?.detailId, obj);
        dispatch(getUserData(userDetailId))
        dispatch(setAppLoader(false))
        if (updatedCurrentResidential) {
            if (userDetails?.instituteAddress?.instituteRiskCode === 4 || userDetails?.instituteAddress?.instituteRiskCode === 3) {
                saveFormPath("/faceMatch", userDetails?.detailId)
                setTabValue(8)
                navigate("/faceMatch", { state: { tabValue: 9, stepperCount: 6, userDetailId: userDetailId } })
            } else {
                saveFormPath("/bankDetails", userDetails?.detailId)
                setTabValue(8)
                navigate("/bankDetails", { state: { tabValue: 8, stepperCount: 5, isCurrentAddress: isCurrentAddress, userDetailId: userDetailId } })
            }
        } else {
            setTabValue(7)
            saveFormPath("/addNewAddress", userDetails?.detailId)
            navigate("/addNewAddress", { state: { tabValue: 7, stepperCount: 5, isCurrentAddress: isCurrentAddress, userDetailId: userDetailId } })
        }
    }
    // Save Aadhaar data
    async function onSubmitAadharOutput(e) {
        e.preventDefault();
        if (!validEmail.test(email)) {
            setEmailError(true)
        } else {
            if (!isCurrenResident) {
                setShowResidentialModal(true)
            } else {
                saveUserResidentialData()
            }
        }
    }

    function handleBack() {
        setTabValue(5);
        navigate("/panAadharDetails", { state: { tabValue: 5, stepperCount: 4, userDetailId: userDetailId } })
    }

    function onSameAddress(isSameAddress) {
        saveUserResidentialData(isSameAddress);
    }

    return (
        <form onSubmit={(e) => onSubmitAadharOutput(e)} className='applicationFormContainer' >
            {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
            }
            {
                errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
            }
            {
                !matches &&
                <Box sx={{ display: "flex" }}>
                    <ArrowBackIosIcon  onClick={() => handleBack()} />
                </Box>
            }
            {matches ? <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Personal Details of Applicant</Typography> : null
                // <Typography color='textColor' variant='subtitle3' sx={{ pb:4, fontWeight:"bold", textAlign: "center" }}>Aadhar Output</Typography>
            }
            <Grid container sx={{pt:4}} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Full Name</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{aadharData?.userFullName}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Address</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{aadharData?.userAddress}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel sx={{ fontSize: "14px", color: "#D32F2F" }} control={<Checkbox checked={isCurrenResident} onChange={() => { setIsCurrentResident(!isCurrenResident); setIsCurrentAddress(!isCurrenResident); }} />} label={<Typography color='textColorR' variant='body2' sx={{ textAlign: "left", fontSize: matches ? "14px" : "8px" }}>This is my current residential address</Typography>} />
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Gender</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{aadharData?.userGender}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Date Of Birth</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{aadharData?.userDob}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>PinCode</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{aadharData?.userPincode}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>City</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{aadharData?.userCity}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>District</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{aadharData?.userDistrict}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>State</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>{aadharData?.userState}</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography color='textColor' variant='aadharLabel' sx={{ textAlign: "left" }}>Photograph</Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Avatar sx={{ width: 100, height: 100 }} src={"data:image/png;base64,"+profileImage} variant="square">
                    </Avatar>
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={5}>
                    <CSelectBox
                        onChange={setResidentialStatus}
                        optionList={residentialList}
                        defaultValue={residentialStatus}
                        label="Residential Status*" name="residentialStatus" />
                </Grid>
                <Grid item xs={12} md={5}>
                    <CSelectBox
                        onChange={setNoOfYears}
                        optionList={noOfYearsList}

                        defaultValue={noOfYears}
                        label="Number of Years at currrent Resident*" name="residentYear" />
                </Grid>
                <Grid item xs={12} md={5}>
                    <CInput
                        onChange={setEmail}
                        value={email}
                        isError={emailError}
                        helperText="Please enter valid email"
                        label="Email*" name="email" />
                </Grid>
            </Grid>
            <div style={{ padding: "20px 0px 0px", display: "flex", flexDirection: "row", justifyContent: `${!matches ? "space-around" : "space-between"}` }}>
                {matches && <CButton handleClick={() => handleBack()} name="Back" />}
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    disabled={disabledButton}
                    variant="contained">
                    Save & Submit
                </Button>
            </div>
            {
                showResidentialModal ?
                    <CDialogue open={showResidentialModal} setOpen={setShowResidentialModal} message="Is your current residential address same as your Aadhar address" isConfirm={onSameAddress} /> : null
            }
        </form >
    )
}