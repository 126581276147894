import { httpservice, baseURL } from "./httpService"
import axios from 'axios'

/**
 *  PDF type
 */
export function generatePDF(userName, userID) {
    return httpservice()
        .get(`/api/client/pdf/get/${userName}/${userID}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function downloadSanctionLetter(userID) {
    let jwt = localStorage.getItem('jwt')
    const auth = 'Bearer ' + jwt

    let URL = baseURL + `/api/client/pdf/sanctionLetter/${userID}`

    return new Promise((resolve, reject) => {
        axios.get(
            URL,
            {
                responseType: 'blob',
                headers: {
                    "Authorization": auth,
                    "Content-type": "application/pdf",
                },
            }
        ).then(function (response) {
            resolve(response.data)
        }).catch(function (error) {
            return error.response
        })
    })
}



/**
 * Equifax type
 */
export function generateEquifax(userName, userID) {
    return httpservice()
        .get(`/api/client/equifax/get/${userName}/${userID}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Final SUbmit
 */
export function finalSubmit(instituteId, status, comments) {
    return httpservice()
        .put(`/api/userData/finalFormSubmit?userInstituteId=${instituteId}&status=${status}&comments=${comments}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}
