import { Button, Checkbox, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from "react";
import { useDispatch } from "react-redux";
import { setAppLoader } from "../../actions/setAppLoader";
import CButton from '../../components/utils/CButton';
import { saveFallback, saveRules } from "../../services/lmsService";
import { saveFormPath } from "../../services/userService";
import AdminModal from "../utils/AdminModal";
import CInput from "../utils/CInput";
import CSelectBox from "../utils/CSelectBox";
import CTextField from '../utils/CTextField';
import AdminDocUpload from "./AdminDocUpload";
import { flagRecord } from '../../services/AdminServices';





export default function UnderWritingTable(props) {
    const dispatch = useDispatch()
    const { selectedRow,shouldFetch, setShouldFetch } = props;
    const [comments, setComments] = React.useState('');
    const [disabledComment, setDisabledComment] = React.useState(false);
    const [showAdminUpload, setShowAdminUpload] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [disabledButton, setDisabledButton] = React.useState(true);
    const [appStatus, setAppStatus] = React.useState("Approved")
    const [disableDropDown, setDisableDropDown] = React.useState(false)
    const [disableInput, setDisableInput] = React.useState(false);
    const [isFlagged, setIsFlagged] = React.useState(false);
    const [detailId, setDetailId] = React.useState(0)


    const actionList = [{ value: 'Pass', name: 'Pass' }, { value: 'Fail', name: 'Fail' }]
    var breData = {
        "bre_response": {
            "SMA_ACCOUNTS_LAST_12_MONTHS_BS": {
                "description": "SMA In Last 12 Months ( Go- True, Deviation- False)",
                "result": null,
                "definition": "SMA Account Status ( If NO : Go ; If YES : Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "MIN_WORK_EXPERIENCE_BS": {
                "description": "Minimum Work Experience",
                "result": null,
                "definition": "Minimum Work Experience - Salaried( If >=2 Go; If >=0.5 and <2 Deviation)",
                "deviation": null,
                "derived_values": null
            },
            "SUBSTANDARD_ACCOUNT_STATUS_24_MONTHS_BS": {
                "description": "Substandard In Last 24 Months ( Go- True, Deviation- False)",
                "result": null,
                "definition": "Substandard Account Status ( If NO : Go ; If YES : Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "MIN_NUMBER_OF_SALARY_CREDITS_BS": {
                "description": "Minimum Number of Salary Credits",
                "result": null,
                "definition": "Minimum Number of Salary Credits - Salaried( If >= Bank Statement Period- 1 Go; If < Bank Statement Period- 1 Deviation), Self_Emp (  If >=2 Go; If < 1 No Go; If <=2 Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "MAX_NUM_OF_NON_CC_ENQUIRIES_6_MONTHS_BS": {
                "description": "Maximum Number Of Non CC Enquires In Last 6 Months  ( Go- True, Deviation- False )",
                "result": null,
                "definition": "Maximum Number Of Non CC Enquires (If <=3 Go; If >6 No Go ; If >3 and <=6 Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "MAX_DPD_CC_LAST_MONTH_BS": {
                "description": "Maximum DPD CC Last Month ( Go- True, Deviation- False )",
                "result": null,
                "definition": "Maximum DPD CC ( If 0 Go; If < 1 No Go; If <30 Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "MAX_0_PLUS_DPD_FOR_LOANS_LAST_MONTH_BS": {
                "description": "Maximum 0+ DPD Accounts In Last Month ( Go- True, Deviation- False)",
                "result": null,
                "definition": "Maximum 0+ DPD Accounts (If 0 Go ; If >0 No Go )",
                "deviation": null,
                "derived_values": null
            },
            "MAX_0_PLUS_DPD_INSTANCES_CC_3_MONTHS_BS": {
                "description": "Maximum 0+ DPD Instances For CC In Last 3 Months ( Go- True, Deviation- False )",
                "result": null,
                "definition": "Maximum 0+ DPD Instances For CC ( If <=1 Go; If >2 No Go ; If >1 and <=2 Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "MAX_EMI_BOUNCE_LAST_3_MONTHS_BS": {
                "description": "Maximum EMI Bounce In Last 3 Months",
                "result": null,
                "definition": "EMI Bounce ( If = 0 Go ; If  > 0 No Go ; NA Deviation )",
                "deviation": false,
                "derived_values": null
            },
            "MIN_FACE_MATCH_SCORE_BS": {
                "description": "Minimum Face Match Score",
                "result": null,
                "definition": "Face Match Score ( If >=70% Go : If <=70% No Go )",
                "deviation": null,
                "derived_values": null
            },
            "WILFUL_DEFAULTER_ACCOUNT_STATUS_12_MONTHS_BS": {
                "description": "Wilful Defaulter Matched",
                "result": null,
                "definition": "Wilful Defaulter  Account Status ( If NO : Go )",
                "deviation": false,
                "derived_values": null
            },
            "MAX_BANK_STATEMENT_AGE_BS": {
                "description": "Maximum Bank Statement Age",
                "result": null,
                "definition": "Bank Statement Age ( If <=15 Go ; If >15 No Go )",
                "deviation": null,
                "derived_values": null
            },
            "PAN_VALIDATION_BS": {
                "description": "PAN Number Matched",
                "result": null,
                "definition": "PAN Number Matches Exactly",
                "deviation": null,
                "derived_values": null
            },
            "LOAN_AMOUNT_BS": {
                "description": "Loan Amount (INR)",
                "result": null,
                "definition": "Loan Amount - Minimum ( If >= 5000  Go ; If <5000 No Go ), Maximum ( If <=600000 Go ; If >600000 No Go )",
                "deviation": null,
                "derived_values": null
            },
            "MIN_BANK_STATEMENT_PERIOD_BS": {
                "description": "Minimum Bank Statement Period",
                "result": null,
                "definition": "Bank Statement Period in Months ( If >= 3 Go ;  If <3 No Go )",
                "deviation": null,
                "derived_values": null
            },
            "MAX_NUM_OF_CHECK_BOUNCES_LAST_6_MONTHS_BS": {
                "description": "Maximum Number Of Cheque Bounces In 6 Last months",
                "result": null,
                "definition": "Maximum Number Of Cheque Bounces-  Salaried(If If <=2 Go; If If >2 No Go ; NA Deviation ), Self_Emp(If <=6 Go; If >6 No Go ;  NA Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "MIN_ABB_EMI_RATIO_BS": {
                "description": "Minimum ABB EMI Ratio",
                "result": null,
                "definition": "Minimum ABB/EMI Ratio-Salaried( If >=1.5 Go; If <1.0 No Go ; If >=1.0 and <1.5 Deviation ), Self_Emp( If >=2 Go; If<1.5 No Go ; If >=1.5 and <2 Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "MAX_60_PLUS_DPD_FOR_LOANS_6_MONTH_BS": {
                "description": "Maximum 60+ DPD Accounts In Last 6 Months ( Go- True , Hard Reject )",
                "result": null,
                "definition": "Maximum 60+ DPD Accounts (If 0 Go ; If >0 No Go )",
                "deviation": null,
                "derived_values": null
            },
            "NON_CC_WRITE_OFF_ACCOUNT_STATUS_36_MONTHS_BS": {
                "description": "Write Off Credit Card Account Status In Last 36 Months ( Go- True, Deviation- False)",
                "result": null,
                "definition": "Credit Bureau account status (If No : Go; If >10% No Go ; If <=10% Deviation)",
                "deviation": null,
                "derived_values": null
            },
            "MAX_30_PLUS_DPD_INSTANCES_CC_3_MONTHS_BS": {
                "description": "Maximum 30+ DPD For CC In Last 3 Months ( Go- True , Hard Reject )",
                "result": null,
                "definition": "Maximum 30+ DPD For CC ( If <=1 Go : If >1 No Go )",
                "deviation": null,
                "derived_values": null
            },
            "MAX_AGE_AT_LOAN_MATURITY_BS": {
                "description": "Maximum Age At Loan Maturity",
                "result": null,
                "definition": "Max Age at Loan Maturity-  Salaried( If <=60 Go; If > 65 No Go ; If >60 and <=65 Deviation ), Self_Emp ( If <=65 Go; If > 70 No Go; If >65 and <=70 Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "USER_ADDITIONAL_ADDRESS_BS": {
                "description": "User Additional Address",
                "result": null,
                "definition": "If false Go; If true deviation",
                "deviation": false,
                "deviation": null,
                "derived_values": null
            },
            "AADHAR_VALIDATION_BS": {
                "description": "AADHAR Number Matched",
                "result": null,
                "definition": "AADHAR Number Matches Exactly",
                "deviation": null,
                "derived_values": null
            },
            "MAX_CREDIT_BUREAU_OVERDUE_FOR_ALL_CC_BS": {
                "description": "Maximum Credit Bureau Overdue (INR) For All Credit Cards ( Go- True, Deviation- False)",
                "result": null,
                "definition": "If ( Sum of Overdue <=10% of loan amount) Go;  If ( Sum of Overdue > 10% of loan amount) Deviation",
                "deviation": null,
                "derived_values": null
            },
            "MIN_CREDIT_BUREAU_SCORE": {
                "description": "Minimum Credit Bureau Score",
                "result": null,
                "definition": "Minimum Credit Bureau Score  ( If >=675 Go ; If <625 No Go ; If >=625 and <675 Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "MIN_FLY_HI_SCORE_BS": {
                "description": "Minimum Fly Hi Score",
                "result": null,
                "definition": "Minimum Fly Hi Score Card Score ( If >=700 Go; If <650 No Go ;If >=650 and <700 Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "MIN_LOAN_TENURE_BS": {
                "description": "Minimum Loan Tenure",
                "result": null,
                "definition": "Loan Tenure  ( If>=3  Go ; If <3 No Go )",
                "deviation": null,
                "derived_values": null
            },
            "MAX_30_PLUS_DPD_FOR_LOANS_3_MONTH_BS": {
                "description": "Maximum 30+ DPD Accounts In Last 3 Months ( Go- True , Hard Reject )",
                "result": null,
                "definition": "Maximum 30+ DPD Accounts (If 0 Go ; If >0 No Go )",
                "deviation": null,
                "derived_values": null
            },
            "MAX_90_PLUS_DPD_FOR_LOANS_12_MONTH_BS": {
                "description": "Maximum 90+ DPD Accounts In Last 12 Months ( Go- True , Hard Reject )",
                "result": null,
                "definition": "Maximum 90+ DPD Accounts (If 0 Go ; If >0 No Go )",
                "deviation": null,
                "derived_values": null
            },
            "MIN_APPLICANT_AGE_BS": {
                "description": "Minimum Applicant Age",
                "result": null,
                "definition": "Minimum Age ( If >=21 Go ; If <21 No Go )",
                "deviation": null,
                "derived_values": null
            },
            "CC_WRITE_OFF_ACCOUNT_STATUS_36_MONTHS_BS": {
                "description": "Write Off Credit Card Account Status In Last 36 Months ( Go- True, Deviation- False)",
                "result": null,
                "definition": "Write Off Credit Card Account Status ( If No : Go ; If  >10% No Go ; If <=10% Deviation )",
                "deviation": null,
                "derived_values": null
            },
            "MAX_30_PLUS_DPD_INSTANCES_CC_12_MONTHS_BS": {
                "description": "Maximum 30+ DPD For CC In Last 12 Months ( Go- True , Hard Reject )",
                "result": null,
                "definition": "Maximum 30+ DPD For CC ( If <=3 Go : If >3 No Go )",
                "deviation": null,
                "derived_values": null
            },
            "MIN_NAME_MATCH_SCORE_BS": {
                "description": "Minimum Name Match Score",
                "result": null,
                "definition": "Name ( If >=80% Go) ",
                "deviation": null,
                "derived_values": null
            }
        }

    }
    const [rowData, setRowData] = React.useState(breData?.bre_response);

    React.useEffect(() => {
        if (props.breFallBackResponse != null)
            setRowData(JSON.parse(props.breFallBackResponse))
            setDetailId(selectedRow?.detailId)
        if(selectedRow.userFormStatus === "Submit" || selectedRow.userFormStatus === "Deviated")
            setShowAdminUpload(true)
        setIsFlagged(selectedRow.flaggedApplication)
    }, [selectedRow])

    React.useEffect(() => {
        setComments(selectedRow?.comments)
        if (selectedRow?.userFormStatus === "Approved-Manual" || selectedRow?.userFormStatus === "Rejected-Manual" || selectedRow?.userFormStatus === "RFD") {
            setDisableDropDown(true)
            setDisabledButton(true)
            setDisableInput(true)
            setDisabledComment(true)
        }
        // else
        //     setDisabledComment(false)
    }, [selectedRow])

    React.useEffect(() => {
        for (const property in rowData) {
            if (rowData[property].derived_values && rowData[property]?.result && !(selectedRow?.userFormStatus === "Approved-Manual" || selectedRow?.userFormStatus === "Rejected-Manual" || selectedRow?.userFormStatus === "RFD")) {
                setDisabledButton(false)
            } else {
                setDisabledButton(true)
                break;
            }
        }
    }, [rowData])

    React.useEffect(() => {
       flagRecord(isFlagged, selectedRow?.detailId)
    }, [isFlagged])


    function onChangeActuals(value, key) {
        const tempRowData = { ...rowData }
        tempRowData[key].derived_values = value
        setRowData(tempRowData)
    }
    async function onChangeResult(value, key) {
        const tempRowData = { ...rowData }
        tempRowData[key].result = value
        setRowData(tempRowData)
    }

    async function forceAction() {
        dispatch(setAppLoader(true))
        await saveFallback(rowData, selectedRow?.detailId, appStatus + '-Manual', comments, isFlagged)
        if (appStatus === "Approved")
            await saveFormPath("/loanApproved", selectedRow?.detailId)
        else
            await saveFormPath("/loanRejected", selectedRow?.detailId)
        setOpenAlert(false);
        setDisabledButton(true)
        setDisabledComment(true)
        setDisableDropDown(true)
        setDisableInput(true)
        dispatch(setAppLoader(false))
    }

    function onSubmit(e) {
        e.preventDefault();
        setAppStatus("Approved")
        dispatch(setAppLoader(true))
        Object.keys(rowData).forEach(
            function (object) {
                var temp = [];
                if (rowData[object]['result'] === "Fail") {
                    setAppStatus("Rejected")
                }
            });
        setOpenAlert(true)
        const obj = {
            breRequest: rowData
        }
        dispatch(setAppLoader(false))
    }

    async function saveRulesData(e) {
        dispatch(setAppLoader(true))
        e.preventDefault();
        const resp = await saveRules(rowData, selectedRow?.detailId, comments)
        setRowData(JSON.parse(resp?.data?.lmsAdminFallbackResponse))
        dispatch(setAppLoader(false))
    }

    function getTableData() {
        const rows = [];
        for (let key in rowData) {
            const derivedValue = rowData[key]?.derived_values || {}
            const derivedLastKey = Object.keys(derivedValue)[Object.keys(derivedValue).length - 1]
            const derivedLastObject = derivedValue[derivedLastKey]
            let displayActuals = '';
            if (Number.isInteger(derivedLastObject)) {
                displayActuals = derivedLastObject
            } else if (Array.isArray(derivedLastObject)) {
                const arrayLastData = derivedLastObject[derivedLastObject?.length - 1]
                const objectLastKey = arrayLastData ? Object.keys(arrayLastData)[Object.keys(arrayLastData).length - 1] : ''
                displayActuals = arrayLastData && objectLastKey ? arrayLastData[objectLastKey] : "Not Present"
            } else if (typeof derivedLastObject == "boolean") {

                displayActuals = derivedLastObject.toString()
            } else {
                displayActuals = "Not Present"
            }
            rows.push(
                <TableRow
                    sx={{ backgroundColor: rowData[key]?.deviation ? '#a9fc03' : '' }}>
                    <TableCell sx={{ borderLeft: "0.1px inset gray", borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {rowData[key]?.description}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {rowData[key]?.definition}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        <CInput disabled={disableInput} onChange={(value) => onChangeActuals(value, key)} value={rowData[key]?.derived_values} />
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        <Grid sx={{ py: 0, width: "100px" }} container justifyContent="flex-start" >
                            <CSelectBox disable={disableDropDown} defaultValue={rowData[key]?.result || ""} onChange={(value) => onChangeResult(value, key)} optionList={actionList} />
                        </Grid>
                    </TableCell>

                </TableRow>

            );
        }
        return rows;
    }

    

    return (
        <TableContainer component={Paper}>
            <form onSubmit={(e) => onSubmit(e)} className='applicationFormContainer'>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ borderRight: "0.1px inset gray", borderLeft: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }}>Rules</TableCell>
                            <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Policy</TableCell>
                            <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Actuals</TableCell>
                            {/* <TableCell>Deviation</TableCell> */}
                            <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Result</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTableData()}
                    </TableBody>
                    <div style={{ padding: "4px" }}>
                        <CButton handleClick={(e) => saveRulesData(e)} name="Save Rules" disabled={disableDropDown} />
                    </div>
                    <div style={{ padding: "4px" }}>
                        <Checkbox style={{ color: "#D32F2F" }} checked={isFlagged} onChange={() => setIsFlagged(!isFlagged)} name="isFLagged" />
                        <span style={{ fontSize: "20px" }}>
                           Flag Record
                        </span>
                    </div>
                    
                       
                    
                </Table>
                <Grid sx={{ py: 3, pr: 4 }} container justifyContent="flex-start" >
                    <div style={{ display: "flex", gap: "20px", alignItems: "end" }}>
                        <div style={{ padding: "4px" }}>
                            <CTextField placeholder='Comments' label='Comments' value={comments} disabled={disabledComment} onChange={setComments}></CTextField>
                        </div>
                        <Button
                            type="submit"
                            sx={{ fontStyle: "Lato" }}
                            disabled={disabledButton}
                            color="neutral"
                            variant="contained">
                            Save & Submit
                        </Button>
                    </div>
                </Grid>
                {showAdminUpload ? <AdminDocUpload username={selectedRow.user.userName} detailId={selectedRow?.detailId} shouldFetch={shouldFetch} setShouldFetch={setShouldFetch}/>   : null }
            </form>
            {
                openAlert &&
                <AdminModal open={openAlert} appStatus={appStatus} setOpenAlert={setOpenAlert} forceAction={forceAction} comments={comments} />

            }
        </TableContainer>
    );
}
