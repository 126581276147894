import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import CInput from '../utils/CInput';
import CAutoComplete from '../utils/CAutoComplete';
import { getFcp, getFcpDropDown } from '../../services/AdminServices';
import { signUPOTP } from '../../services/authenticationService';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function AddressModal(props) {
    const { setAddress, setCity, SetAddrPin  } = props


    const [otp, setOtp] = React.useState();
    const [error, setError] = React.useState();
    const handleClose = () => {
        //setOpenOtp(false);
    };


    const verifyOTP = () => {

        // const otpData = {
        //     otp: otp,
        //     phoneNo: mobileNumber,
        //     details: details,
        //     verify: true
        // }
        // setError(null)
        // signUPOTP(otpData).then(data => {
        //     if (data.status === 200 || data.status === 201) {
        //         setMobileNumberVerified(true)
        //         setOpenOtp(false);
        //     } else {
        //         console.log(data.response.data.message)
        //         setError(data.response.data.message)
        //     }
        // }).catch(error => {
        //     //setError(true)
        //     console.log('error', error)
        // })
        
    };

    function handleChange() {
        setOtp(otp)
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={false}

            >
                <DialogContent sx={{ margin: "30px" }}>
                    <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Enter OTP</Typography>
                    <CInput helperText={error} isError={error ? true : false} placeholder="Address" autoFocus={true} value={otp} onChange={setAddress} name="address" label="Address as per Aadhaar" />
                    <CInput helperText={error} isError={error ? true : false} placeholder="City" autoFocus={true} value={otp} onChange={setCity} name="city" label="City" />
                    <CInput helperText={error} isError={error ? true : false} placeholder="Pin" autoFocus={true} value={otp} onChange={SetAddrPin} name="pin" label="Pin" />
                    
                </DialogContent>
                <div style={{ padding: "10px 0px 20px", display: "flex", justifyContent: "center" }}>
                    <Button
                        type="button"
                        sx={{ fontStyle: "Lato" }}
                        color="neutral"
                        variant="contained"
                        onClick={verifyOTP}
                        disabled={!otp}
                    >
                        Verify 
                    </Button>
                    <Button
                        type="button"
                        sx={{ fontStyle: "Lato" }}
                        color="neutral"
                        variant="contained"
                        onClick={handleClose}
                        disabled={!otp}
                    >
                        Close
                    </Button>
                </div>
            </BootstrapDialog>
        </div>
    );
}
