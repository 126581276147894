

export const getStep = (step) => dispatch => {

    // TO DO is this needed ?
    dispatch({
        type: 'CHANGE_STATE',
        payload: step
    })

}