import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CButton from './CButton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CDialogue(props) {
    const { open, setOpen, isConfirm, message, isOkButton } = props


    const handleClose = () => {
        setOpen(false);
        isConfirm(false)
    };

    function onConfirmClick() {
        isConfirm(true)
        setOpen(false)
    }
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >

                <DialogContent>
                    <Typography gutterBottom>
                        {message}
                    </Typography>

                </DialogContent>
                {isOkButton ?
                    <DialogActions>
                        <CButton handleClick={onConfirmClick} variant='contained' name="OK" />
                    </DialogActions> :
                    <DialogActions>
                        <CButton handleClick={onConfirmClick} variant='contained' name="Yes" />
                        <CButton handleClick={handleClose} variant='contained' name="No" />
                    </DialogActions>
                }
            </BootstrapDialog>
        </div>
    );
}
