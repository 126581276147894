import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CInput from "../../utils/CInput";
import CSelectBox from "../../utils/CSelectBox";
import CButton from "../../utils/CButton";
import { Autocomplete, Grid, TextField } from '@mui/material';
import { getFcp, saveInstituteAddress } from '../../../services/AdminServices';
import { useDispatch, useSelector } from 'react-redux';
import CSwitch from '../../utils/CSwitch';
import CDialogue from '../../utils/CDialogue';
import { getInstituteName } from '../../../actions/getInstituteName';
import CAutoComplete from '../../utils/CAutoComplete';

export default function AddInstituteAddress(props) {
    const { open, setOpen, reFetch, handleCloseForm, selectedRow, instituteType, instituteName, fcps } = props;
    const dispatch = useDispatch();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [instituteAddress, setInstituteAddress] = React.useState('')

    const [instituteTypeList, setinstitueTypeList] = React.useState([])
    const [selectedInstituteType, setSelectedInstitueType] = React.useState([])

    const [instituteNameList, setinstitueNameList] = React.useState([])
    const [selectedInstituteName, setSelectedInstitueName] = React.useState([])

    const [bankAccount, setBankAccount] = React.useState('')
    const [instituteCity, setInstituteCity] = React.useState('')
    const [instPhNo, setInstPhNo] = React.useState('')
    const [instState, setInstState] = React.useState('')
    const [instPin, setInstPin] = React.useState('')
    const [instCName, setInstCName] = React.useState('')
    const [instEmail, setInstEmail] = React.useState('')
    const [instIFSC, setInstIFSC] = React.useState('')
    const [instGST, setInstGST] = React.useState('')
    const [bankName, setBankName] = React.useState('')
    const [bankBranch, setBankBranch] = React.useState('')
    const [riskCode, setRiskCode] = React.useState('')
    const [fcpId, setFcpId] = React.useState('')
    const [beneficiaryName, setBeneficiaryName] = React.useState('')
    const [isOther, setIsOther] = React.useState('')
    const [confirmModel, setConfirmModel] = React.useState(false)
    const [fcpList, setFcpList] = React.useState([]);
    const [isInterestBourneByInstitute, setInterestBourneByInstitute] = React.useState(false);
    const [byepass, setByepass] = React.useState();
    const [instituteByepassUrl, setInstituteByepassUrl] = React.useState();

    const instituteNameData = useSelector((state) => state?.getInstituteName?.data);
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const top100Films = ['The Godfather', 'Pulp Fiction'];

    React.useEffect(() => {
        setInstituteAddress(selectedRow?.adddress)
        setSelectedInstitueType(+selectedRow?.typeId)
        if (+selectedRow?.typeId) {
            dispatch(getInstituteName(+selectedRow?.typeId))
        }
        setSelectedInstitueName(+selectedRow?.nameId)
        setBankAccount(selectedRow?.bAccount)
        setInstituteCity(selectedRow?.city)
        setInstPhNo(selectedRow?.pNo)
        setInstCName(selectedRow?.instituteContactPersonName)
        setInstEmail(selectedRow?.instituteEmailId)
        setInstIFSC(selectedRow?.instituteIfscCode)
        setInstGST(selectedRow?.instituteGst)
        setIsOther(selectedRow?.isOtherAddress)
        setBankName(selectedRow?.instituteBankName)
        setBankBranch(selectedRow?.instituteBankBranch)
        setRiskCode(selectedRow?.instituteRiskCode)
        setBeneficiaryName(selectedRow?.beneficiaryName)
        setFcpId(selectedRow?.instituteFcpId)
        setInstState(selectedRow?.state)
        setInstPin(selectedRow?.pin)
        setByepass(selectedRow?.byepass ? selectedRow?.byepass : false)
        let temp = []
        for(let i=0; i<fcps.length;i++) {
            let obj = {
                'id' : i,
                'name': fcps[i]
            }
            temp.push(obj)
        }
        setFcpList(fcps)
        //const resp = getFcp()
        setInterestBourneByInstitute(selectedRow?.interestBourneByInstitute === "false" ? false : true)

        // const selectedAddressId = instituteAddressList?.find(item=>item?.adddress === selectedRow?.type)
        // setSelectedInstituteAddress(+selectedAddressId?.value)
    }, [selectedRow])

    // Filter Institute Name Data
    React.useEffect(() => {
        if (instituteNameData && instituteNameData.length) {
            const tempInstituteName = []
            instituteNameData.map(item => {
                if (item.instituteName.toLowerCase() === "other") {
                    return
                }
                tempInstituteName.push({
                    name: item?.instituteName,
                    value: item?.instituteId
                })
            })
            setinstitueNameList(tempInstituteName)
        }
    }, [instituteNameData])

    React.useEffect(() => {
        const tempData = []
        instituteType?.map(item => {
            tempData.push({
                value: item?.instituteTypeId,
                name: item?.instituteTypeName
            })
        })
        setinstitueTypeList(tempData)

    }, [instituteType])

    function onChangeType(valueId) {
        if (valueId) {
            dispatch(getInstituteName(valueId))
            setSelectedInstitueType(valueId)
        }
    }

    const handleClose = () => {
        setSelectedInstitueName('')
        setSelectedInstitueType('')
        setinstitueNameList([])
        setInstState('');
        setInstPin('')
        setInterestBourneByInstitute(false)
        setOpen(false);
        handleCloseForm();
    };

    async function saveData() {
        const selectedName = instituteNameList?.find(item => item?.value === selectedInstituteName)
        const selectedType = instituteTypeList?.find(item => item?.value === selectedInstituteType)

        const obj = {
            instituteTypeId: selectedInstituteType,
            instituteNameId: selectedInstituteName,
            instituteAddressId: null,
            instituteCourseId: null,
            instituteSchemeId: null,
            instituteTypeName: selectedType?.name,
            instituteName: selectedName?.name,
            instituteAddressName: instituteAddress,
            instituteCourseName: null,
            instituteCourseTenure: null,
            instituteCourseFee: null,
            createdBy: userDetails?.user?.userName,
            instituteAddressCity: instituteCity,
            instituteAddressPin: instPin,
            instituteAddressState: instState,
            instituteAddressEMail: instEmail,
            instituteAddressBankAccount: bankAccount,
            instituteAddressPhoneNumber: instPhNo,
            instituteAddressIfsc: instIFSC,
            instituteAddressContactPerosnName: instCName,
            instituteBankBranch: bankBranch,
            beneficiaryName: beneficiaryName,
            instituteRiskCode: riskCode,
            instituteFcpId: fcpId,
            instituteGst: instGST,
            instituteBankName: bankName,
            interestBourneByInstitute: isInterestBourneByInstitute,
            byepass: byepass ? byepass : false,
        }
        console.log(obj)
        if (selectedRow && selectedRow?.id) {
            obj.instituteAddressId = selectedRow?.id
        }
        const updatedData = await saveInstituteAddress(obj);
        handleClose()
        reFetch(4)
    }


    function confirmAction() {
        setConfirmModel(true)
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Add New Institute Address</DialogTitle>
                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            pt: 2,
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <CInput value={instituteAddress} onChange={setInstituteAddress} label="Institute Address*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CSelectBox onChange={onChangeType} defaultValue={selectedInstituteType} optionList={instituteTypeList} label="Institute Type*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CSelectBox onChange={setSelectedInstitueName} defaultValue={selectedInstituteName} optionList={instituteNameList} label="Institute Name*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setInstituteCity} value={instituteCity} label="Institute City*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setInstState} value={instState} label="Institute State" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput type="number" onChange={setInstPin} value={instPin} label="Institute PIN*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setInstCName} value={instCName} label="Institute Contact Name" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput type="number" onChange={setInstPhNo} value={instPhNo} label="Institute Phone Number" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setInstEmail} value={instEmail} label="Institute Email" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setBankName} value={bankName} label="Bank Name" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setBankBranch} value={bankBranch} label="Bank Branch" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setBankAccount} value={bankAccount} label="Bank Account" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setBeneficiaryName} value={beneficiaryName} label="Beneficiary Name" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setInstIFSC} value={instIFSC} label="Institute IFSC" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setInstGST} value={instGST} label="Institute GST" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setRiskCode} value={riskCode} label="Institute Risk Code" />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <CSelectBox onChange={setFcpId} defaultValue={fcpId} optionList={fcpList} label="Institute Fcp ID" /> */}
                                <CAutoComplete
                                    onChange={setFcpId} defaultValue={selectedRow?.instituteFcpId} optionList={fcpList} label="Fcp ID"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CSwitch setIsChecked={setInterestBourneByInstitute} checked={isInterestBourneByInstitute} label="Interest Bourne By Institute" />
                            </Grid>
                            <Grid item xs={12}>
                                <CSwitch setIsChecked={setByepass} checked={byepass} label="ByePass Evaluation" />
                            </Grid>
                        </Grid>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <CButton disabled={!instituteAddress || !selectedInstituteName || !selectedInstituteType || !instituteCity || !instPin} handleClick={confirmAction} variant='contained' name="Add" />
                </DialogActions>
            </Dialog>
            <CDialogue open={confirmModel} message={selectedRow?.id ? "Are you Sure you want to Edit Institute Address" : "Are you Sure you want to Create new Institute Address"} setOpen={setConfirmModel} isConfirm={saveData} />

        </React.Fragment>
    );
}
