import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CInput from "../../utils/CInput";
import CSelectBox from "../../utils/CSelectBox";
import CButton from "../../utils/CButton";
import { Fab, Grid, Typography } from '@mui/material';
import { saveInstituteScheme } from '../../../services/AdminServices';
import { useSelector, useDispatch } from 'react-redux';
import CSwitch from '../../utils/CSwitch';
import CDialogue from '../../utils/CDialogue';
import { getInstituteAddress } from '../../../actions/getInstituteAddress';
import { getInstituteCourse } from '../../../actions/getInstituteCourse';
import { getInstituteName } from '../../../actions/getInstituteName';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

export default function AddInstituteScheme(props) {

    const { open, setOpen, reFetch, handleCloseForm, selectedRow, instituteType, instituteName, instituteCourse, instituteAddress } = props;

    const dispatch = useDispatch()
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const userData = localStorage.getItem('mobileData') ? JSON.parse(localStorage.getItem('mobileData')) : null
    const userId = userData?.userName;

    const [instituteTypeList, setinstitueTypeList] = React.useState([])
    const [selectedInstituteType, setSelectedInstitueType] = React.useState([])

    const [instituteNameList, setinstitueNameList] = React.useState([])
    const [selectedInstituteName, setSelectedInstitueName] = React.useState([])

    const [instituteAddressList, setInstitueAddressList] = React.useState([])
    const [selectedInstituteAddress, setSelectedInstituteAddress] = React.useState([])

    const [instituteCourseList, setinstitueCourseList] = React.useState([])
    const [selectedInstituteCourse, setSelectedInstitueCourse] = React.useState([])

    const [tenure, setTenure] = React.useState('')
    const [roi, setROI] = React.useState('')
    const [subvention, setSubvention] = React.useState('')
    const [pf, setPF] = React.useState('')
    const [isOtherScheme, setIsOtherScheme] = React.useState(false)
    const [isDisabledScheme, setIsDisabledScheme] = React.useState(false)
    const [isTranche, setIsTranche] = React.useState(false)
    const [trancheDetails, setTrancheDetails] = React.useState([{ month: "", percentage: "" }])
    const [advanceEMI, setAdvanceEMI] = React.useState('')
    const [confirmModel, setConfirmModel] = React.useState(false)
    const [percenategError, setPercentageError] = React.useState(false)
    const [schemeMoratorium, setSchemeMoratorium] = React.useState(0)
    const instituteNameData = useSelector((state) => state?.getInstituteName?.data);
    const instituteAddressData = useSelector((state) => state?.getInstituteAddress?.data);
    const instituteCourseData = useSelector((state) => state?.getInstituteCourse?.data);


    const monthData = createMonthData(48);
    function createMonthData(numberOfItems) {
        const data = [];
        for (let i = 0; i < numberOfItems; i++) {
            data.push({ name: String(i), value: i });
        }
        return data;
    }

    // set Select Box Data
    React.useEffect(() => {
        const tempData = []
        instituteType?.map(item => {
            tempData.push({
                value: item?.instituteTypeId,
                name: item?.instituteTypeName
            })
        })
    }, [instituteType])

    // set Scheme Data
    React.useEffect(() => {
        if (Object.keys(selectedRow)?.length) {

            // Format the tranche Details 
            let tranceString = selectedRow?.details.replace(/[{}]/g, "");
            tranceString = tranceString?.split(",")
            let tempTrancheDetails = []
            for (let i = 0; i < tranceString?.length; i++) {
                let subString = tranceString[i]?.split(":")
                tempTrancheDetails.push({ month: subString[0]?.replace(/["]/g, ""), percentage: subString[1] })
            }

            setTenure(selectedRow?.tenure)
            setROI(+selectedRow?.roi)
            setPF(+selectedRow?.pf)
            setTrancheDetails(tempTrancheDetails)
            setAdvanceEMI(selectedRow?.emi)
            setIsTranche(selectedRow?.tranche === "false" ? false : true)
            setIsOtherScheme(selectedRow?.scheme === "false" ? false : true)
            setIsDisabledScheme(selectedRow?.disabledScheme === "false" ? false : true)
            setSelectedInstitueCourse(+selectedRow?.courseId)
            setSubvention(+selectedRow?.subvention)
            setSelectedInstitueType(+selectedRow?.typeId)
            setSchemeMoratorium(+selectedRow?.schemeMoratorium)
            if (+selectedRow?.typeId) {
                dispatch(getInstituteName(+selectedRow?.typeId))
            }
            setSelectedInstitueName(+selectedRow?.nameId)
            if (+selectedRow?.nameId) {
                dispatch(getInstituteAddress(+selectedRow?.nameId))
            }
            setSelectedInstituteAddress(+selectedRow?.addressID)
            if (+selectedRow?.addressID) {
                dispatch(getInstituteCourse(+selectedRow?.addressID))
            }
        }
    }, [selectedRow])

    // Filter Institute Name Data
    React.useEffect(() => {
        if (instituteNameData && instituteNameData.length) {
            const tempInstituteName = []
            instituteNameData.map(item => {
                if (item.instituteName.toLowerCase() === "other") {
                    return
                }
                tempInstituteName.push({
                    name: item?.instituteName,
                    value: item?.instituteId
                })
            })
            setinstitueNameList(tempInstituteName)
        }
    }, [instituteNameData])

    // Filter Institute Address Data
    React.useEffect(() => {
        if (instituteAddressData && instituteAddressData.length) {
            const tempInstituteAddress = []
            instituteAddressData.map(item => {
                if (item.instituteAddress.toLowerCase() === "other") {
                    return
                }
                tempInstituteAddress.push({
                    name: item?.instituteAddress,
                    value: item?.instituteAddressId
                })

            })
            setInstitueAddressList(tempInstituteAddress)
        }
    }, [instituteAddressData])

    // Filter Institute Course Data
    React.useEffect(() => {
        if (instituteCourseData && instituteCourseData.length) {
            const tempInstituteCourse = []
            instituteCourseData.map(item => {
                if (item.instituteCourses.toLowerCase() === "other") {
                    return
                }
                tempInstituteCourse.push({
                    name: item?.instituteCourses,
                    value: item?.instituteCourseId
                })
            })
            setinstitueCourseList(tempInstituteCourse)
        }
    }, [instituteCourseData])



    React.useEffect(() => {
        const tempData = []
        instituteType?.map(item => {
            tempData.push({
                value: item?.instituteTypeId,
                name: item?.instituteTypeName
            })
        })
        setinstitueTypeList(tempData)
        const tempNameData = []
        instituteName?.map(item => {
            tempNameData.push({
                value: item?.instituteId,
                name: item?.instituteName
            })
        })
        setinstitueNameList(tempNameData)

    }, [instituteType, instituteName])


    function onChangeType(valueId) {
        setInstitueAddressList([])
        setinstitueCourseList([])
        if (valueId) {
            dispatch(getInstituteName(valueId))
            setSelectedInstitueType(valueId)
        }
    }
    function onChangeName(valueId) {
        setInstitueAddressList([])
        setinstitueCourseList([])
        if (valueId) {
            dispatch(getInstituteAddress(valueId))
            setSelectedInstitueName(valueId)
        }
    }

    function onChangeAddress(valueId) {
        setinstitueCourseList([])
        if (valueId) {
            dispatch(getInstituteCourse(valueId))
            setSelectedInstituteAddress(valueId)
        }
    }


    const handleClose = () => {
        setOpen(false);
        handleCloseForm();
        setinstitueNameList([])
        setInstitueAddressList([])
        setinstitueCourseList([])
        setSelectedInstitueType('')
        setSelectedInstitueName('')
        setSelectedInstitueCourse('')
        setSelectedInstituteAddress('')
        setSchemeMoratorium(0)
        setTenure('');
        setROI('');
        setSubvention('');
        setPF('');
        setTrancheDetails([{ month: "", percentage: "" }]);
        setAdvanceEMI('');
        setIsTranche(false)
        setIsOtherScheme(false)
        setIsDisabledScheme(false)
    };

    // TO DO: remove hard coded number 
    // To Do: tranceDetails, number of emis to be dynamic
    async function saveData() {
        setPercentageError(false)
        const trancheMap = new Map();
        let trancheData = '';
        let sumPercentage = 0;
        trancheDetails?.length && trancheDetails?.map((item, index) => {
            if (index === 0) {
                trancheData = trancheData + `${item?.month}:${item?.percentage}`;
                sumPercentage += +item?.percentage
            } else {
                trancheData = trancheData + `,${item?.month}:${item?.percentage}`;
                sumPercentage += +item?.percentage
            }
            trancheMap.set(item?.month, item?.percentage)
        })
        if (isTranche && sumPercentage !== 100) {
            setPercentageError(true)
            return;
        }
        const obj = {
            instituteTypeId: selectedInstituteType,
            instituteNameId: selectedInstituteName,
            instituteAddressId: selectedInstituteAddress,
            instituteCourseId: isOtherScheme ? null : selectedInstituteCourse,
            schemeId: null,
            schemeTenure: +tenure,
            schemeRoi: +roi || 0,
            schemeSubvention: +subvention || 0,
            schemePf: +pf,
            schemeCreatedBy: userId,
            trancheString: `{${trancheData}}`,
            numberOfAdvanceEmis: advanceEMI || 0,
            otherScheme: isOtherScheme,
            disabledScheme: isDisabledScheme,
            tranche: isTranche,
            schemeMoratorium: schemeMoratorium
        }
        if (selectedRow && selectedRow?.id) {
            obj.schemeId = selectedRow?.id
        }
        const updatedData = await saveInstituteScheme(obj);
        handleClose()
        reFetch(1)

    }

    function confirmAction() {
        setConfirmModel(true)
    }

    function onChangeMonth(value, index) {
        const tempList = trancheDetails
        tempList[index].month = value
        setTrancheDetails([...tempList])
    }
    function onChangePercentage(value, index) {
        const tempList = trancheDetails
        tempList[index].percentage = value
        setTrancheDetails([...tempList])
    }
    function onAdd() {
        const tempList = trancheDetails
        tempList.push({ month: "", percentage: "" })
        setTrancheDetails([...tempList])
    }
    function onDelete(index) {
        const tempList = trancheDetails
        tempList.splice(index, 1)
        setTrancheDetails([...tempList])
    }
    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Add New Scheme</DialogTitle>
                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            pt: 2,
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <CSelectBox onChange={onChangeType} defaultValue={selectedInstituteType} optionList={instituteTypeList} label="Institute Type*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CSelectBox onChange={onChangeName} defaultValue={selectedInstituteName} optionList={instituteNameList} label="Institute Name*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CSelectBox onChange={onChangeAddress} defaultValue={selectedInstituteAddress} optionList={instituteAddressList} label="Institute Address*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CSelectBox onChange={setSelectedInstitueCourse} defaultValue={selectedInstituteCourse} optionList={instituteCourseList} label="Institute Course*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput type="number" value={tenure} onChange={setTenure} label="Tenure*" />
                            </Grid> 
                            <Grid item xs={12}>
                                <CInput type="number" value={schemeMoratorium} onChange={setSchemeMoratorium} label="Moratorium" />
                            </Grid> 
                            <Grid item xs={12}>
                                <CInput onChange={setAdvanceEMI} value={advanceEMI} label="No of Advance EMI*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput type="number" onChange={setROI} value={roi} label="ROI*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput type="number" onChange={setSubvention} value={subvention} label="Subvention*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput type="number" onChange={setPF} value={pf} label="PF*" />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <CInput onChange={setAdvanceEMI} value={advanceEMI} label={`No of Advance EMI`} />
                            </Grid> */}
                            <Grid item xs={12}>
                                <CSwitch setIsChecked={setIsTranche} checked={isTranche} label="Is Tranche" />
                            </Grid>

                            {/* <Grid item xs={12}>
                                <CInput disabled={!isTranche} placeholder="{0:11, 1:11}" onChange={setTrancheDetails} value={trancheDetails} label={`Tranche Details${isTranche ? "*" : ''}`} />
                            </Grid> */}
                            {isTranche && trancheDetails?.map((item, index) => {
                                return (
                                    <div key={index} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Grid item xs={5}>
                                            <CSelectBox onChange={(value) => onChangeMonth(value, index)} defaultValue={item?.month} optionList={monthData} label="Select Month*" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CInput placeholder="percentage" onChange={(value) => onChangePercentage(value, index)} value={item?.percentage} label={`Percentage*`} />
                                        </Grid>
                                        {
                                            trancheDetails?.length === index + 1 &&
                                            <>
                                                <Grid sx={{ pt: 4 }} item xs={2}>
                                                    <Fab onClick={() => onAdd(index)} size="medium" sx={{ backgroundColor: 'textColorR' }} aria-label="add">
                                                        <AddIcon />
                                                    </Fab>
                                                </Grid>
                                                <Grid sx={{ pt: 4 }} item xs={2}>
                                                    <Fab disabled={index === 0} onClick={() => onDelete(index)} size="medium" sx={{ backgroundColor: 'textColorR' }} aria-label="add">
                                                        <DeleteIcon />
                                                    </Fab>
                                                </Grid>
                                            </>
                                        }

                                    </div>
                                )
                            })}
                            {
                                percenategError &&
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <Typography color='textColorR' variant='body2' sx={{ fontWeight: "bold", textAlign: "left" }}>Percentage total should be 100</Typography>
                                </div>
                            }

                            <Grid item xs={12}>
                                <CSwitch setIsChecked={setIsOtherScheme} checked={isOtherScheme} label="Other Scheme" />
                            </Grid>
                            <Grid item xs={12}>
                                <CSwitch setIsChecked={setIsDisabledScheme} checked={isDisabledScheme} label="Disable Scheme" />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CButton handleClick={confirmAction} disabled={isOtherScheme ? (!tenure || !(roi >= 0) || !(subvention >= 0) || !(pf >= 0) || !(advanceEMI >= 0)) : (!selectedInstituteName || !selectedInstituteAddress || !selectedInstituteCourse || !selectedInstituteType || !tenure || !(roi >= 0) || !(subvention >= 0) || !(pf >= 0) || !(advanceEMI >= 0))}
                        variant='contained' name="Add" />
                </DialogActions>
            </Dialog>
            <CDialogue open={confirmModel} message={selectedRow?.id ? "Are you Sure you want to Edit Institute Scheme" : "Are you Sure you want to Create new Institute Scheme"} setOpen={setConfirmModel} isConfirm={saveData} />

        </React.Fragment>
    );
}
