import { httpservice } from "./httpService"

/**
 * Listing Data Master Data
 */


export function synofinLoanSave(detailId) {
    return httpservice()
        .get(`api/synlms/loanSave?detailId=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}