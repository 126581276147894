import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CInput from "../../utils/CInput";
import CButton from "../../utils/CButton";
import { Button, Grid, recomposeColor } from '@mui/material';
import { saveFcp } from '../../../services/AdminServices';
import { useDispatch } from 'react-redux';
import CDialogue from '../../utils/CDialogue';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { uploadFile, deleteFile } from "../../../services/studentService";


export default function AddFcp(props) {
    const { open, setOpen, reFetch, handleCloseForm, selectedRow, instituteType, instituteName } = props;
    const dispatch = useDispatch();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [instituteAddress, setInstituteAddress] = React.useState('')

    const [name, setName] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [companyName, setCompanyName] = React.useState('')
    const [ifsc, setIfsc] = React.useState('')
    const [city, setCity] = React.useState('')
    const [accountNumber, setAccountNumber] = React.useState('')
    const [refCommission, setRefCommission] = React.useState('')

    const [refCommissionFlat, setRefCommissionFlat] = React.useState('')
    const [agreementUrl, setAgreementUrl] = React.useState('')

    const [beneficiaryName, setBeneficiaryName] = React.useState('')

    const [confirmModel, setConfirmModel] = React.useState(false)
    const [uploadFileURL, setUploadFileURL] = React.useState('')
    const [fileObj, setFileObj] = React.useState('')
    const inputRef = React.useRef(null);

    React.useEffect(() => {
        setName(selectedRow?.name)
        setPhone(selectedRow?.fcpPhoneNumber)
        setEmail(selectedRow?.fcpEmail)
        setCompanyName(selectedRow?.fcpComponyName)
        setBeneficiaryName(selectedRow?.fcpBankBeneficiaryName)
        setIfsc(selectedRow?.fcpBankIfsc)
        setAccountNumber(selectedRow?.fcpbankAccountNumber)
        setRefCommission(selectedRow?.fcpReferralCommissionPercent)
        setRefCommissionFlat(selectedRow?.fcpReferralCommissionRate)
        setAgreementUrl(selectedRow?.fcpAgreementUrl)
        setCity(selectedRow?.city)
    }, [selectedRow])


    const handleClose = () => {
        setName('')
        setPhone('')
        setCity('')
        setEmail()
        setCompanyName('');
        setBeneficiaryName('')
        setIfsc('')
        setAccountNumber('')
        setRefCommission('')
        setRefCommissionFlat('')
        setAgreementUrl('')
        setOpen(false);
        handleCloseForm();
    };

    async function saveData() {
        const obj = {
            name: name,
            fcpPhoneNumber: phone,
            fcpEmail: email,
            fcpCompanyName: companyName,
            fcpBankBeneficiaryName: beneficiaryName,
            fcpBankIfsc: ifsc,
            fcpbankAccountNumber: accountNumber,
            fcpReferralCommissionPercent: refCommission,
            fcpReferralCommissionRate: refCommissionFlat,
            fcpAgreementUrl: agreementUrl,
            fcpCity: city
        }
        if (selectedRow && selectedRow?.id) {
            obj.fcpId = selectedRow?.fcpId
        }
        const updatedData = await saveFcp(obj);
        handleClose()
        reFetch(10)
    }


    function confirmAction() {
        setConfirmModel(true)
    }

    function onFileUpload() {
        // 👇️ open file input box on click of other element
        inputRef.current.click();
    }

    // On File Upload
    function handleFileChange(event) {
        const fileObj = event.target.files && event.target.files[0];
        setFileObj(fileObj)
       // setIsFileUpload(false)
       // setFileName(fileObj?.name)
       const formData = new FormData();
        formData.append("file", fileObj);
        formData.append("delimeter", "fcp");
        formData.append("detailId", 0)
        uploadFile(formData).then(res => {
            if (res?.status === 200) {
                // setFileName(fileObj?.name)
                setAgreementUrl(res?.data)
            }
        }).catch(err => {
            // setError(true)
            // setErrorMsg("File length should be less than 5Mb")
        })
    }



    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Add New FCP</DialogTitle>
                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            pt: 2,
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <CInput value={name} onChange={setName} label="Name*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput value={phone} onChange={setPhone} label="Phone*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput value={email} onChange={setEmail} label="Email*" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setCompanyName} value={companyName} label="Company Name" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setCity} value={city} label="City" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setBeneficiaryName} value={beneficiaryName} label="Beneficiary Name" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setIfsc} value={ifsc} label="IFSC" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput value={accountNumber} onChange={setAccountNumber} label="Account Number" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setRefCommission} value={refCommission} label="Referal Commission %" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput onChange={setRefCommissionFlat} value={refCommissionFlat} label="Referal Commission Flat Rate" />
                            </Grid>
                            <Grid item xs={12}>
                                <CInput disabled={true} onChange={setAgreementUrl} value={agreementUrl} label="AgreementUrl" />
                            </Grid>
                            <Grid item xs={6}>
                                <input accept="application/pdf" type="file" style={{ display: 'none' }} onChange={handleFileChange} ref={inputRef} />
                                <Button onClick={onFileUpload} color="uploadButton" fullWidth variant="contained" startIcon={<FileUploadIcon />}>
                                    Upload Agreement
                                </Button>
                            </Grid>
                        </Grid>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <CButton disabled={!name || !phone || !email || !companyName || !beneficiaryName || !ifsc || !accountNumber || !refCommission || !refCommissionFlat || !agreementUrl || !city} handleClick={confirmAction} variant='contained' name="Add" />
                </DialogActions>
            </Dialog>
            <CDialogue open={confirmModel} message={selectedRow?.id ? "Are you Sure you want to Edit FCP Record" : "Are you Sure you want to Create new FCP Record"} setOpen={setConfirmModel} isConfirm={saveData} />

        </React.Fragment>
    );
}
