import { httpservice } from './httpService'

/**
 * OTP Send
 */
export function getUserDataService(userId) {
    return httpservice()
        .get(`/api/userData/${userId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function getUserDetails(detailId) {
    return httpservice()
        .get(`/api/userData/detail/${detailId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function getUserAppList(userId) {
    return httpservice()
        .get(`/api/userData/userDashboardView/${userId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function leadUpdateAndCreateOpp(userId,obj) {
    return httpservice()
        .post(`api/leadSquared/createOpportunity/${userId}`, obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function updateOpportunity(detailId,obj) {
    return httpservice()
        .post(`api/leadSquared/updateOpportunity/${detailId}`, obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}


/**
 * Save Step
 */
 export function saveFormPath(formPath, userInstituteDetailId) {
    return httpservice()
        .put(`/api/userData/formPath?formPath=${formPath}&id=${userInstituteDetailId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function prefillUserData(userID) {
    return httpservice()
        .get(`/api/userData/prefill?id=${userID}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}