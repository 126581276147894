import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { Grid, IconButton, TableHead, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { getAdminDocs, getPdf, saveAdminDocs } from '../../../services/AdminServices';
import UserDataPDF from './UserDataPDF';
import { Fragment, useEffect, useState } from 'react';


export default function AdminDocs(props) {
    const { selectedRow, shouldFetch, setShouldFetch } = props;
    const [adminDocs, setAdminDocs] = useState(null)
    const [showPDFView, setShowPDFView] = useState(false);
    const [pdf, setPdf] = useState(null);



    useEffect(() => {
        (async () => {
            if (shouldFetch) {

                const resp = await getAdminDocs(selectedRow?.detailId)
                setAdminDocs(resp)
                setShouldFetch(false)
            }
        })();
    }, [shouldFetch])

    useEffect(() => {
        (async () => {

            const resp = await getAdminDocs(selectedRow?.detailId)
            setAdminDocs(resp)
            setShouldFetch(false)

        })();
    }, [])
    async function getUserPdf(url, e) {
        e?.preventDefault()
        const splitFile = JSON.stringify(url).split("/")
        const obj = {
            fileName: url
        }
        const dFile = await getPdf(obj);
        setShowPDFView(true)
        const blob = base64ToBlob(dFile?.data.split(',')[1], 'application/pdf');
        const pdfurl = URL.createObjectURL(blob);
        setPdf(pdfurl);

    }

    function base64ToBlob(base64, type = "application/octet-stream") {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        return new Blob([arr], { type: type });
    }

    function getEventBody() {
        const rows = [];
        for (let key in adminDocs?.data) {
            //const date = format(new Date(adminDocs?.data[key].time), "dd-MM-yyyy hh:mm:ss aaaaa'm'")
            rows.push(
                <TableRow>
                    <TableCell sx={{ borderLeft: "0.1px inset gray", borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {key + 1}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">
                        {adminDocs?.data[key].documentTypeDescription}
                    </TableCell>
                    <TableCell sx={{ borderRight: "0.1px inset gray", borderBottom: "0.1px inset gray" }} component="th" scope="row">

                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={(e) => getUserPdf(adminDocs?.data[key].url, e)}
                            aria-label="PDF"
                        >
                            <PictureAsPdf />
                        </IconButton>
                    </TableCell>

                </TableRow>

            );
        }
        return rows;
    }


    return (
        <Fragment>
            <DialogContent>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Admin Documents</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <Grid>

                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ borderRight: "0.1px inset gray", borderLeft: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }}>Doc#</TableCell>
                                                <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >Type</TableCell>
                                                <TableCell sx={{ borderRight: "0.1px inset gray", borderTop: "0.1px inset gray", borderBottom: "0.1px inset gray", fontWeight: "bold", backgroundColor: "#E5E5E5" }} >URL</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {getEventBody()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Grid>
                        </Paper>
                    </AccordionDetails>
                </Accordion>

            </DialogContent>
            {
                showPDFView &&
                <UserDataPDF open={showPDFView} setOpen={setShowPDFView} pdf={pdf} setPdf={setPdf} />
            }

        </Fragment>
    );
}
