const themeObj = {
    default: {
           
        palette: {
            headerColor: "#E5E5E5",
            buttonColor: "#D32F2F",
            textColor: "#4D4948",
            textColorR: '#D32F2F',
            textColorG: "#A3A3A3",
            textColorB: "#000000",
            textColorY: "#DAB221",
            textColorGreen: "#098129",
            textColorFetch: "#B03030",
            inputLabelColor: "#4D4948",
            appBarColor: "#383D54",
            neutral: {
                main: '#D32F2F',
                contrastText: '#fff',
            },
            uploadButton: {
                main: '#E5E5E5',
                contrastText: '#4D4948',
            },
        },
        typography: {
            subtitle1: {
                fontSize: 32,
                fontStyle: "lato"
            },
            subtitle2: {
                fontSize: 28,
                fontStyle: "lato"
            },
            subtitle3: {
                fontSize: 30,
                fontStyle: "Lato"
            },
            body1: {
                fontSize: 24,
            },
            body2: {
                fontSize: 14,
            },
            body3: {
                fontSize: 25,
            },
            inputLabel: {
                fontSize: 14,
            },
            aadharLabel: {
                fontSize: 18
            },
            Label20: {
                fontSize: 20
            },
            Label16: {
                fontSize: 20
            },
            
        },
  

    },

}

export default themeObj;

// Mobile VIew 