import { httpservice } from "./httpService"

/**
 * Listing Data Master Data
 */

 export function getListingData(obj) {
    return httpservice()
        .post(`/api/adminData/listingData`, obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function getPieChartData(obj) {
    return httpservice()
        .post(`/api/adminData/pieChartData`, obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function getBarChartData(obj) {
    return httpservice()
        .post(`/api/adminData/barChartData`, obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function exportCSV(startDate,endDate) {
    return httpservice()
        .get(`/api/adminData/export?startDate=${startDate}&endDate=${endDate}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function getConsentData(panNumber) {
    return httpservice()
        .get(`/api/adminData/userProfile/${panNumber}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

export function exportBreData(startDate,endDate) {
    return httpservice()
        .get(`/api/adminData/exportBre?startDate=${startDate}&endDate=${endDate}`,{
            responseType: 'blob',
            headers: {
                "Content-type": "application/xls",
                "Accept": "application/xls"
            },
        })
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Institute Type get
 */
 export function getInstituteTypesAdmin() {
    return httpservice()
        .get(`/api/adminData/allInstituteType`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Institute Type get
 */
 export function getInstituteSchemeAdmin() {
    return httpservice()
        .get(`/api/adminData/allInstituteSchemes`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Institute Type get
 */
 export function getInstituteNameAdmin() {
    return httpservice()
        .get(`/api/adminData/allInstituteNames`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Institute Type get
 */
 export function getInstituteCourseAdmin() {
    return httpservice()
        .get(`/api/adminData/allInstituteCourses`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Institute Type get
 */
 export function getInstituteAddressAdmin() {
    return httpservice()
        .get(`/api/adminData/allInstituteAddress`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * BlackLIsted PINS
 */
 export function getBlackListedPinsAdmin() {
    return httpservice()
        .get(`/api/adminData/allblackListedPin`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * FCPs
 */
export function getFcp() {
    return httpservice()
        .get(`/api/adminData/allFcp`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * FCP DropDown
 */
export function getFcpDropDown() {
    return httpservice()
        .get(`/api/adminData/fcpDropdown`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Save FCP
 */
export function saveFcp(obj) {
    return httpservice()
        .put(`/api/adminData/fcp`,obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Save Institute Admin Type
 */
 export function saveInstituteType(obj) {
    return httpservice()
        .put(`/api/adminData/saveAdminInstituteType`,obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Save Black Listed PIN
 */
 export function saveBlackListedPin(pinId, pinValue) {
    return httpservice()
        .put(`/api/adminData/saveBlackListedPin?pinId=${pinId}&pinValue=${pinValue}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}


/**
 * Save Institute Admin Name
 */
 export function saveInstituteName(obj) {
    return httpservice()
        .put(`/api/adminData/saveAdminInstituteName`,obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Save Institute Admin Course
 */
 export function saveInstituteCourse(obj) {
    return httpservice()
        .put(`/api/adminData/saveAdminInstituteCourse`,obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}


/**
 * Save Institute Admin Address
 */
 export function saveInstituteAddress(obj) {
    return httpservice()
        .put(`/api/adminData/saveAdminInstituteAddress`,obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Save Institute Admin Scheme
 */
 export function saveInstituteScheme(obj) {
    return httpservice()
        .put(`/api/adminData/saveAdminInstituteScheme`,obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Delete Institute Type
 */
 export function deleteInstituteType(typeId) {
    return httpservice()
        .delete(`/api/adminData/deleteType?typeId=${typeId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Delete Institute Name
 */
 export function deleteInstituteName(nameId) {
    return httpservice()
        .delete(`/api/adminData/deleteName?nameId=${nameId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Delete Institute Address
 */
 export function deleteInstituteAddress(addressId) {
    return httpservice()
        .delete(`/api/adminData/deleteAddress?addressId=${addressId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Delete Institute Course
 */
 export function deleteInstituteCourse(courseId) {
    return httpservice()
        .delete(`/api/adminData/deleteCourse?courseId=${courseId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Delete Institute Scheme
 */
 export function deleteInstituteScheme(schemeId) {
    return httpservice()
        .delete(`/api/adminData/deleteScheme?schemeId=${schemeId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}
/**
 * Delete Institute Scheme
 */
 export function deleteDropDownValue(valueId) {
    return httpservice()
        .delete(`/api/adminData/deleteDropDownValue?valueId=${valueId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Delete BlackListed PIN
 */
 export function deleteBlackListedPin(pinId) {
    return httpservice()
        .delete(`/api/adminData/deletePin?pinId=${pinId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}


/**
 * Delete FCP
 */
export function deleteFcp(fcpId) {
    return httpservice()
        .delete(`/api/adminData/fcp?fcpId=${fcpId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Add dropdown value
 */
 export function saveDropDownData(obj) {
    return httpservice()
        .put(`/api/adminData/dropdownMasterData`,obj)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

// Get Scheme

export function getSchemeDetails(courseId,tenure) {
    return httpservice()
        .get(`/api/adminData/schemeData?courseId=${courseId}&tenure=${tenure}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

// Get Scheme

export function assignRecordToAdmin(detailId,userName) {
    const url = userName ? `/api/adminData/assign/${detailId}?userName=${userName}` : `/api/adminData/assign/${detailId}`
    return httpservice()
        .post(url)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error
        })
}

export function getPdf(obj) {
    return httpservice()
        .post(`/api/storage/getPdf?fileName=${obj?.fileName}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function getAdminDropdown() {
   
    return httpservice()
        .get(`/api/adminData/adminDocsDropdown`,)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function saveAdminDocs(detailId, docsDetails) {
   
    return httpservice()
        .post(`/api/adminData/saveUploadedDocs/${detailId}`, docsDetails)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function getAdminDocs(detailId) {
   
    return httpservice()
        .get(`/api/adminData/getUploadedDocs/${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

export function flagRecord(isFlagged, detailId) {
    return httpservice()
        .post(`/api/adminData/flag?isFlagged=${isFlagged}&detailId=${detailId}`)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

