import React from "react";
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Box, makeStyles } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function MobileViewHeader(props) {

    const { stepperCount, header, nextHeader } = props;
    const theme = useTheme()

    return (
        
<React.Fragment>
    <Container maxWidth="xl" sx={{ pt: "16px", pb: "16px", boxShadow: 2 }}>
        <Toolbar disableGutters sx={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto', // Three-column layout with middle column taking available space
            alignItems: 'center', // Align items vertically in the center
            width: '100%', // Ensure the grid takes up the full width of its container
        }}>
            {/* Left-aligned Stepper Count */}
            <div className='numberCircle' style={{ justifySelf: 'start' }}> {/* Align this item to the start of its grid column */}
                <Typography color='appBarColor' sx={{ p: 1, textAlign: "left", fontFamily: "Poppins", fontWeight: "bolder" }} variant="subtitle1">{`${stepperCount}/9`}</Typography>
            </div>

            {/* Center-aligned Header */}
            {/* This item automatically centers because of the 1fr column taking the extra space */}
            <div style={{ justifySelf: 'center' }}> {/* Explicitly center this item in its grid column */}
                <Typography color='appBarColor' variant="subtitle2" sx={{ textAlign: "center", fontFamily: "Poppins", fontWeight: "bolder" }} >{header}</Typography>
            </div>

            {/* Placeholder for right-aligned content if needed */}
            <div style={{ visibility: 'hidden' }}> {/* Invisible placeholder to maintain grid structure */}
                {/* This div ensures the grid's structure but doesn't display any content */}
            </div>
        </Toolbar>
    </Container>
</React.Fragment>
    )
}