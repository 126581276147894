import * as React from 'react';
import Switch from '@mui/material/Switch';
import { InputLabel, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function CSwitch(props) {
    const { label, checked, setIsChecked } = props;
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const handleChange = (event) => {
        setIsChecked(event.target.checked);
    };
    return (
        <div>
            <InputLabel htmlFor="input-with-icon-adornment">
                <Typography fontSize={matches ? "14px" : "10px"} color="inputLabelColor" sx={{ fontWeight: "bold", fontFamily: "lato" }} align='left' variant="inputLabel">
                    {label}
                </Typography>
            </InputLabel>
            <Switch checked={checked}
                onChange={handleChange}
                {...label} />
        </div>
    );
}