import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import '../assets/jsx/WelcomeStyle.css'
import "../assets/jsx/ApplicationForm.css";
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CssBaseline, Grid, Paper } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import FlyLogo from "../assets/images/flyhi_logo_full.svg"
import signUPImage from "../assets/images/signUP.svg"
import { getDropDownData } from '../actions/getDropDownData';
import SelfEvaluationVerification from '../components/forms/SelfEvaluationVerification';

const SelfEvaluation = ({}) => {
    
    const theme = useTheme();
    const location = useLocation();
    const dispatch = useDispatch()
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));


    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
    }, [location])


    // React.useEffect(() => {
    //     if (jwt)
    //         dispatch(getDropDownData())
    // }, [jwt])

    return (
        <div style={{ height: "auto" }}>
            {/* <CAlert /> */}
            <AppBar sx={{ backgroundColor: theme.palette.headerColor }} position="static">
                <Container maxWidth="xl">
                    <Toolbar sx={{ display: "grid", gridAutoFlow: 'column', justifyContent: 'space-between' }} disableGutters>
                        <img style={{ marginTop: "10px", marginBottom: "10px",width: "50px", height: "50px" }} src={FlyLogo} alt="logo" />
                        <div sx={{ pt:6 , gridColumn: '3 / 5', display: { xs: 'none', md: 'block', lg: 'none' } }}>
                        </div>
                        
                    </Toolbar>
                </Container>
            </AppBar>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />

                <Grid item xs={12} sm={6} md={6} component={Paper} >
                    <SelfEvaluationVerification/>
                </Grid>
                <Grid
                    item
                    display={{ xs: "none", lg: "block", sm: "block" }}
                    sm={6}
                    md={6}
                    sx={{
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        pt: 12
                    }}
                >
                    <img src={signUPImage} alt="signup" />
                </Grid>
            </Grid>
        </div >
    );
};
export default SelfEvaluation;
