

export const setAppLoader = (showLoader) => dispatch => {
    if (showLoader) {
        return dispatch({
            type: 'SHOW_LOADER'
        })
    } else {
        return dispatch({
            type: 'HIDE_LOADER'
        })
    }
}