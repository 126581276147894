import { Box, Button, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import CButton from "../utils/CButton";
import CInput from "../utils/CInput";
import CSelectBox from "../utils/CSelectBox";
import { saveStudentPersData } from "../../services/studentService";
import CAlert from "../utils/CAlert";
import { setAppLoader } from "../../actions/setAppLoader";
import { getUserData } from "../../actions/getUserData";
import { saveFormPath } from "../../services/userService";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function PersonalDetails({ setTabValue, setStepperCount, userDetailId }) {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [maritalList, setMaritalList] = React.useState([])
    const [maritalStatus, setMaritalStatus] = React.useState('')
    const [noOfDependentList, setNoOfDependentList] = React.useState([])
    const [noOfDependent, setNoOfDependent] = React.useState('')
    const [noOfEarningMembersList, setNoOfEarningMembersList] = React.useState([])
    const [noOfEarningMembers, setNoOfEarningMembers] = React.useState('')
    const [noOfEmiServingList, setNoOfEmiServingList] = React.useState([])
    const [noOfEmiServing, setNoOfEmiServing] = React.useState('')
    const [monthlyFamilyIncome, setMonthlyFamilyIncome] = React.useState('')
    const [totalEmiAMount, setTotalEmiAMount] = React.useState('')
    const [enrollementList, setEnrollementList] = React.useState([]);
    const [employementTypeId, setEmployementTypeId] = React.useState('')
    const [componyName, setComponyName] = React.useState('')
    const [empYearList, setEmpYearList] = React.useState([]);
    const [yearsOfCurrentEmployement, setYearsOfCurrentEmployement] = React.useState('')
    const [totalYearsList, setTotalYearsList] = React.useState([]);
    const [totalExpError, setTotalExpError] = React.useState(false);
    const [totalYearsOfEmployement, setTotalYearsOfEmployement] = React.useState('')
    const [errorMsg, setErrorMsg] = React.useState('');
    const [error, setError] = React.useState(false)
    const dropDownData = useSelector((state) => state?.getDropDownData?.data);
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const disabledButton = !maritalStatus || !noOfDependent || !noOfEarningMembers || !noOfEmiServing || !componyName
        || !monthlyFamilyIncome || !(totalEmiAMount !== '') || !employementTypeId || !yearsOfCurrentEmployement || !totalYearsOfEmployement || totalExpError

    useEffect(() => {
        dispatch(getUserData(userDetailId))
    }, []);

    //Show Loader
    React.useEffect(() => {
        dispatch(setAppLoader(isFetching))
    }, [isFetching])

    // SetData if there
    React.useEffect(() => {
        setMaritalStatus(userDetails?.userPersonalDetails?.maritalStatus.valueId)
        setNoOfDependent(userDetails?.userPersonalDetails?.noOfDependent?.valueId)
        setNoOfEarningMembers(userDetails?.userPersonalDetails?.noOfEarningMembers?.valueId)
        setNoOfEmiServing(userDetails?.userPersonalDetails?.noOfEmiServing?.valueId)
        setMonthlyFamilyIncome(userDetails?.userPersonalDetails?.monthlyFamilyIncome)
        setTotalEmiAMount(userDetails?.userPersonalDetails?.totalEmiAMount)
        setEmployementTypeId(userDetails?.userPersonalDetails?.employementType?.valueId)
        setComponyName(userDetails?.userPersonalDetails?.componyName)
        setYearsOfCurrentEmployement(userDetails?.userPersonalDetails?.yearsOfCurrentEmployement?.valueId)
        setTotalYearsOfEmployement(userDetails?.userPersonalDetails?.totalYearsOfEmployement?.valueId)

    }, [userDetails])

    React.useEffect(() => {
        // Filter Enrollment List
        if (dropDownData && dropDownData?.length) {
            let enrolmentObj = dropDownData.find(x => x.keyDesc === "EMPLOYMENT_TYPE");
            const tempEnrollementData = []
            enrolmentObj?.valueData?.map(eItem => {
                tempEnrollementData.push({
                    value: eItem?.valueid,
                    name: eItem?.valuedesc
                })
            })
            setEnrollementList(tempEnrollementData)

            // Filter Marital List
            let maritalObj = dropDownData.find(x => x.keyDesc === "MARITAL_STATUS");
            const tempMaritalObj = []
            maritalObj?.valueData?.map(eItem => {
                tempMaritalObj.push({
                    value: eItem?.valueid,
                    name: eItem?.valuedesc
                })
            })
            setMaritalList(tempMaritalObj)

            // Filter Dependent List
            let dependentObj = dropDownData.find(x => x.keyDesc === "NUMBER_OF_DEPENDENTS");
            const tempDependentObj = []
            dependentObj?.valueData?.map(eItem => {
                tempDependentObj.push({
                    value: eItem?.valueid,
                    name: eItem?.valuedesc
                })
            })
            setNoOfDependentList(tempDependentObj)

            // Filter Earning List
            let earningObj = dropDownData.find(x => x.keyDesc === "NUMBER_OF_EARNING_MEMBER");
            const tempEarningObj = []
            earningObj?.valueData?.map(eItem => {
                tempEarningObj.push({
                    value: eItem?.valueid,
                    name: eItem?.valuedesc
                })
            })
            setNoOfEarningMembersList(tempEarningObj)


            // Filter EMI Serving List
            let emiServingObj = dropDownData.find(x => x.keyDesc === "NUMBER_OF_EMI_SERVING");
            const tempEmiServingObj = []
            emiServingObj?.valueData?.map(eItem => {
                tempEmiServingObj.push({
                    value: eItem?.valueid,
                    name: eItem?.valuedesc
                })
            })
            setNoOfEmiServingList(tempEmiServingObj)

            // Filter Current Year List
            let currentYearObj = dropDownData.find(x => x.keyDesc === "YEARS_AT_CURRENT_EMPLOYMENT");
            const tempCurrentYearObj = []
            currentYearObj?.valueData?.map(eItem => {
                tempCurrentYearObj.push({
                    value: eItem?.valueid,
                    name: eItem?.valuedesc
                })
            })
            setEmpYearList(tempCurrentYearObj)

            // Filter Total Year List
            let totalYearObj = dropDownData.find(x => x.keyDesc === "TOTAL_YEARS_OF_CURRENT_EMPLOYMENT");
            const tempTotalYearObj = []
            totalYearObj?.valueData?.map(eItem => {
                tempTotalYearObj.push({
                    value: eItem?.valueid,
                    name: eItem?.valuedesc
                })
            })
            setTotalYearsList(tempTotalYearObj)
        }
    }, [dropDownData])


    function onSubmitPersonalData(e) {
        dispatch(setAppLoader(true))
        e.preventDefault();
        const personalData = {
            personalDetailId: userDetails?.userPersonalDetails?.detailId ? userDetails?.userPersonalDetails?.detailId : null,
            maritalStatus: +maritalStatus,
            noOfDependent: +noOfDependent,
            noOfEarningMembers: +noOfEarningMembers,
            monthlyFamilyIncome: +monthlyFamilyIncome,
            noOfEmiServing: +noOfEmiServing,
            totalEmiAMount: +totalEmiAMount,
            employementTypeId: +employementTypeId,
            componyName: componyName ? componyName : null,
            yearsOfCurrentEmployement: +yearsOfCurrentEmployement,
            totalYearsOfEmployement: +totalYearsOfEmployement,
            userInstituteDetailId: userDetails?.detailId
        }
        saveStudentPersData(personalData).then(data => {
            if (data.status === 200 || data.status === 201) {
                setErrorMsg("Data Saved Successfully")
                dispatch(getUserData(userDetailId))
                saveFormPath("/panAadharDetails", userDetails?.detailId)
                setTabValue(5)
                setStepperCount(5)
                dispatch(setAppLoader(false))
                navigate("/panAadharDetails", { state: { tabValue: 5, stepperCount: 5, userDetailId: userDetailId } })
            } else {
                setError(true)
                setErrorMsg(data?.response?.data?.message)
                dispatch(setAppLoader(false))
            }
        }).catch(error => {
            console.log('error', error)
            dispatch(setAppLoader(false))
        })
    }

    React.useEffect(() => {
        setTotalExpError(false)
        if (totalYearsOfEmployement && totalYearsOfEmployement) {
            const tempTotalYear = totalYearsList.find(item => item.value == totalYearsOfEmployement)
            const tempCurrentEmployee = empYearList.find(item => item.value == yearsOfCurrentEmployement)
            if (+tempTotalYear.name < +tempCurrentEmployee.name) {
                setTotalExpError(true)
            } else {
                setTotalExpError(false)
            }
        }
    }, [totalYearsOfEmployement, yearsOfCurrentEmployement])

    function handleBack() {
        setTabValue(3);
        setStepperCount(3);
        navigate("/studentDetails", { state: { tabValue: 3, stepperCount: 3, userDetailId: userDetailId } })
    }
    return (
        <form onSubmit={(e) => onSubmitPersonalData(e)} className='applicationFormContainer' >
            {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
            }
            {
                errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
            }
            {
                !matches &&
                <Box sx={{ display: "flex" }}>
                    <ArrowBackIosIcon onClick={() => handleBack()} />
                </Box>
            }
            {
                matches ?
                    <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Personal Details of Applicant</Typography> :
                    null
                    // <Typography color='textColor' variant="subtitle3" sx={{ fontWeight: "bold", textAlign: "center" }}>Personal Details</Typography>

            }

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                    <CSelectBox
                        onChange={setMaritalStatus}
                        optionList={maritalList}
                        defaultValue={maritalStatus ?? ""}
                        name="maritalStatus"
                        label="Marital Status*" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CSelectBox onChange={setNoOfDependent}
                        optionList={noOfDependentList}
                        defaultValue={noOfDependent ?? ""} label="Number of Dependents*" name="dependents" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CSelectBox
                        onChange={setNoOfEarningMembers}
                        optionList={noOfEarningMembersList}
                        defaultValue={noOfEarningMembers ?? ""}
                        label="Number of Earning Members in Famiy*" name="membersCount" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CInput type="number" value={monthlyFamilyIncome} onChange={setMonthlyFamilyIncome} label="Monthly Family Income*" name="studentName" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CSelectBox
                        onChange={setNoOfEmiServing}
                        optionList={noOfEmiServingList}
                        defaultValue={noOfEmiServing ?? ""}
                        label="No. of EMIs Serving Currently*" name="emiServing" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CInput type="number" value={totalEmiAMount} onChange={setTotalEmiAMount} label="Total EMI Amount*" name="totalEMI" />
                </Grid>
            </Grid>
            {
                !matches &&
                <Grid item sx={{ my: 2 }} xs={12}>
                    <Divider style={{ background: '#616161' }} />
                </Grid>
            }
            {
                matches ?
                    <Typography color='textColor' variant='subtitle1' sx={{ pt: 6, textAlign: "left" }}>Employment Details of Applicant</Typography>

                    :
                    <Typography color='textColor' variant="subtitle3" sx={{ fontWeight: "bold", textAlign: "center", fontFamily: "Poppins" }}>Employement Details</Typography>

            }

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                    <CSelectBox
                        onChange={setEmployementTypeId}
                        optionList={enrollementList}
                        defaultValue={employementTypeId ?? ""}
                        label="Employement Type*" name="employementType" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CInput value={componyName} onChange={setComponyName} label="Company/Business Name*" name="companyName" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CSelectBox
                        onChange={setYearsOfCurrentEmployement}
                        optionList={empYearList}
                        defaultValue={yearsOfCurrentEmployement ?? ""}
                        label="Years at Current Company/Business*" name="studentName" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CSelectBox
                        onChange={setTotalYearsOfEmployement}
                        optionList={totalYearsList}
                        defaultValue={totalYearsOfEmployement ?? ""}
                        label="Total Years of Employement/Business*" name="studentName" />
                </Grid>
                <Grid item xs={6}>
                </Grid>
                {
                    totalExpError && <Grid item xs={6}>
                        <Typography color='textColorR' variant='body2' sx={{ fontWeight: "bold", textAlign: "left" }}>Total Years of Experience should be more than Current Years of Experience</Typography>
                    </Grid>
                }
            </Grid>
            <div style={{ padding: "20px 0px 0px", display: "flex", flexDirection: "row", justifyContent: `${!matches ? "space-around" : "space-between"}` }}>
                {matches && <CButton handleClick={() => handleBack()} name="Back" />}
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    disabled={disabledButton}
                    variant="contained">
                    Save & Submit
                </Button>
            </div>
        </form >
    )
}