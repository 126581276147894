import { httpservice } from './httpService'

/**
 * OTP Send
 */
export function saveInstituteData(instituteData) {
    return httpservice()
        .put(`/api/userData/userDetails`, instituteData)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Institute Type get
 */
export function getInstituteTypesService() {
    return httpservice()
        .get(`/api/masterData/getInstituteTypes`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Get Institute Name
 */
 export function getInstituteNameService(typeId) {
    return httpservice()
        .get(`/api/masterData/getInstituteNames/${typeId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Get Institute Address by name id
 */
 export function getInstituteAddressService(nameId) {
    return httpservice()
        .get(`/api/masterData/getInstituteAddresses/${nameId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}


/**
 * Get Institute Course by Address id
 */
 export function getInstituteCourseService(addressId) {
    return httpservice()
        .get(`/api/masterData/getInstituteCourses/${addressId}`)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * get tenure Data
 */
 export function getLoanTenureService(value) {
    return httpservice()
        .post(`/api/masterData/getTenures?courseId=${value}`,)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}


/**
 * Get EMI and Processing Fee for course
 */
 export function getEmiAndProcessingFee(pmtRequest) {
    return httpservice()
        .post(`/api/masterData/getPmt`, pmtRequest)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

/**
 * Create Other Data
 */
 export function postOtherData(OtherDataReq) {
    return httpservice()
        .post(`/api/masterData/Other`, OtherDataReq)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.data
        })
}

