import { useTheme } from '@emotion/react';
import { Autocomplete, TextField, Typography, useMediaQuery } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import * as React from 'react';

export default function CAutoComplete(props) {
    const { label, placeholder, optionList, onChange, name, defaultValue, disable } = props;
    const theme = useTheme()
    const [value, setValue] = React.useState(defaultValue);
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    React.useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    function handleChange(event, value) {
        setValue(value)
        onChange(value);
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "-webkit-fill-available" }}>
            <InputLabel htmlFor="input-with-icon-adornment">
                <Typography fontSize={matches ? "14px" : "10px"} color="inputLabelColor" sx={{ fontWeight: "bold", fontFamily: "lato" }} align='left' variant="inputLabel">
                    {label}
                </Typography>
            </InputLabel>
            <FormControl fullWidth>
                <Autocomplete
                    size="small"
                    placeholder={placeholder}
                    labelId="demo-simple-select-label"
                    id="combo-box-demo"
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, defaultValue) =>option == defaultValue}
                    disabled={disable}
                    value={value || null}
                    onChange={(event, newValue)=>handleChange(event, newValue)}
                    options={optionList}
                    style={{ fontSize: '10px', textAlign: "left" }}
                    renderInput={(params) => <TextField {...params} InputProps={{ ...params.InputProps, style: { fontSize: "18px", fontFamily: "lato" } }}/>}
                />
                    
            </FormControl>
        </div>
    );
}
