import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import CButton from "../utils/CButton";
import CInput from "../utils/CInput";
import CSelectBox from "../utils/CSelectBox";
import CAlert from "../utils/CAlert";
import { setAppLoader } from "../../actions/setAppLoader";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { saveStudentData, uploadFile, deleteFile } from "../../services/studentService";
import { saveAdditionalData } from "../../services/bankService";
import { getUserData } from "../../actions/getUserData";
import { saveFormPath } from "../../services/userService";

export default function NewAddress({ setTabValue, setStepperCount,userDetailId }) {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const inputRef = React.useRef(null);
    const [addressLine1, setAddressLine1] = React.useState('')
    const [addressLine2, setAddressLine2] = React.useState('')
    const [addressLine3, setAddressLine3] = React.useState('')
    const [pinCode, setPinCode] = React.useState('')
    const [city, setCity] = React.useState('')
    const [district, setDistrict] = React.useState('')
    const [state, setState] = React.useState('')
    const [documentType, setDocumentType] = React.useState('')
    const [documentURL, setDocumentURL] = React.useState('')
    const [documentTypeList, setDocumentTypeList] = React.useState([])
    const MAX_FILE_SIZE = 15000 // 15MB
    const [error, setError] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('');
    const [fileURL, setFileURL] = React.useState('')
    const [fileName, setFileName] = React.useState('')

    const dropDownData = useSelector((state) => state?.getDropDownData?.data);
    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const userId = userData?.userId;

    const disabledButton = !addressLine1 || !pinCode || !city || !state || !district || !documentType || !fileURL

    useEffect(() => {
        dispatch(getUserData(userDetailId))
    }, []);

    //Show Loader
    React.useEffect(() => {
        dispatch(setAppLoader(isFetching))
    }, [isFetching])

    React.useEffect(() => {
        // Filter Enrollment List
        if (dropDownData && dropDownData?.length) {
            let docObj = dropDownData.find(x => x.keyDesc === "DOCUMENT_TYPE");
            const tempDocData = []
            docObj?.valueData?.map(eItem => {
                tempDocData.push({
                    value: eItem?.valueid,
                    name: eItem?.valuedesc
                })
            })
            setDocumentTypeList(tempDocData)
        }
    }, [])


    // SetData if there
    React.useEffect(() => {
        if (userDetails && userDetails?.userAddtionalAddressDetails) {
            const additonalAddress = userDetails?.userAddtionalAddressDetails;
            setAddressLine1(additonalAddress?.addtionalAddressLine1)
            setAddressLine2(additonalAddress?.addtionalAddressLine2)
            setAddressLine3(additonalAddress?.addtionalAddressLine3)
            setPinCode(additonalAddress?.addtionalAddressPincode)
            setCity(additonalAddress?.addtionalAddressCity)
            setDistrict(additonalAddress?.addtionalAddressDistrict)
            setState(additonalAddress?.addtionalAddressState)
            setDocumentType(additonalAddress?.addtionalAddressTypeId?.valueId)
            setFileURL(additonalAddress?.addtionalAddressDocumentId)
            const fName = additonalAddress?.addtionalAddressDocumentId?.split('/')
            setFileName(fName && fName[4])
        }
    }, [userDetails])

    function onFileUpload() {
        setError(false)
        // 👇️ open file input box on click of other element
        inputRef.current.click();
    };


    function handleFileChange(event) {
        const fileObj = event.target.files && event.target.files[0];
        const extension = fileObj.name.split('.')[1]
        if(event.target.files.length > MAX_FILE_SIZE){
            setError(true)
            setErrorMsg("File length should be less than 15Mb")
            return;
        }
        if(extension !== 'pdf' && extension !== 'PDF'){
            setError(true)
            setErrorMsg("Only PDF files are allowed")
            return;
        }
        setFileName(fileObj?.name)
        const formData = new FormData();
        formData.append("file", fileObj);
        formData.append("delimeter", userDetails?.user?.userName)
        formData.append("detailId", +localStorage.getItem('detailId'))
        uploadFile(formData).then(res => {
            if (res?.status === 200) {
                setFileURL(res?.data)
            }
        })
    }


    // Delete uploaded file by fileURL
    async function deleteFileByURL() {
        await deleteFile(fileURL)
        setFileURL('')
    }


    async function onSubmitAdditionalAddress(e) {
        e.preventDefault();
        const obj = {
            addtionalAddressId: userDetails?.userAddtionalAddressDetails?.addtionalAddressId || null,
            addtionalAddressLine1: addressLine1,
            addtionalAddressLine2: addressLine2 ? addressLine2 : null,
            addtionalAddressLine3: addressLine3 ? addressLine3 : null,
            addtionalAddressPincode: pinCode,
            addtionalAddressCity: city,
            addtionalAddressDistrict: district,
            addtionalAddressState: state,
            addtionalAddressTypeId: +documentType,
            addtionalAddressDocumentId: fileURL,
            userInstituteDetailId: userDetails?.detailId,
            addtionalAddressCreatedBy: userDetails?.user?.userName
        }

        const additionalData = await saveAdditionalData(obj);
        if (additionalData.status === 200 || additionalData.status === 201) {
            setErrorMsg("Data Saved Successfully")
            dispatch(getUserData(userDetailId))
            if(userDetails?.instituteAddress?.instituteRiskCode === 4 || userDetails?.instituteAddress?.instituteRiskCode === 3)  {
                saveFormPath("/faceMatch", userDetails?.detailId)
                setTabValue(8)
                navigate("/faceMatch", {state: {tabValue: 9, stepperCount: 6, userDetailId: userDetailId}})
            } else {
                saveFormPath("/bankDetails", userDetails?.detailId)
                setTabValue(8)
                navigate("/bankDetails", {state: {tabValue: 8, stepperCount: 5, userDetailId: userDetailId}})
            }
        } else {
            setError(true)
            setErrorMsg(additionalData?.response?.data?.message)
        }
    }

    function handleBack() {
        setTabValue(6); 
        navigate("/aadhaarDetails", {state:{tabValue:6, stepperCount: 5, userDetailId:userDetailId}})
    }

    return (
        <form onSubmit={(e) => onSubmitAdditionalAddress(e)} className='applicationFormContainer' >
            {
                error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
            }
            {
                errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
            }
            <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left" }}>Add New Address</Typography>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <CInput value={addressLine1} onChange={setAddressLine1} label="Address Line1*" name="addresLine1" />

                </Grid>
                <Grid item xs={12}>
                    <CInput value={addressLine2} onChange={setAddressLine2} label="Address Line2" name="addresLine1" />

                </Grid>
                <Grid item xs={12}>
                    <CInput value={addressLine3} onChange={setAddressLine3} label="Address Line3" name="addresLine1" />

                </Grid>
                <Grid item xs={6}>
                    <CInput type="number" maxLength={6} value={pinCode} onChange={setPinCode} label="Pin Code*" name="pinCode" />
                </Grid>
                <Grid item xs={6}>
                    <CInput value={city} onChange={setCity} label="City*" name="city" />
                </Grid>
                <Grid item xs={6}>
                    <CInput value={district} onChange={setDistrict} label="District*" name="district" />
                </Grid>
                <Grid item xs={6}>
                    <CInput value={state} onChange={setState} label="State*" name="state" />
                </Grid>

            </Grid>
            <Typography color='textColor' variant='subtitle1' sx={{ pt: 6, textAlign: "left" }}>Proof of Address</Typography>
            <Typography color='textColor' variant='body1' sx={{ pt: 6, textAlign: "left" }}>Select one of the following and upload the corresponding document</Typography>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <CSelectBox
                        onChange={setDocumentType}
                        optionList={documentTypeList}
                        defaultValue={documentType}
                        label="Select Document Type*" name="documentType" />
                </Grid>
                <Grid item xs={6} container sx={{ pt: 4 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {fileURL &&
                        <Grid sx={{ display: "flex", alignItems: "center", gap: "5px" }} item xs={12}>
                            <Typography color='textColor' variant='body2' sx={{ textAlign: "left" }}>{fileName}</Typography>
                            <CloseIcon color="warning" onClick={deleteFileByURL} />
                        </Grid>
                    }
                    <Grid item xs={6}>
                        <input accept="application/pdf" type="file" style={{ display: 'none' }} onChange={handleFileChange} ref={inputRef} />
                        <Button onClick={onFileUpload} color="uploadButton" fullWidth variant="contained" startIcon={<FileUploadIcon />}>
                            Upload
                        </Button>
                    </Grid>
                    <Grid sx={{ display: "flex", alignItems: "center", gap: "5px" }} item xs={6}>
                        {
                            fileURL &&
                            <React.Fragment>
                                <CheckCircleRoundedIcon color="success" />
                                <Typography color='textColor' variant='body1' sx={{ textAlign: "left" }}>Upload Successful</Typography>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid sx={{ textAlign: "left" }} item xs={12}>
                        <Typography color='textColorR' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>The uploaded file should be PDF should not exceed 15MB</Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "left" }} item xs={12}>
                        <Typography color='inputLabelColor' variant='aadharLabel' sx={{ fontWeight: "bold", textAlign: "left" }}>Note: The Uploaded document should have proof of the current address mentioned above.</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ padding: "20px 0px 0px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <CButton handleClick={() => handleBack()} name="Back" />
                <Button
                    type="submit"
                    sx={{ fontStyle: "Lato" }}
                    color="neutral"
                    disabled={disabledButton}
                    variant="contained">
                    Save & Submit
                </Button>
            </div>
        </form >
    )
}